import React, { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { toast } from 'react-toastify'
import { Auth, UserInfo } from '../../contexts/allContext'
import { svgs } from '../../utils/findSvgs/findSvgs'
import { removePDescriptionImage, uploadPDescriptionImage } from '../../utils/functions'
import classes from './TextEditor.module.css'

// Override Quill's default icons
const icons = Quill.import('ui/icons')
icons['bold'] = svgs.bold
icons['italic'] = svgs.italic
icons['strike'] = `<span class="strokeIcon">${svgs.stroke}</span>`
icons['list']['ordered'] = `<span class="unorderListIcon">${svgs.orderList}</span>`
icons['list']['bullet'] = svgs.unorderList

const TextEditor = ({ productData, setProductData }) => {
    const { generalInfo } = productData
    const [value, setValue] = useState('')
    const quillRef = useRef(null)
    const { stateAuth } = useContext(Auth)
    const { stateUser } = useContext(UserInfo)
    const userToken = stateAuth?.token
    const userInfo = stateUser?.info
    const [imageId, setImageId] = useState('')

    // Track if an image is currently being processed
    const [isProcessingImage, setIsProcessingImage] = useState(false)

    const handleChange = (newValue) => {
        if (newValue) {
            setValue(newValue)
            setProductData((prev) => ({
                ...prev,
                generalInfo: {
                    ...prev.generalInfo,
                    description: {
                        ...prev.generalInfo.description,
                        html: newValue,
                    },
                },
            }))

            // Check if image was removed from editor
            const editor = quillRef.current?.getEditor()
            if (editor) {
                const delta = editor.getContents()
                const hasImage = delta.ops.some((op) => op.insert && typeof op.insert === 'object' && op.insert.image)

                if (!hasImage && imageId) {
                    // Image was removed from editor, clean up the imageId
                    handleImageRemoval()
                }
            }
        }
    }

    // Handle image removal
    const handleImageRemoval = async () => {
        if (imageId && userToken) {
            try {
                await removePDescriptionImage(userToken, imageId)
                setImageId('')
            } catch (error) {
                console.error('Error removing image:', error)
            }
        }
    }

    useEffect(() => {
        if (generalInfo?.description?.html && !value) {
            // Parse and update image data-id when setting value
            const updatedHTML = updateImageDataId(generalInfo.description.html)
            setValue(updatedHTML)
        }
    }, [generalInfo?.description?.html, value])

    const updateImageDataId = (htmlContent) => {
        // Parse the HTML content
        const parser = new DOMParser()
        const doc = parser.parseFromString(htmlContent, 'text/html')
        const images = doc.querySelectorAll('img')
        images.forEach((img) => {
            const currentImageId = img.getAttribute('data-id')
            if (currentImageId) {
                setImageId(currentImageId)
            }
        })
        return htmlContent
    }

    useEffect(() => {
        if (generalInfo?.description?.html && !value) {
            setValue(generalInfo.description.html)
        }
    }, [generalInfo?.description?.html, value])

    const imageHandler = async () => {
        if (isProcessingImage) {
            toast.info('Please wait while the current image is being processed')
            return
        }

        const editor = quillRef.current?.getEditor()
        if (!editor) return

        // Check if an image already exists
        const delta = editor.getContents()
        const imageExists = delta.ops.some((op) => op.insert && typeof op.insert === 'object' && op.insert.image)

        if (imageExists) {
            toast.error('Only one image is allowed. Please remove the existing image first.')
            return
        }

        const input = document.createElement('input')
        input.setAttribute('type', 'file')
        input.setAttribute('accept', 'image/*')
        input.click()

        input.onchange = async () => {
            const file = input.files[0]
            if (!file) return

            setIsProcessingImage(true)

            try {
                if (imageId) {
                    await removePDescriptionImage(userToken, imageId)
                }

                const response = await uploadPDescriptionImage(file, userToken, userInfo.id)
                if (!response?.image_url || !response?.id) {
                    throw new Error('Invalid response from image upload')
                }
                setImageId(response.id)
                const range = editor.getSelection() || { index: editor.getLength() }
                editor.insertEmbed(range.index, 'image', response.image_url)
                const images = editor.root.querySelectorAll('img')
                const insertedImage = images[images.length - 1]
                if (insertedImage) {
                    insertedImage.setAttribute('data-id', response.id)
                }
                // Update product data
                setProductData((prev) => ({
                    ...prev,
                    generalInfo: {
                        ...prev.generalInfo,
                        description: {
                            ...prev.generalInfo.description,
                            html: editor.root.innerHTML,
                        },
                    },
                }))
            } catch (error) {
                console.error('Error handling image:', error)
                toast.error('Failed to upload image')
            } finally {
                setIsProcessingImage(false)
            }
        }
    }

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    ['image'],
                ],
                handlers: {
                    image: imageHandler,
                },
            },
        }),
        []
    )

    return (
        <div className={classes.customEditor}>
            <ReactQuill
                ref={quillRef}
                theme="snow"
                value={value}
                modules={modules}
                onChange={handleChange}
                placeholder="Write Down Product Description"
            />
        </div>
    )
}

export default TextEditor

import React, { useContext, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { UserInfo } from '../../../contexts/allContext'
import { svgs } from '../../../utils/findSvgs/findSvgs'
import { createProduct, updateProductImages, uploadPDescriptionImage } from '../../../utils/functions'
import { DefaultBtn, PrimaryBtn } from '../../Resources/Buttons'
import classes from './ProductLayout.module.css'

// Import axios for making API requests

const ProductLayout = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const pages = ['general', 'variant', 'delivery']
    const [isActivePageIndex, setIsActivePageIndex] = useState(0)
    const { stateUser } = useContext(UserInfo)
    const [productData, setProductData] = useState({
        generalInfo: {
            selectedCategoryId: null,
            selectedSubCategoryId: null,
            selectedSubSubCategoryId: null,
            moq: 5,
            name: '',
            type: null,
            unit: 'Piece',
            productImages: null,
            description: null,
            sellerid: null,
        },
        variantInfo: {
            quantity: 5,
            isMultiVariant: false,
            separetePrice: false,
            quantityAndPrice: {
                price: { b2bp: 0, b2cp: 0 },
                discount: { b2bdp: 0, b2cdp: 0 },
                profit: { b2bpm: 0, b2cpm: 0 },
                details: { cop: 0 },
            },
            selectedVariants: [],
            variants: [],
            separetePriceVariants: [],
        },
        deliveryInfo: {
            packageInfo: null,
            deliveryPartnerInfo: null,
        },
        error: {},
    })

    const validateVariantInfo = (variantInfo) => {
        const { price, profit } = variantInfo.quantityAndPrice

        // Check B2B price and discount
        let isB2BValid = profit.b2bpm > 0
        let isB2CValid = profit.b2cpm > 0

        if (price.b2bp && price.b2cp) {
            return isB2BValid && isB2CValid
        }

        return isB2BValid || isB2CValid
    }

    const handleErrorMsg = (label) => {
        const objectData = productData[label]
        let isDataValid = true

        if (label === 'variantInfo') {
            const { variantInfo } = productData
            if (variantInfo.isMultiVariant && variantInfo.errorMessages) {
                if (Object.keys(variantInfo.errorMessages).length > 0) {
                    isDataValid = false
                }
            } else {
                isDataValid = validateVariantInfo(objectData)
            }
        } else if (label === 'deliveryInfo') {
            const { packageInfo, deliveryPartnerInfo } = productData['deliveryInfo']
            if (!packageInfo?.weight || !deliveryPartnerInfo?.cod) {
                isDataValid = false
            }
        } else {
            isDataValid = !Object.values(objectData).some((value) => value === null || value === '' || value === 0)
        }

        if (!isDataValid) {
            setProductData((prev) => ({
                ...prev,
                error: {
                    [label]: true,
                },
            }))
        }

        return isDataValid
    }

    // this is for routing validation for all pages
    useEffect(() => {
        const activePageIndex = pages.findIndex((page) => location.pathname.includes(`/products/add/${page}`))
        setIsActivePageIndex(activePageIndex)
        // Redirect logic
        if (activePageIndex === 1) {
            const isGeneralValid = handleErrorMsg('generalInfo')
            if (!isGeneralValid) {
                navigate(`/products/add/general`)
            }
        } else if (activePageIndex === 2) {
            const isVariantValid = handleErrorMsg('variantInfo')
            if (!isVariantValid) {
                navigate(`/products/add/variant`)
            }
        }
    }, [location])

    // this is for routing to next/previous page
    const handleNavigation = async (direction) => {
        if (direction === '+') {
            const label =
                isActivePageIndex === 0 ? 'generalInfo' : isActivePageIndex === 1 ? 'variantInfo' : 'deliveryInfo'
            const isValid = handleErrorMsg(label)

            if (isValid) {
                if (isActivePageIndex === pages.length - 1) {
                    // Handle POST request when on the last page (delivery page)
                    handleSubmit()
                } else {
                    const nextPage = pages[isActivePageIndex + 1]
                    navigate(`/products/add/${nextPage}`)
                }
            }
        } else {
            const nextPage = pages[isActivePageIndex - 1]
            navigate(`/products/add/${nextPage}`)
        }
    }

    const handleSubmit = async () => {
        const { generalInfo } = productData
        const userInfo = stateUser.info
        try {
            // Validation
            if (!productData?.generalInfo) {
                throw new Error('General info is missing')
            }

            // API Calls
            const createNewProduct = await createProduct(productData, generalInfo?.sellerid)

            if (createNewProduct) {
                toast('Product created successfully')
            }

            const { image_id, id: productId } = createNewProduct || {}

            if (image_id && productId && generalInfo?.productImages) {
                const newProductImages = await updateProductImages(generalInfo?.productImages, image_id)
                if (newProductImages) {
                    toast('Product images updated successfully')
                }
            }

            if (generalInfo?.description?.file && productId) {
                const newProductImagesDescriptionImg = await uploadPDescriptionImage(
                    productId,
                    generalInfo?.description?.file
                )
                if (newProductImagesDescriptionImg) {
                    toast('Product description image uploaded successfully')
                }
            }

            navigate('/products/products-lists') // Navigate to the next page
        } catch (error) {
            console.error('Error:', error.message || error)
        }
    }

    return (
        <div>
            <div className={classes.layout}>
                <h2>Add New Product</h2>
                <div className={classes.navigation}>
                    {pages.map((page, index) => {
                        const lastIndex = pages.length - 1 === index
                        const active = isActivePageIndex === index
                        const completedPage = isActivePageIndex > index

                        return (
                            <div
                                key={page.name}
                                className={`${completedPage ? classes.completed : ''} ${active ? classes.active : ''}`}>
                                <div className={classes.indicator}>{svgs.click}</div>
                                <div>
                                    <h3>
                                        {page} {index == 1 && '& pricing'}
                                    </h3>
                                </div>
                                {!lastIndex && <div className={classes.line}>{svgs.line}</div>}
                            </div>
                        )
                    })}
                </div>
            </div>
            <Outlet
                context={{
                    productData,
                    setProductData,
                }}
            />
            <div className={classes.footer}>
                <div className={classes.btnGroup}>
                    <div onClick={() => handleNavigation('-')}>
                        {isActivePageIndex > 0 && <DefaultBtn label="Go Back" />}
                    </div>
                    <div onClick={() => handleNavigation('+')}>
                        <PrimaryBtn label={isActivePageIndex == 2 ? 'Submit' : 'Continue'} iconPosition={'right'} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductLayout

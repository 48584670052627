import { faEye, faPenToSquare } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import Pagination from '../../Pagination/Pagination'
import PopUP from '../../PopUP/PopUP'
import TableV1 from '../../Resources/TableV1/TableV1'
import TitleContainer from '../../Resources/TitleContainer/TitleContainer'
import Edit from '../Edit/Edit'
import classes from './InActiveSellers.module.css'

export default function InActiveSellers({ sellers, pageSkip, setPageSkip, pageAll }) {
    const [show, setShow] = useState(false)
    const [userId, setUserId] = useState(false)
    const [isActive, setIsActive] = useState('')
    const [popUp, setPopUp] = useState(false)

    return (
        <div className={classes.wrapper}>
            <TitleContainer title={'Inactive Seller List'} isCount={true} count={pageAll} />
            <TableV1>
                <thead>
                    <tr className={`${classes.tableRow}`}>
                        <th>Sl.</th>
                        <th>Seller ID</th>
                        <th>Company Name</th>
                        <th>Owner </th>
                        <th>City</th>
                        <th>Category </th>
                        <th>Phone</th>
                        <th>Date</th>
                        <th>Subscription</th>
                        <th>Details</th>
                        <th>Action</th>
                    </tr>
                </thead>

                <tbody>
                    {sellers &&
                        sellers.map((data, index) => (
                            <tr className={classes.row} key={index}>
                                <td>{index + 1 + pageSkip}</td>
                                <td>{data.Seller?.seller_id}</td>
                                <td>{data.Seller.page_name}</td>
                                <td>{data.User.name}</td>
                                <td>{data.Seller.city}</td>
                                <td>{data.Seller.category}</td>
                                <td>{data.Seller.poc_phone}</td>
                                <td>{data.Seller.created_at.slice(0, 10)}</td>
                                <td>-</td>
                                <td>
                                    <Link to={`https://biztradebd.com/companies/${data?.User?.id + 10000}`}>
                                        <FontAwesomeIcon className={classes.icon} icon={faEye} />
                                    </Link>
                                </td>
                                <td
                                    onClick={() => {
                                        setShow(true)
                                        setUserId(data.User.id)
                                        setIsActive(data.User.is_active)
                                    }}>
                                    <FontAwesomeIcon
                                        className={classes.icon}
                                        icon={faPenToSquare}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </td>
                            </tr>
                        ))}
                </tbody>
            </TableV1>

            {show && <PopUP setShow={setShow} userId={userId} isActive={isActive} />}
            <Pagination pageSkip={pageSkip} setPageSkip={setPageSkip} listItem={sellers} pageAll={pageAll} />
            {popUp && <Edit setPopUp={setPopUp} />}
        </div>
    )
}

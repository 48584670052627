import React, { Fragment, useEffect, useRef, useState } from 'react'
import Cropper from 'react-easy-crop'
import ReactSlider from 'react-slider'
import { generateImage } from '../../../../utils/cropImage'
import { svgs } from '../../../../utils/findSvgs/findSvgs'
import { DefaultBtn, PrimaryBtn } from '../../../Resources/Buttons'
import classes from './ImagePreview.module.css'

export default function ImagePreview({ setProductData, productData, singleProductData }) {
    const { generalInfo, error } = productData || {}
    const { productImages: images, initialImages = [] } = generalInfo
    const [isImageUploadAble, setImageUploadAble] = useState(false)
    const inputRef = useRef()
    const [productImages, setProductImages] = useState({
        image1: {
            src: '',
            file: null,
        },
        image2: {
            src: '',
            file: null,
        },
        image3: {
            src: '',
            file: null,
        },
        image4: {
            src: '',
            file: null,
        },
        image5: {
            src: '',
            file: null,
        },
    })
    const [image, setImage] = useState(null)
    const [croppedArea, setCroppedArea] = useState(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1.5)
    const [tempImageIndex, setTempImageIndex] = useState(null)
    const [hoverImageKey, setHoverImageKey] = useState(null)

    const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels)
    }

    const onSelectFile = (event, index) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0]
            const url = URL.createObjectURL(file)
            setImage(url)
            setTempImageIndex(index)
            const dynamicImage = `image${index}`
            setImageUploadAble(true)
            setProductImages((prevImages) => ({
                ...prevImages,
                [dynamicImage]: { src: url, file },
            }))
        }
    }

    const onUpload = async () => {
        const blob = await generateImage(image, croppedArea)
        if (blob) {
            const dynamicImage = `image${tempImageIndex}`

            // Create a file from the blob
            const croppedFile = new File([blob], `cropped_image_${tempImageIndex}.jpeg`, { type: 'image/jpeg' })

            setProductImages((prevImages) => ({
                ...prevImages,
                [dynamicImage]: { ...prevImages[dynamicImage], src: URL.createObjectURL(blob), file: croppedFile },
            }))

            setTempImageIndex(null)
            setImageUploadAble(true)
        }
    }

    const onCancel = () => {
        setImage('')
        setTempImageIndex(null)
    }

    useEffect(() => {
        if (!isImageUploadAble) {
            if (generalInfo.productImages) {
                setProductImages({ ...generalInfo.productImages })
            }
        }
    }, [productData, isImageUploadAble])

    useEffect(() => {
        if (isImageUploadAble) {
            const filteredProductImages = Object.fromEntries(
                Object.entries(productImages).filter(([key, image]) => image.src || !image.src)
            )

            // Update product data with the filtered object
            setProductData((prev) => ({
                ...prev,
                generalInfo: {
                    ...prev.generalInfo,
                    productImages: filteredProductImages,
                },
            }))
        }

        setImageUploadAble(false)
    }, [isImageUploadAble, productImages])

    return (
        <Fragment>
            <div className={classes.preview}>
                <div className={classes.imageBox1}>
                    <div
                        className={
                            initialImages.length === 0 && error.generalInfo && !generalInfo?.productImages?.image1
                                ? `${classes.previewBox} ${classes.error}`
                                : classes.previewBox
                        }>
                        <input
                            type="file"
                            accept=".png, .jpg, .jpeg"
                            ref={inputRef}
                            id="imageUpload1"
                            onChange={(e) => onSelectFile(e, 1)}
                        />
                        <label htmlFor="imageUpload1">{svgs.plus}</label>
                        {productImages.image1?.src && (
                            <Fragment>
                                <div className={classes.image}>
                                    <img src={productImages.image1?.src} alt="Preview 1" />
                                </div>
                                <label htmlFor="imageUpload1" className={classes.overlay}>
                                    {svgs.plus}
                                </label>
                            </Fragment>
                        )}
                    </div>

                    {initialImages.length === 0 && error?.generalInfo && !productImages?.image1?.file ? (
                        <p className={classes.warning}> {svgs.warning} Display Image is required</p>
                    ) : (
                        <label htmlFor="imageUpload0">
                            Display Image <sup>*</sup>
                        </label>
                    )}
                </div>
                <div className={classes.imageBox2}>
                    {Object.keys(productImages)
                        .slice(1)
                        .map((imageKey, index) => {
                            const imageIndex = Number(imageKey.split('image')[1])
                            return (
                                <div key={imageKey}>
                                    <div
                                        className={classes.previewBox}
                                        onMouseEnter={() => setHoverImageKey(imageIndex)}
                                        onMouseLeave={() => setHoverImageKey(null)}>
                                        <input
                                            type="file"
                                            accept=".png, .jpg, .jpeg"
                                            ref={inputRef}
                                            onChange={(e) => onSelectFile(e, imageIndex)}
                                            id={`imageUpload${imageIndex + 1}`}
                                        />
                                        <label htmlFor={`imageUpload${imageIndex + 1}`}>{svgs.plus}</label>
                                        {productImages[imageKey]?.src && (
                                            <Fragment>
                                                <div className={classes.image}>
                                                    <img
                                                        src={productImages[imageKey]?.src}
                                                        alt={`Preview ${imageIndex + 1}`}
                                                    />
                                                </div>
                                                {imageIndex === hoverImageKey && (
                                                    <label
                                                        htmlFor={`imageUpload${imageIndex + 1}`}
                                                        className={classes.overlay}>
                                                        {svgs.plus}
                                                    </label>
                                                )}
                                            </Fragment>
                                        )}
                                    </div>
                                    <label htmlFor={`imageUpload${index + 1}`}>Image {imageIndex}</label>
                                </div>
                            )
                        })}
                </div>
            </div>

            {tempImageIndex && (
                <div className={classes.cropContainer}>
                    <div className={classes.cropWrapper}>
                        <Cropper
                            image={image}
                            crop={crop}
                            zoom={zoom}
                            aspect={1}
                            onCropChange={setCrop}
                            onZoomChange={setZoom}
                            onCropComplete={onCropComplete}
                        />
                    </div>
                    <div>
                        <ReactSlider
                            min={1.01}
                            max={3}
                            step={0.01}
                            value={zoom}
                            onChange={(newZoom) => setZoom(newZoom)}
                            className={classes.slider}
                            thumbClassName={classes.thumb}
                            trackClassName={classes.track}
                        />
                        <div className={classes.actions}>
                            <div onClick={() => onCancel()}>
                                <DefaultBtn label={'cancel'} />
                            </div>
                            <div onClick={() => onUpload()}>
                                <PrimaryBtn label={'crop'} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    )
}

import React, { Fragment, useEffect, useState } from 'react'
import { svgs } from '../../../../utils/findSvgs/findSvgs'
import { priceType, variantType } from '../../../../utils/static/static'
import { PrimaryBtn } from '../../../Resources/Buttons'
import RenderDropDown from '../../../Resources/CustomDropDown/RenderDropdown/RenderDropDown'
import classes from './PorductVariant.module.css'
import ProductVariantTable from './ProductVariantTable/ProductVariantTable'
import SingleVariant from './SingleVariant'

const ProductVariant = ({ variants, productData, setProductData }) => {
    const [isVariantRadioBtn, setVariantRadioBtn] = useState(false)
    const [isVariantFormOpen, setIsVariantFormOpen] = useState(true)
    const [isSeperatePrieBtn, setSeperatePriceBtn] = useState(false)
    const [attribute, setAttribute] = useState('')
    // states from single variant
    const { variantInfo } = productData

    const saveVariant = () => {
        setProductData((prev) => {
            const updatedVariants = prev.variantInfo.selectedVariants.map((variant) => {
                if (!variant.isCompleted) {
                    return {
                        ...variant,
                        isCompleted: true,
                        isEdited: false,
                    }
                }
                return variant
            })

            return {
                ...prev,
                variantInfo: {
                    ...prev.variantInfo,
                    selectedVariants: updatedVariants,
                    isEdited: false,
                },
            }
        })
    }

    const editVariant = (editVariantId) => {
        setProductData((prev) => {
            const updatedVariants = prev.variantInfo.selectedVariants.map((variant) => {
                if (variant.id === editVariantId) {
                    return {
                        ...variant,
                        isCompleted: false,
                        isEdited: true,
                    }
                }
                return { ...variant, isCompleted: true }
            })

            return {
                ...prev,
                variantInfo: {
                    ...prev.variantInfo,
                    selectedVariants: updatedVariants,
                },
            }
        })
    }

    const deleteVariant = (deleteVariantId) => {
        setProductData((prev) => {
            const updatedVariants = prev.variantInfo.selectedVariants.filter(
                (variant) => variant.id !== deleteVariantId
            )
            return {
                ...prev,
                variantInfo: {
                    ...prev.variantInfo,
                    selectedVariants: updatedVariants,
                },
            }
        })
    }

    //  attribute handler

    const createAttribute = (e) => {
        if (e.key === 'Enter' || (e.type === 'click' && attribute.length > 1)) {
            setProductData((prev) => {
                const updatedVariants = prev.variantInfo.selectedVariants.map((variant) => {
                    if (!variant.isCompleted) {
                        return {
                            ...variant,
                            attributes: [...variant.attributes, attribute],
                        }
                    }
                    return variant
                })

                return {
                    ...prev,
                    variantInfo: {
                        ...prev.variantInfo,
                        selectedVariants: updatedVariants,
                    },
                }
            })
            setAttribute('')
        }
    }

    const deleteAttribute = (removeAttribute) => {
        setProductData((prev) => {
            const updatedVariants = prev.variantInfo.selectedVariants.map((variant) => {
                if (!variant.isCompleted) {
                    return {
                        ...variant,
                        attributes: variant.attributes.filter((attribute) => attribute !== removeAttribute),
                    }
                }
                return variant
            })

            return {
                ...prev,
                variantInfo: {
                    ...prev.variantInfo,
                    selectedVariants: updatedVariants,
                },
            }
        })
    }

    // it will make sure to render product variant type radio button if profits

    useEffect(() => {
        const { profit } = variantInfo.quantityAndPrice
        if (profit.b2bpm > 0 || profit.b2cpm > 0) {
            setVariantRadioBtn(true)
        } else {
            setVariantRadioBtn(false)
        }
    }, [variantInfo.quantityAndPrice])

    // it will check variant list. if varinat list >= 2 , then make variant form false

    useEffect(() => {
        const checkVariant = variantInfo.selectedVariants.some((variant) => !variant.isCompleted)
        if (!checkVariant && variantInfo.selectedVariants.length >= 2) {
            setIsVariantFormOpen(false)
        } else {
            setIsVariantFormOpen(true)
        }
        // for seperate price button
        setSeperatePriceBtn(variantInfo.selectedVariants.some((variant) => variant.isCompleted))
    }, [variantInfo.selectedVariants])

    // the purpose of this to make variant select disabled when edit
    const isEditedVariant = variantInfo?.selectedVariants.find((variant) => variant.isEdited)

    useEffect(() => {
        setProductData((prev) => ({
            ...prev,
            error: '',
        }))
    }, [variantInfo.selectedVariants, variantInfo.separetePrice])

    return (
        <form onSubmit={(e) => e.preventDefault()}>
            <Fragment>
                {/* main form */}

                {!variantInfo.separetePrice && (
                    <SingleVariant productData={productData} setProductData={setProductData} />
                )}
                {isVariantRadioBtn && (
                    <div className={classes.formBox}>
                        <div className={`${classes.title}`}>How would you like to sell your product?</div>
                        <div className={classes.radioBtn}>
                            {variantType?.map((sell) => {
                                const isActiveId = !variantInfo.isMultiVariant ? 1 : 2
                                return (
                                    <div
                                        className={`${sell.id == isActiveId ? classes.activeBox : ''}`}
                                        key={sell.id}
                                        onClick={() =>
                                            setProductData((prev) => ({
                                                ...prev,
                                                variantInfo: {
                                                    ...prev.variantInfo,
                                                    isMultiVariant: !variantInfo.isMultiVariant,
                                                },
                                            }))
                                        }>
                                        <div className={`${sell.id == isActiveId ? classes.active : ''}`}>
                                            <div></div>
                                        </div>
                                        <label>{sell.name}</label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}
            </Fragment>

            {variantInfo?.isMultiVariant ? (
                <Fragment>
                    <div className={classes.formBox}>
                        <div className={`${classes.title} ${classes.borderBottom}`}>
                            <div className={classes.spreadTitle}>
                                <div> {svgs.variant} Product Variants</div>
                                <div
                                    onClick={() => {
                                        setIsVariantFormOpen(true)
                                    }}>
                                    {isVariantFormOpen && <PrimaryBtn label={'Add Variant'} iconPosition={'left'} />}
                                </div>
                            </div>
                        </div>
                        {isVariantFormOpen && (
                            <div className={classes.formLayout2}>
                                <div className={`${classes.formGroup} ${classes.disabledVariant}`}>
                                    <RenderDropDown
                                        label={'Variant Name'}
                                        data={variants}
                                        placeholder={isEditedVariant ? '' : 'Select Your Variant'}
                                        dropdownLabel={'Variant Name'}
                                        setProductData={setProductData}
                                        isDisabled={isEditedVariant}
                                        defaultValue={
                                            variantInfo.selectedVariants.find(
                                                (variant) => !variant.isCompleted || variant.isEdited
                                            )?.variantName
                                        }
                                    />
                                    <span>{isEditedVariant?.variantName}</span>
                                </div>
                                <div className={classes.formGroup}>
                                    <label>
                                        Attributes<sup>*</sup>
                                    </label>
                                    <div className={classes.attribute}>
                                        <div className={classes.itemGroup}>
                                            <input
                                                type="string"
                                                placeholder="Create Attributes"
                                                onKeyDown={createAttribute}
                                                value={attribute}
                                                onChange={(e) => setAttribute(e.target.value)}
                                            />
                                            {attribute.length > 1 && (
                                                <button onClick={createAttribute} className={classes.submitBtn}>
                                                    Enter
                                                </button>
                                            )}
                                        </div>
                                        <div className={classes.wrapper}>
                                            {variantInfo?.selectedVariants
                                                .find((variant) => !variant.isCompleted)
                                                ?.attributes?.map((attribute, index) => (
                                                    <button key={index} className={classes.attributeBtn}>
                                                        {attribute}
                                                        <span onClick={() => deleteAttribute(attribute)}>
                                                            {svgs.closeIcon}
                                                        </span>
                                                    </button>
                                                ))}
                                        </div>
                                    </div>
                                </div>

                                <div className={classes.formGroup}></div>
                                <div className={classes.attributeBtnWrapper}>
                                    <div className={classes.btnGroup}>
                                        <div onClick={saveVariant}>
                                            <PrimaryBtn label={'Save Variant'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {renderVariants(variantInfo?.selectedVariants, editVariant, deleteVariant, isVariantFormOpen)}
                    </div>

                    {isSeperatePrieBtn && (
                        <div className={classes.formBox}>
                            <div className={`${classes.title}`}>
                                Would you like to add separate prices for each variant?
                            </div>
                            <div className={classes.radioBtn}>
                                {priceType?.map((sell) => {
                                    const isActiveId = !variantInfo.separetePrice ? 1 : 2
                                    return (
                                        <div
                                            className={`${sell.id == isActiveId ? classes.activeBox : ''}`}
                                            key={sell.id}
                                            onClick={() =>
                                                setProductData((prev) => ({
                                                    ...prev,
                                                    variantInfo: {
                                                        ...prev.variantInfo,
                                                        separetePrice: !variantInfo.separetePrice,
                                                    },
                                                }))
                                            }>
                                            <div className={`${sell.id == isActiveId ? classes.active : ''}`}>
                                                <div></div>
                                            </div>
                                            <label>{sell.name}</label>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )}

                    {variantInfo?.selectedVariants?.length > 0 && (
                        <ProductVariantTable productData={productData} setProductData={setProductData} />
                    )}
                </Fragment>
            ) : undefined}
        </form>
    )
}

const renderVariants = (selectedVariants, editVariant, deleteVariant, isVariantFormOpen) => {
    return (
        <>
            {selectedVariants.map((variant) => {
                const { id, variantName, attributes, isCompleted } = variant
                return (
                    <Fragment>
                        {isCompleted && (
                            <div className={isVariantFormOpen && classes.renderVariantWrapper}>
                                <div className={classes.renderVariant}>
                                    <div className={classes.card}>
                                        <h5>{variantName}</h5>
                                        {attributes?.map((attribute, index) => (
                                            <button key={index} className={classes.attributeBtn}>
                                                {attribute}
                                            </button>
                                        ))}
                                    </div>
                                    <div className={classes.action}>
                                        <button onClick={() => editVariant(id)}>{svgs.editIcon}Edit</button>
                                        <button onClick={() => deleteVariant(id)}>{svgs.trashIcon}</button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Fragment>
                )
            })}
        </>
    )
}

export default ProductVariant

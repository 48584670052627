import React, { useContext, useState } from 'react'
import { Auth } from '../../contexts/allContext'
import Formv1 from '../Resources/FormV1/Formv1'
import classes from './CreateVariant.module.css'

const CreateVariant = () => {
    const [variant, setVariant] = useState('')
    const [msg, setMsg] = useState('')
    const { stateAuth } = useContext(Auth)
    const [submitStatus, setSubmitStatus] = useState(false)
    const apiV1 = process.env.REACT_APP_API_URL_V1
    const token = stateAuth.token
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const response = await fetch(`${apiV1}/variants/`, {
                headers: {
                    Accept: 'appllication/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'POST',
                body: JSON.stringify({
                    name: variant,
                }),
            })

            if (response.ok) {
                setSubmitStatus(true)
                setMsg('Variant Added!')
                setVariant('')
                setTimeout(() => {
                    setSubmitStatus(false)
                }, 2000)
            } else {
                setMsg('Something went wrong!')
                setSubmitStatus(true)
            }
        } catch (error) {
            setMsg('Something went wrong!')
            setSubmitStatus(true)
            setTimeout(() => {
                setSubmitStatus(false)
            }, 2000)
        }
    }
    return (
        <div className={classes.container}>
            <Formv1 title={'Create Variant'}>
                <form onSubmit={handleSubmit}>
                    <div className={classes.innerContainer}>
                        <p>Variant Name</p>

                        <input type="text" value={variant} onChange={(e) => setVariant(e.target.value)} required />

                        {submitStatus && (
                            <div>
                                <span
                                    className={classes.submitMessage}
                                    style={{ color: `${msg?.substring(0, 2) === 'Va' ? '#5cb484' : '#a10f0f'}` }}>
                                    {msg}
                                </span>
                            </div>
                        )}
                    </div>
                    <div className={classes.submitContainer}>
                        <button type="submit">Submit</button>
                    </div>
                </form>
            </Formv1>
        </div>
    )
}

export default CreateVariant

import React, { useEffect, useState } from 'react'
import Select, { components } from 'react-select'
import { svgs } from '../../../utils/findSvgs/findSvgs'
import classes from './CustomDropDown.module.css'

const DropdownIndicator = (props) => {
    return <components.DropdownIndicator {...props}>{svgs.downArrow}</components.DropdownIndicator>
}

const CustomDropDown = ({
    options,
    placeholder,
    dropdownLabel,
    setProductData,
    customNoOptionsMessage,
    defaultValue,
    error,
    isDisabled,
}) => {
    const handleChange = (selectedOption) => {
        // for the general page of product create
        if (dropdownLabel) {
            if (selectedOption) {
                setProductData((prev) => ({
                    ...prev,
                    generalInfo: {
                        ...prev.generalInfo,
                        [dropdownLabel]: dropdownLabel === 'sellerid' ? selectedOption.userid : selectedOption.value,
                    },
                }))
            }
        }

        if (dropdownLabel.includes('Variant')) {
            setProductData((prev) => {
                const filteredVariants = prev.variantInfo.selectedVariants.filter((variant) => variant.isCompleted)
                return {
                    ...prev,
                    variantInfo: {
                        ...prev.variantInfo,
                        selectedVariants: [
                            ...filteredVariants,
                            {
                                id: filteredVariants.length + 1,
                                variantName: selectedOption.label,
                                attributes: [],
                                isCompleted: false,
                            },
                        ],
                    },
                }
            })
        }
    }

    const handleInputChange = (inputValue) => {
        if (dropdownLabel) {
            if (inputValue) {
                setProductData((prev) => ({
                    ...prev,
                    generalInfo: { ...prev.generalInfo, [dropdownLabel]: inputValue },
                }))
            }
        }
    }

    return (
        <div className={`${classes.customDropDown} ${error ? classes.error : ''}`}>
            <Select
                isDisabled={isDisabled}
                options={options}
                classNamePrefix="react-select"
                components={{ DropdownIndicator }}
                placeholder={placeholder}
                onChange={handleChange}
                onInputChange={handleInputChange}
                noOptionsMessage={() => customNoOptionsMessage}
                value={options?.find((option) => option.value == defaultValue || option.label == defaultValue)}
            />
        </div>
    )
}

export default CustomDropDown

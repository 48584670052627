import React, { useState } from 'react'
import { svgs } from '../../../utils/findSvgs/findSvgs'
import classes from '../../Product/create/ProductAdd/ProductAdd.module.css'

const ProductQuantity = ({ setProductData, productData, label, minimumValue = 5 }) => {
    const [isFocus, setIsFocus] = useState(false)

    // Dynamically access the value based on the label prop
    const value = label === 'quantity' ? productData.variantInfo.quantity : productData.generalInfo.moq

    // Handle increment/decrement logic for quantity or moq
    const handleQuantity = (indicator) => {
        const updatedValue =
            indicator === '+'
                ? value + minimumValue
                : value - minimumValue >= minimumValue
                ? value - minimumValue
                : value

        if (label === 'quantity') {
            setProductData((prev) => ({
                ...prev,
                variantInfo: { ...prev.variantInfo, quantity: updatedValue },
            }))
        } else if (label === 'moq') {
            setProductData((prev) => ({
                ...prev,
                generalInfo: { ...prev.generalInfo, moq: updatedValue },
            }))
        }

        setIsFocus(true)
    }

    // Handle input change for custom values
    const handleInputChange = (e) => {
        const newValue = Number(e.target.value)
        if (label === 'quantity') {
            setProductData((prev) => ({
                ...prev,
                variantInfo: { ...prev.variantInfo, quantity: newValue },
            }))
        } else if (label === 'moq') {
            setProductData((prev) => ({
                ...prev,
                generalInfo: { ...prev.generalInfo, moq: newValue },
            }))
        }
    }

    return (
        <div
            className={`${classes.quantity} ${isFocus ? classes.focus : ''}`}
            onMouseEnter={() => setIsFocus(true)}
            onMouseLeave={() => setIsFocus(false)}>
            <div onClick={() => handleQuantity('-')}>{svgs.minus}</div>
            <input type="number" onChange={handleInputChange} value={value} min={minimumValue} />
            <div onClick={() => handleQuantity('+')}>{svgs.quantiyPlus}</div>
        </div>
    )
}

export default ProductQuantity

import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import logo from '../../../assets/biztrade_logo/logo.png'
import shortLogo from '../../../assets/biztrade_logo/short-logo.png'
import { svgs } from '../../../utils/findSvgs/findSvgs'
import classes from '../Sidebar/Sidebar.module.css'

export default function Sidebar({ sidebarItems, isSidebarPanelOpen }) {
    const location = useLocation()
    const [showToolTipIndex, setShowToolTipIndex] = useState(null)
    const [trackSubMenu, setTrackSubMenu] = useState({})

    const toggleMenuIndex = (menu) => {
        if (menu.id === trackSubMenu.id) {
            return setTrackSubMenu({})
        }
        setTrackSubMenu({ ...menu, open: true })
    }

    useEffect(() => {
        if (location.pathname.includes(trackSubMenu.path)) {
            return setTrackSubMenu({ ...trackSubMenu, pause: false })
        }
        if (location.pathname && trackSubMenu.open) {
            setTrackSubMenu({ ...trackSubMenu, pause: true })
        }
    }, [location.pathname])

    return (
        <div className={`${classes.sidebar} ${isSidebarPanelOpen ? classes.open : classes.close}`}>
            <Link to={`https://biztradebd.com/`}>
                <div className={`${classes.logo} ${isSidebarPanelOpen ? classes.open : classes.close}`}>
                    <img src={isSidebarPanelOpen ? logo : shortLogo} alt="" />
                </div>
            </Link>
            <div>
                {sidebarItems.map((item) => {
                    const open = trackSubMenu.open && trackSubMenu.id === item.id
                    const isPaused = trackSubMenu.pause && trackSubMenu.id === item.id
                    return (
                        <React.Fragment key={item.id}>
                            {item?.submenu?.length > 0 ? (
                                <>
                                    {isSidebarPanelOpen ? (
                                        <li
                                            className={`${classes.linkItem} ${classes.dropdownList} ${
                                                open
                                                    ? `${classes.active} ${isPaused && classes.pause}`
                                                    : classes.inactive
                                            }`}
                                            onMouseEnter={() => setShowToolTipIndex(item.id)}
                                            onMouseLeave={() => setShowToolTipIndex(null)}>
                                            <Link
                                                to=""
                                                onClick={(event) => {
                                                    event.preventDefault() // Prevent navigation
                                                    toggleMenuIndex(item)
                                                }}>
                                                <div>
                                                    {item.icon}
                                                    <div>
                                                        <div className={classes.productText}>
                                                            {isSidebarPanelOpen && item.text}
                                                        </div>
                                                    </div>
                                                </div>
                                                {isSidebarPanelOpen && (
                                                    <div
                                                        className={open ? classes.iconDropDown : classes.iconUpperDown}>
                                                        {svgs.upArrow}
                                                    </div>
                                                )}
                                            </Link>
                                            {!open && showToolTipIndex === item.id && (
                                                <div className={classes.tooltip}>{item.text}</div>
                                            )}
                                        </li>
                                    ) : (
                                        <li
                                            className={`${classes.linkItem} ${classes.dropdownList} ${
                                                !open ? `${classes.pause}` : classes.active
                                            }`}
                                            onMouseEnter={() => setShowToolTipIndex(item.id)}
                                            onMouseLeave={() => setShowToolTipIndex(null)}>
                                            <Link
                                                to=""
                                                onClick={(event) => {
                                                    event.preventDefault() // Prevent navigation
                                                    toggleMenuIndex(item)
                                                }}>
                                                <div>
                                                    {item.icon}
                                                    <div>
                                                        <div className={classes.productText}>
                                                            {isSidebarPanelOpen && item.text}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={open ? classes.iconDropDown : classes.iconUpperDown}>
                                                    {svgs.upArrow}
                                                </div>
                                            </Link>
                                            {!open && showToolTipIndex === item.id && (
                                                <div className={classes.tooltip}>{item.text}</div>
                                            )}
                                        </li>
                                    )}

                                    {item?.submenu?.map((menu) => {
                                        return (
                                            <div
                                                className={`${classes.dropDown} ${
                                                    open
                                                        ? `${classes.open} ${isPaused && classes.pause}`
                                                        : `${classes.close}`
                                                }`}>
                                                <li
                                                    className={`${classes.linkItem} ${
                                                        location.pathname.includes(menu.path)
                                                            ? classes.active
                                                            : classes.inactive
                                                    }`}
                                                    onMouseEnter={() => setShowToolTipIndex(menu.id)}
                                                    onMouseLeave={() => setShowToolTipIndex(null)}>
                                                    <div>
                                                        <Link to={menu.path} key={menu?.id}>
                                                            {menu.icon}

                                                            {isSidebarPanelOpen && menu.text}
                                                        </Link>
                                                    </div>
                                                    {showToolTipIndex == menu.id && (
                                                        <div className={classes.tooltip}>{menu.text}</div>
                                                    )}
                                                </li>
                                            </div>
                                        )
                                    })}
                                </>
                            ) : (
                                <li
                                    className={`${classes.linkItem} ${
                                        location.pathname.includes(item.path) ? classes.active : classes.inactive
                                    }`}
                                    onMouseEnter={() => setShowToolTipIndex(item.id)}
                                    onMouseLeave={() => setShowToolTipIndex(null)}>
                                    <Link to={item.path}>
                                        {item.icon}
                                        {isSidebarPanelOpen && item.text}
                                    </Link>
                                    {showToolTipIndex === item.id && <div className={classes.tooltip}>{item.text}</div>}
                                </li>
                            )}
                        </React.Fragment>
                    )
                })}
            </div>
        </div>
    )
}

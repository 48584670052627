import React, { useState } from 'react'
import classes from './ToggleButton.module.css'

export default function ToggleButton({ label, initialState, onToggle }) {
    const [isOn, setIsOn] = useState(initialState)

    const handleToggle = () => {
        const newState = !isOn
        setIsOn(newState)
        if (onToggle) onToggle(newState)
    }

    return (
        <div className={classes.toggleContainer}>
            {label && (
                <>
                    <span className={classes.label}>{label}</span>
                    <div className={`${classes.toggleSwitch} ${isOn ? classes.on : ''}`} onClick={handleToggle}>
                        <div className={classes.toggleCircle}></div>
                        <span className={isOn ? classes.toggleText : classes.toggleTextOff}>{isOn ? 'ON' : 'OFF'}</span>
                    </div>
                </>
            )}
        </div>
    )
}

import { useContext } from 'react'
import { Auth } from '../../contexts/allContext'
import { apiV1 } from '../../utils/api'
import classes from './PopUp.module.css'

// const auth = JSON.parse(localStorage.getItem('auth'))
// const token = auth.token

export default function PopUP(props) {
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const handleClick = async (value) => {
        try {
            const response = await fetch(`${apiV1}/admin/user/switch/${value}`, {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            const log = await response.json()
            if (response.ok) {
                console.log('Status Updated')
                window.location.reload()
            } else {
                console.log(log.context)
            }
        } catch {}
    }

    return (
        <div className={classes.wrapper}>
            <div className={classes.container}>
                <div>
                    <p> Are you confirm to {props.isActive ? 'deactivate' : 'activate'} this seller?</p>
                    <div className={classes.buttonContainer}>
                        <button onClick={() => handleClick(props.userId)}>Yes</button>
                        <button onClick={() => props.setShow(false)}>No</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

import React, { useContext, useState } from 'react'
import { IoClose } from 'react-icons/io5'
import { toast } from 'react-toastify'
import imgNotFound from '../../../../../assets/Product/imageNotFound.svg'
import { Auth } from '../../../../../contexts/allContext'
import { apiV2 } from '../../../../../utils/api'
import PopupV1 from '../../../../Resources/PopupV1/PopupV1'
import ToggleButton from '../../../../Resources/ToggleButton/ToggleButton'
import classes from './InfoPopup.module.css'

export default function InfoPopup({ setForm, form, singleProduct, setProductActive, productActive }) {
    const { stateAuth } = useContext(Auth)
    const token = stateAuth?.token

    const [isOnActive, setIsOnActive] = useState(singleProduct.Product?.is_active)
    const handleToggleActive = (newState) => {
        setIsOnActive(newState)
        handleStatusChange({ is_active: newState })
    }

    const [isOnFeature, setIsOnFeature] = useState(singleProduct.Product?.is_feature)
    const handleToggleFeature = (newState) => {
        setIsOnFeature(newState)
        handleStatusChange({ is_feature: newState })
    }

    const [isOnAdult, setIsOnAdult] = useState(singleProduct.Product?.is_adult)
    const handleToggleAdult = (newState) => {
        setIsOnAdult(newState)
        handleStatusChange({ is_adult: newState })
    }

    const [isOnBiztrade, setIsOnBiztrade] = useState(singleProduct.Product?.is_biztrade)
    const handleToggleBiztrade = (newState) => {
        setIsOnBiztrade(newState)
        handleStatusChange({ is_biztrade: newState })
    }

    const [isOnRetail, setIsOnRetail] = useState(singleProduct.Product?.is_retail)
    const handleToggleRetail = (newState) => {
        setIsOnRetail(newState)
        handleStatusChange({ is_retail: newState })
    }

    const [isOnWholesale, setIsOnWholesale] = useState(singleProduct.Product?.is_wholesale)
    const handleToggleWholesale = (newState) => {
        setIsOnWholesale(newState)
        handleStatusChange({ is_wholesale: newState })
    }

    const toastSuccess = () => {
        toast.dismiss()
        toast.success('Product Status updated!', {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
        })
    }

    const handleStatusChange = async (updatedFields) => {
        try {
            const response = await fetch(`${apiV2}/products/product/${singleProduct.Product?.id}`, {
                method: 'PATCH',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    is_active: updatedFields.is_active ?? isOnActive,
                    is_adult: updatedFields.is_adult ?? isOnAdult,
                    is_biztrade: updatedFields.is_biztrade ?? isOnBiztrade,
                    is_feature: updatedFields.is_feature ?? isOnFeature,
                    is_retail: updatedFields.is_retail ?? isOnRetail,
                    is_wholesale: updatedFields.is_wholesale ?? isOnWholesale,
                }),
            })

            if (response.ok) {
                console.log('Success: Product status updated')
                setProductActive(!productActive)
                toastSuccess()
            } else {
                console.log('Error: Something went wrong!')
            }
        } catch (error) {
            console.error('Request failed:', error)
        }
    }

    return (
        <div className={classes.container}>
            <PopupV1 setForm={setForm} form={form} width="40%">
                <div className={classes.popupDetails}>
                    <div className={classes.close}>
                        <div className={classes.closeIcon}>
                            <p onClick={() => setForm(!form)}>
                                <IoClose size={20} />
                            </p>
                        </div>
                        <div className={classes.header}>
                            <p>Update Product Status</p>
                        </div>
                        <hr />
                    </div>
                    <div className={classes.productDetails}>
                        <div className={classes.productTitle}>
                            <div className={classes.img}>
                                {singleProduct.ImgProduct.image_url == null ? (
                                    <img src={imgNotFound} alt="" className={classes.porductImage} />
                                ) : (
                                    <img
                                        src={singleProduct.ImgProduct.image_url}
                                        alt=""
                                        className={classes.porductImage}
                                    />
                                )}
                            </div>
                            <div className={classes.productinfo}>
                                <p className={classes.title}>{singleProduct?.Product?.title}</p>
                                <p className={classes.pageName}>{singleProduct?.Seller?.page_name}</p>
                            </div>
                        </div>
                        <div className={classes.stock}>
                            <p className={classes.title}>20</p>
                            <p className={classes.pageName}>Stock Product</p>
                        </div>
                    </div>
                    <div className={classes.btns}>
                        <ToggleButton label="Active" initialState={isOnActive} onToggle={handleToggleActive} />
                        <ToggleButton label="Biztrade" initialState={isOnBiztrade} onToggle={handleToggleBiztrade} />
                        <ToggleButton label="Feature" initialState={isOnFeature} onToggle={handleToggleFeature} />
                        <ToggleButton label="Adult" initialState={isOnAdult} onToggle={handleToggleAdult} />
                        <ToggleButton label="Retail" initialState={isOnRetail} onToggle={handleToggleRetail} />
                        <ToggleButton label="Wholesale" initialState={isOnWholesale} onToggle={handleToggleWholesale} />
                    </div>
                </div>
            </PopupV1>
        </div>
    )
}

import React from 'react'
import { TfiMenuAlt } from 'react-icons/tfi'
import classes from './TitleContainer.module.css'

const TitleContainer = ({ title, count, isCount }) => {
    return (
        <div className={classes.titleContainer}>
            <h2>
                <TfiMenuAlt size={14} />
                {title}
            </h2>
            {isCount && (
                <p>
                    Total: <span>{count}</span>
                </p>
            )}
        </div>
    )
}

export default TitleContainer

import { api } from '../utils/api'

export const useRequest = () => {
    const handleFetch = async (endPoint, params, token) => {
        try {
            const queryString = params ? '?' + new URLSearchParams(params).toString() : ''
            const response = await fetch(`${api}/${endPoint}${queryString}`, {
                method: 'GET',
                headers: {
                    Authorization: token ? `Bearer ${token}` : undefined,
                },
            })

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`)
            }

            const data = await response.json()
            return {
                data,
                isSuccess: true,
            }
        } catch (error) {
            console.error(error)
            return {
                data: null,
                isSuccess: false,
            }
        }
    }

    // Improved handleSubmit function
    const handleSubmit = async (endPoint, params, body, token, method = 'POST') => {
        try {
            const queryString = params ? '?' + new URLSearchParams(params).toString() : ''
            const response = await fetch(`${api}/${endPoint}${queryString}`, {
                method,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: token ? `Bearer ${token}` : undefined,
                },
                body: JSON.stringify(body),
            })

            const data = await response.json() // Parse the response body

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status} - ${data.message || 'Unknown error'}`)
            }

            return {
                data,
                isSuccess: true,
            }
        } catch (error) {
            console.error(error)
            return {
                data: null,
                isSuccess: false,
                error: error.message,
            }
        }
    }

    const handleImageSubmit = async (endPoint, params, body, token, method = 'PATCH') => {
        try {
            const queryString = params ? '?' + new URLSearchParams(params).toString() : ''
            const response = await fetch(`${api}/${endPoint}${queryString}`, {
                method,
                headers: {
                    Authorization: token ? `Bearer ${token}` : undefined,
                },
                body,
            })

            const data = await response.json()

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status} - ${data.message || 'Unknown error'}`)
            }

            return {
                data,
                isSuccess: true,
            }
        } catch (error) {
            console.error(error)
            return {
                data: null,
                isSuccess: false,
                error: error.message,
            }
        }
    }

    return { handleFetch, handleSubmit, handleImageSubmit }
}

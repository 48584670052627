import React, { useRef, useState } from 'react'
import ImgIcon from '../../../../../assets/Courier/imageFieldPlaceHolder.png'
import classes from './CourierLogo.module.css'

const CourierLogo = ({ apiV1, token, courier, setRefetch }) => {
    const [selected, setSelected] = useState(null)
    const inputRef = useRef()
    const [msg, setMsg] = useState('')
    const [submitStatus, setSubmitStatus] = useState(false)
    const handleOnChoose = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelected(e.target.files[0])
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        const filedata = new FormData()
        filedata.append('file', selected)
        const fetchdata = async () => {
            try {
                const response = await fetch(`${apiV1}/couriers/logo/${courier.id}`, {
                    method: 'PATCH',
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: filedata,
                })
                if (response.ok) {
                    setSubmitStatus(true)
                    setMsg('Logo Updated!')
                    setRefetch(true)
                    setTimeout(() => {
                        setSubmitStatus(false)
                    }, 2000)
                } else {
                    setMsg('Something went wrong!')
                    setSubmitStatus(true)
                }
            } catch (error) {
                setMsg('Something went wrong!')
                setSubmitStatus(true)
                setTimeout(() => {
                    setSubmitStatus(false)
                }, 2000)
            }
        }
        fetchdata()
    }
    return (
        <div className={classes.container}>
            <form onSubmit={handleSubmit}>
                <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px' }}>
                    <div className={classes.innerContainer}>
                        <div className={classes.fileField} onClick={() => inputRef.current.click()}>
                            <img src={ImgIcon} alt="logo" />
                            <input type="file" onChange={handleOnChoose} ref={inputRef} required accept="image/*" />
                            <div>
                                <p>
                                    Click to change Logo<span className={classes.star}>*</span>
                                </p>
                            </div>
                        </div>
                        {selected && (
                            <div className={classes.file}>
                                <img src={ImgIcon} alt="logo" />
                                <p>{selected.name}</p>
                            </div>
                        )}

                        {submitStatus && (
                            <div>
                                <span
                                    className={classes.submitMessage}
                                    style={{ color: `${msg?.substring(0, 2) === 'Lo' ? '#5cb484' : '#a10f0f'}` }}>
                                    {msg}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
                <div className={classes.submitContainer}>
                    <button type="submit">Submit</button>
                </div>
            </form>
        </div>
    )
}

export default CourierLogo

import React, { useEffect, useState } from 'react'
import TitleContainer from '../Resources/TitleContainer/TitleContainer'
import Courier from './Courier/Courier'
import classes from './CourierList.module.css'
import CourierTable from './CourierTable/CourierTable'

const CourierList = ({ couriers, fetch, refetch, token, apiV1, setRefetch, courierCount }) => {
    const [active, setActive] = useState('')
    const [selected, setSelected] = useState({ id: null, name: null })

    useEffect(() => {
        if (couriers?.length > 0) {
            setActive(couriers[0].name)
            setSelected((prev) => ({ ...prev, id: couriers[0].id, name: couriers[0].name }))
        }
    }, [couriers])
    return (
        <div className={classes.container}>
            <TitleContainer title={'Courier List'} isCount={true} count={courierCount} />
            <div className={classes.tabContainer}>
                <div className={classes.tabs}>
                    {couriers &&
                        couriers?.map((courier) => {
                            return (
                                <Courier
                                    courier={courier}
                                    key={courier.id}
                                    active={active}
                                    setActive={setActive}
                                    fetch={fetch}
                                    refetch={refetch}
                                    token={token}
                                    apiV1={apiV1}
                                    setRefetch={setRefetch}
                                    setSelected={setSelected}
                                />
                            )
                        })}
                </div>
            </div>
            {selected.id !== null && <CourierTable selected={selected} token={token} apiV1={apiV1} />}
        </div>
    )
}

export default CourierList

import axios from 'axios'
import { apiV1 } from '../api'

const axiosInstance = axios.create({
    baseURL: apiV1,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
    },
})

export default axiosInstance

import React from 'react'
import { FaFileAlt } from 'react-icons/fa'
import TitleContainer from '../Resources/TitleContainer/TitleContainer'
import classes from './RfqList.module.css'

export default function RfqList(props) {
    return (
        <div className={classes.wrapper}>
            <TitleContainer title={'My RFQ'} isCount={true} count={props.rfqCount} />
            <div></div>
            <div>
                {props.rfqCount === 0 ? (
                    <div className={classes.noRfq}>No RFQ has been posted yet.</div>
                ) : (
                    props.rfq &&
                    props.rfq?.map((data, id) => (
                        <div className={classes.rfq} key={id}>
                            <div className={classes.sl}>{id + 1}</div>
                            <div className={classes.gridWrap}>
                                <div className={classes.productImage}>
                                    {data.FileRfq?.bucket ? (
                                        data.FileRfq?.file_type === 'pdf' ? (
                                            <FaFileAlt className={classes.fileIcon} />
                                        ) : (
                                            <img src={data.FileRfq?.file_url} alt="" />
                                        )
                                    ) : (
                                        'No file uploaded'
                                    )}
                                </div>
                                <div>
                                    <div>
                                        Product Name: <span>{data.BuyerRfq.product_name}</span>
                                    </div>
                                    <div>
                                        Sourcing Quantity:{' '}
                                        <span>
                                            {data.BuyerRfq.sourcing_quantity} {data.BuyerRfq.measurement_unit}
                                        </span>
                                    </div>
                                    <div>
                                        Budget Range:{' '}
                                        <span>
                                            {data.BuyerRfq.currency === 'taka'
                                                ? '৳'
                                                : data.BuyerRfq.currency === 'euro'
                                                ? '€'
                                                : data.BuyerRfq.currency === 'dollar'
                                                ? '$'
                                                : ''}{' '}
                                            {data.BuyerRfq.budget_range}
                                        </span>
                                    </div>
                                    <div>
                                        Company Name: <span>{data.BuyerRfq.company}</span>
                                    </div>
                                    <div>
                                        Country:{' '}
                                        <span>
                                            {data.BuyerRfq.country_emoji} {data.BuyerRfq.country}
                                        </span>
                                    </div>
                                    <div>
                                        Buyer Name: <span>{data.User.name}</span>
                                    </div>
                                    <div>
                                        Phone:{' '}
                                        <span>{data.BuyerRfq.phone ? data.BuyerRfq.phone : data.User.phone}</span>
                                    </div>
                                    <div>
                                        Email:{' '}
                                        <span>{data.BuyerRfq.email ? data.BuyerRfq.email : data.User.email}</span>
                                    </div>
                                    <div>
                                        Delivery Address: <span>{data.BuyerRfq.delivery_address}</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                Requirements <span>{data.BuyerRfq.requirements}</span>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    )
}

import React, { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import ProductEdit from '../../../components/Product/edit/ProductEdit/ProductEdit'
import { getCategory, getSingleProductBySeller, getSubCategory, getSubSubCategory } from '../../../utils/functions'

const ProductEditPage = () => {
    const { productData, setProductData, singleProductData } = useOutletContext()
    const [allCategories, setAllCategories] = useState({
        categories: [],
        subCategories: [],
        subSubCategories: [],
    })

    // Fetch main categories
    useEffect(() => {
        const fetchData = async () => {
            try {
                const category = await getCategory()
                setAllCategories((prev) => ({
                    ...prev,
                    categories: category?.data || [],
                }))
            } catch (error) {
                console.error('Error fetching categories:', error)
            }
        }
        fetchData()
    }, [])
    // Fetch subcategories
    useEffect(() => {
        const categoryId = productData.generalInfo.selectedCategory ?? singleProductData?.Product?.category_id
        const fetchSubCategories = async () => {
            if (categoryId) {
                try {
                    const subCategory = await getSubCategory(categoryId)
                    setAllCategories((prev) => ({
                        ...prev,
                        subCategories: subCategory?.data || [],
                    }))
                } catch (error) {
                    console.error('Error fetching subcategories:', error)
                }
            }
        }
        fetchSubCategories()
    }, [singleProductData, productData])
    // Fetch sub-subcategories
    useEffect(() => {
        const subCategoryId = productData.generalInfo.selectedSubCategory ?? singleProductData?.Product?.sub_category_id
        const fetchSubSubCategories = async () => {
            if (subCategoryId) {
                try {
                    const subSubCategory = await getSubSubCategory(subCategoryId)
                    setAllCategories((prev) => ({
                        ...prev,
                        subSubCategories: subSubCategory?.data || [],
                    }))
                } catch (error) {
                    console.error('Error fetching sub-subcategories:', error)
                }
            }
        }
        fetchSubSubCategories()
    }, [singleProductData, productData])
    return (
        <ProductEdit
            productData={productData}
            setProductData={setProductData}
            singleProductData={singleProductData}
            allCategories={allCategories}
        />
    )
}
export default ProductEditPage

import React, { Fragment } from 'react'
import { svgs } from '../../../../utils/findSvgs/findSvgs'
import PricingTable from './PricingTable/PricingTable'
import classes from './ProductDelivery.module.css'

const ProductDelivery = ({ couriers, selectedCourierId, priceList, setProductData, productData }) => {
    const { generalInfo, deliveryInfo, error } = productData
    const { packageInfo, deliveryPartnerInfo } = deliveryInfo

    const handleInputChange = (event, type) => {
        const { name, value } = event.target
        if (type === 'package') {
            setProductData((prev) => ({
                ...prev,
                deliveryInfo: {
                    ...prev.deliveryInfo,
                    packageInfo: {
                        ...deliveryInfo.packageInfo,
                        [name]: Number(value),
                    },
                },
            }))
        } else if (type === 'delivery') {
            setProductData((prev) => ({
                ...prev,
                deliveryInfo: {
                    ...prev.deliveryInfo,
                    deliveryPartnerInfo: {
                        ...deliveryInfo.deliveryPartnerInfo,
                        [name]: Number(value),
                    },
                },
            }))
        }
    }

    // dt means delivery time...
    return (
        <Fragment>
            <div className={classes.formBox}>
                <div className={`${classes.title} ${classes.borderBottom}`}>
                    <div className={classes.spreadTitle}>
                        <div>{svgs.measurement} Package Measurement</div>
                    </div>
                </div>
                <div>
                    <div className={classes.formLayout}>
                        <div className={classes.formGroup}>
                            <label>
                                Weight <sup>*</sup>
                            </label>
                            <div className={`${classes.quantity} ${classes.amount}`}>
                                <input
                                    type="number"
                                    placeholder="00.00"
                                    name="weight"
                                    value={packageInfo?.weight}
                                    onChange={(e) => handleInputChange(e, 'package')}
                                />

                                <div>Kilogram</div>
                            </div>
                            {error?.deliveryInfo && !packageInfo?.weight && (
                                <p className={classes.warning}>{svgs.warning}Weight is required</p>
                            )}
                        </div>

                        <div className={classes.formGroup}>
                            <label>Unit</label>
                            <div className={`${classes.quantity} ${classes.amount}`}>
                                <input
                                    type="string"
                                    placeholder={generalInfo?.unit}
                                    name="unit"
                                    onChange={(e) => handleInputChange(e, 'package')}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classes.formLayout}>
                        <div className={classes.formGroup}>
                            <label>Height</label>
                            <div className={`${classes.quantity} ${classes.amount}`}>
                                <input
                                    type="number"
                                    placeholder="00.00"
                                    name="height"
                                    value={packageInfo?.height}
                                    onChange={(e) => handleInputChange(e, 'package')}
                                />
                                <div>Inch</div>
                            </div>
                        </div>
                        <div className={classes.formGroup}>
                            <label>Width</label>
                            <div className={`${classes.quantity} ${classes.amount}`}>
                                <input
                                    type="number"
                                    placeholder="00.00"
                                    name="width"
                                    value={packageInfo?.width}
                                    onChange={(e) => handleInputChange(e, 'package')}
                                />
                                <div>Inch</div>
                            </div>
                        </div>
                        <div className={classes.formGroup}>
                            <label>Length</label>
                            <div className={`${classes.quantity} ${classes.amount}`}>
                                <input
                                    type="number"
                                    placeholder="00.00"
                                    name="length"
                                    value={packageInfo?.length}
                                    onChange={(e) => handleInputChange(e, 'package')}
                                />
                                <div>Inch</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.formBox}>
                <div className={`${classes.title}`}>
                    Choose your preferred delivery partner <sup>*</sup>
                </div>
                <div className={classes.couriers}>
                    <div className={classes.formLayout}>
                        {couriers.map((courier) => {
                            const isActive = selectedCourierId == courier.id
                            return (
                                <div
                                    className={`${classes.partnerBox} ${isActive ? classes.selected : ''}`}
                                    onClick={() =>
                                        setProductData((prev) => {
                                            return {
                                                ...prev,
                                                deliveryInfo: {
                                                    ...prev.deliveryInfo,
                                                    deliveryPartnerInfo: {
                                                        ...prev.deliveryInfo.deliveryPartnerInfo,
                                                        courierId: courier.id,
                                                    },
                                                },
                                            }
                                        })
                                    }>
                                    <div>
                                        <div>
                                            <div className={classes.radioBtn}>
                                                <div className={`${isActive ? classes.active : ''}`}>
                                                    <div></div>
                                                </div>
                                            </div>
                                            <h4>{courier.name}</h4>
                                        </div>
                                    </div>
                                    <div>
                                        <img
                                            src={courier.logo_url}
                                            alt={`${courier.name} logo`}
                                            className={classes.logo}
                                        />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className={classes.formBox}>
                <div className={`${classes.title} ${classes.borderBottom}`}>
                    Retail Delivery Details <sup>*</sup>
                </div>
                <div className={classes.formLayout}>
                    <div className={classes.formGroup}>
                        <label>Delivery Time</label>
                        <div className={`${classes.quantity} ${classes.amount}`}>
                            <input
                                type="number"
                                placeholder="00.00"
                                name="dt"
                                value={deliveryPartnerInfo?.dt}
                                onChange={(e) => handleInputChange(e, 'delivery')}
                            />
                            <div>Day</div>
                        </div>
                    </div>
                    <div className={classes.formGroup}>
                        <label>
                            Cash on Delivery Fee (%) <sup>*</sup>
                        </label>
                        <div
                            className={`${
                                error?.deliveryInfo && !deliveryPartnerInfo?.cod
                                    ? `${classes.quantity} ${classes.amount} ${classes.wrongInput}`
                                    : `${classes.quantity} ${classes.amount}`
                            }`}>
                            <input
                                type="number"
                                placeholder="00.00"
                                name="cod"
                                onChange={(e) => handleInputChange(e, 'delivery')}
                                value={deliveryPartnerInfo?.cod}
                                min={1}
                            />
                            <div>{svgs.percentage}</div>
                        </div>
                        {error?.deliveryInfo && !deliveryPartnerInfo?.cod && (
                            <p className={classes.warning}>{svgs.warning}Cash On Delivery fee is required</p>
                        )}
                    </div>
                    <div className={classes.formGroup}>
                        <label>Minimum order amount required for Free Delivery</label>
                        <div className={`${classes.quantity} ${classes.amount}`}>
                            <input
                                type="number"
                                placeholder="00.00"
                                name="fd"
                                onChange={(e) => handleInputChange(e, 'delivery')}
                                value={deliveryPartnerInfo?.fd}
                                min={1}
                            />
                            <div>{svgs.taka}</div>
                        </div>
                    </div>
                </div>
                <PricingTable priceList={priceList} couriers={couriers} selectedCourierId={selectedCourierId} />
            </div>
        </Fragment>
    )
}

export default ProductDelivery

import React, { useEffect, useRef, useState } from 'react'
import { svgs } from '../../../../utils/findSvgs/findSvgs'
import { productType, productUnits, sellType } from '../../../../utils/static/static'
import RenderDropDown from '../../../Resources/CustomDropDown/RenderDropdown/RenderDropDown'
import TextEditor from '../../../TextEditor/TextEditor'
import ImagePreview from '../../shared/ImagePreview/ImagePreview'
import ProductAddTags from '../../shared/ProductAddTags'
import ProductQuantity from '../../shared/ProductQuantity'
import classes from './PorductEdit.module.css'

const ProductEdit = ({ productData, setProductData, singleProductData, allCategories }) => {
    const { categories, subCategories, subSubCategories } = allCategories
    const { generalInfo } = productData
    const [sellTypeIds, setSellTypeIds] = useState([])

    const handleSellType = (id) => {
        setSellTypeIds((prev) => {
            const updatedSellTypeIds = prev.includes(id) ? prev.filter((data) => data !== id) : [...prev, id]

            // Update productData when sellTypeIds change
            setProductData((prevData) => ({
                ...prevData,
                generalInfo: {
                    ...prevData.generalInfo,
                    isB2c: updatedSellTypeIds.includes(1),
                    isB2b: updatedSellTypeIds.includes(2),
                },
            }))

            return updatedSellTypeIds
        })
    }

    useEffect(() => {
        // Initialize sellTypeIds based on productData's generalInfo
        const initialSellTypes = [...(generalInfo?.isB2c ? [1] : []), ...(generalInfo?.isB2b ? [2] : [])]
        setSellTypeIds(initialSellTypes.length ? initialSellTypes : [1])
    }, [generalInfo])

    return (
        <form onSubmit={(e) => e.preventDefault()}>
            {/* checkbox */}
            <div className={classes.formBox}>
                <div className={classes.title}>How would you like to make your sales? *</div>
                <div className={classes.checkboxes}>
                    {sellType.map((sell) => {
                        const isActive = sellTypeIds.includes(sell.id)
                        return (
                            <div key={sell.id} onClick={() => handleSellType(sell.id)}>
                                <div className={`${isActive ? classes.active : ''}`}>{svgs.selectIcon}</div>
                                <label>{sell.name}</label>
                            </div>
                        )
                    })}
                </div>
            </div>

            {/*  main form */}
            <div className={classes.formBox}>
                <div className={`${classes.title} ${classes.borderBottom}`}>
                    <div>{svgs.products} Product Details</div>
                </div>
                {/* <div className={classes.formLayout}> */}
                <div className={classes.formGroup}>
                    <label>
                        Product Title <sup>*</sup>
                    </label>
                    <input
                        placeholder="Write Down Product Name"
                        value={generalInfo?.title}
                        onChange={(e) =>
                            setProductData((prev) => ({
                                ...prev,
                                generalInfo: {
                                    ...prev.generalInfo,
                                    title: e.target.value,
                                },
                            }))
                        }
                    />
                </div>
                {/* </div> */}

                <div className={classes.formLayout}>
                    <div className={classes.formGroup}>
                        <RenderDropDown
                            label={'Product Category'}
                            data={categories}
                            placeholder={'Select Product Category'}
                            dropdownLabel={'selectedCategory'}
                            setProductData={setProductData}
                            defaultValue={generalInfo?.selectedCategory ?? generalInfo?.category}
                        />
                    </div>
                    <div className={classes.formGroup}>
                        <RenderDropDown
                            label={'Product Sub Category'}
                            data={subCategories}
                            placeholder={'Select Product Sub Category'}
                            dropdownLabel={'selectedSubCategory'}
                            setProductData={setProductData}
                            customNoOptionsMessage={'Select Product Category First'}
                            defaultValue={generalInfo?.selectedSubCategory ?? generalInfo?.subCategory}
                        />
                    </div>
                    <div className={classes.formGroup}>
                        <RenderDropDown
                            label={'Product Sub SubCategory'}
                            data={subSubCategories}
                            placeholder={'Select Product Sub Category'}
                            dropdownLabel={'selectedSubSubCategory'}
                            setProductData={setProductData}
                            customNoOptionsMessage={'Select Product Sub Category First'}
                            defaultValue={generalInfo?.selectedSubSubCategory ?? generalInfo?.subSubCategory}
                        />
                    </div>
                    <div className={classes.formGroup}>
                        <RenderDropDown
                            label={'Product Type'}
                            data={productType}
                            placeholder={'Select Product Type'}
                            dropdownLabel={'type'}
                            defaultValue={generalInfo?.type}
                            setProductData={setProductData}
                        />
                    </div>
                    <div className={classes.formGroup}>
                        <RenderDropDown
                            label={'Unit'}
                            data={productUnits}
                            placeholder={'Select Unit'}
                            dropdownLabel={'unit'}
                            defaultValue={generalInfo?.unit}
                            setProductData={setProductData}
                            isDisabled={true}
                        />
                    </div>
                    {generalInfo?.isB2b && (
                        <div className={classes.formGroup}>
                            <label>
                                Minimum Order Quantity (MOQ) <sup>*</sup>
                            </label>
                            <ProductQuantity setProductData={setProductData} productData={productData} label="moq" />
                        </div>
                    )}
                </div>

                <div>
                    <ProductAddTags
                        productData={productData}
                        setProductData={setProductData}
                        singleProductData={singleProductData}
                    />
                </div>
            </div>

            {/* product image uplaod */}
            <div className={classes.formBox}>
                <div className={classes.title}>
                    <div>{svgs.imageIcon} Upload Product Images</div>
                    <p>
                        Upload upto 5 images, maximum size 5MB. It is suggested to add a “Size Chart” image (if needed)
                        for a better shopping experience.
                    </p>
                </div>
                <ImagePreview
                    productData={productData}
                    setProductData={setProductData}
                    singleProductData={singleProductData}
                />
            </div>

            {/* text editor */}
            <div className={classes.formBox}>
                <div className={classes.title}>
                    <div>{svgs.descriptionIcon} Description</div>
                </div>
                <TextEditor
                    setProductData={setProductData}
                    productData={productData}
                    singleProductData={singleProductData}
                />
            </div>
        </form>
    )
}

export default ProductEdit

import React from 'react'
import { useState, useEffect } from 'react'
import { Buyers } from '../components'
import { apiV1 } from '../utils/api'

export default function BuyerListPage() {
    const [buyers, setBuyers] = useState([])
    const [pageSkip, setPageSkip] = useState(0)
    const [pageAll, setPageAll] = useState(0)
    const [check, setCheck] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiV1}/buyers/?skip=${pageSkip}&limit=10`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })

                const data = await response.json()
                if (response.ok) {
                    setBuyers(data[1])
                    setPageAll(data[0].results)
                    setCheck(false)
                }
            } catch {
                setBuyers([])
            }
        }
        fetchData()
    }, [pageSkip, check])
    return (
        <Buyers
            buyers={buyers}
            pageAll={pageAll}
            pageSkip={pageSkip}
            setPageSkip={setPageSkip}
            check={check}
            setCheck={setCheck}
        />
    )
}

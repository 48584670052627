import React, { useState } from 'react'
import { IoClose } from 'react-icons/io5'
import PopupV1 from '../../Resources/PopupV1/PopupV1'
import classes from './UpdatePopup.module.css'

const UpdatePopup = ({ setForm, form, variants, setRefetch, apiV1, token }) => {
    const [msg, setMsg] = useState('')
    const [name, setName] = useState(variants?.name)
    const [submitStatus, setSubmitStatus] = useState(false)
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const response = await fetch(`${apiV1}/variants/${variants?.id}`, {
                headers: {
                    Accept: 'appllication/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'PATCH',
                body: JSON.stringify({
                    name: name,
                }),
            })

            if (response.ok) {
                setSubmitStatus(true)
                setMsg('Variant Updated!')
                setRefetch(true)
                setTimeout(() => {
                    setSubmitStatus(false)
                }, 2000)
            } else {
                setMsg('Something went wrong!')
                setSubmitStatus(true)
            }
        } catch (error) {
            setMsg('Something went wrong!')
            setSubmitStatus(true)
            setTimeout(() => {
                setSubmitStatus(false)
            }, 2000)
        }
    }
    return (
        <div className={classes.container}>
            <PopupV1 setForm={setForm} form={form} width="50%">
                <div className={classes.close}>
                    <div className={classes.header}>
                        <p>Update Variant</p>
                    </div>
                    <button onClick={() => setForm(!form)}>
                        <IoClose size={20} />
                    </button>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className={classes.innerContainer}>
                        <p>Variant Name</p>

                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />

                        {submitStatus && (
                            <div>
                                <span
                                    className={classes.submitMessage}
                                    style={{ color: `${msg?.substring(0, 2) === 'Va' ? '#5cb484' : '#a10f0f'}` }}>
                                    {msg}
                                </span>
                            </div>
                        )}
                    </div>
                    <div className={classes.submitContainer}>
                        <button type="submit">Submit</button>
                    </div>
                </form>
            </PopupV1>
        </div>
    )
}

export default UpdatePopup

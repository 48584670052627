import React, { Fragment, useEffect, useState } from 'react'
import { svgs } from '../../../../../utils/findSvgs/findSvgs'
import classes from './ProductVariantTable.module.css'

const ProductVariantTable = ({ productData, setProductData }) => {
    const { variantInfo, error, generalInfo } = productData
    const { price, discount, details } = productData?.variantInfo.quantityAndPrice
    const [attributes, setAttributes] = useState([])
    const [variants, setVariants] = useState([])

    const [firstVariantName, secondVariantName] = variantInfo?.selectedVariants?.map((variant) => variant.variantName)
    const [errorMessages, setErrorMessages] = useState([])
    // Helper function to calculate the discounted price
    const calculateDiscountedPrice = (price, discountPercentage) => {
        return Math.round(price - price * (discountPercentage / 100))
    }

    const getAtributeRowData = (attribute) => {
        const attributesArray = attribute.includes('|')
            ? attribute.split('|').map((attr) => attr.trim())
            : [attribute.trim()]
        const [firstAttribute, secondAttribute] = attributesArray
        return {
            type: firstVariantName,
            value: firstAttribute,
            type2: secondAttribute ? secondVariantName : '',
            value2: secondAttribute || '',
            id: `${firstAttribute}${secondAttribute || ''}`.trim(),
        }
    }

    // Refactor validation to handle errors separately
    const handleValidation = (variants) => {
        const errors = []
        variants.forEach((variant, index) => {
            const { quantity, cop, b2bp, b2cp, b2bdPrice, b2cdPrice, b2cdPercentage, b2bdPercentage } = variant
            const variantErrors = {}

            // Quantity check
            if (!quantity || quantity <= 0) {
                variantErrors.quantity = true
            }
            const isB2CPrice = b2cdPercentage ? b2cdPrice : b2cp
            const isB2BPrice = b2bdPercentage ? b2bdPrice : b2bp

            // Price check for b2bp
            if (!isB2BPrice && generalInfo.isB2b) {
                variantErrors.b2bp = true
            } else if (isB2BPrice <= cop && generalInfo.isB2b) {
                variantErrors.b2bp = true
            }

            // Price check for b2cp
            if (!isB2CPrice && generalInfo.isB2c) {
                variantErrors.b2cp = true
            } else if (isB2CPrice <= cop && generalInfo.isB2c) {
                variantErrors.b2cp = true
            }

            // If there are any errors, push them
            if (Object.keys(variantErrors).length > 0) {
                errors.push({ ...variantErrors, errorIndex: index })
            }
        })

        return errors
    }

    const handleProductPrice = (e, index) => {
        const { name, value } = e.target
        const numericValue = Number(value)

        setVariants((prevVariants) =>
            prevVariants.map((variant, idx) =>
                idx === index
                    ? {
                          ...variant,
                          [name]: numericValue,
                          b2cdPrice:
                              name === 'b2cp' || name === 'b2cdPercentage'
                                  ? calculateDiscountedPrice(
                                        name === 'b2cp' ? numericValue : variant.b2cp,
                                        name === 'b2cdPercentage' ? numericValue : variant.b2cdPercentage
                                    )
                                  : variant.b2cdPrice,
                          b2bdPrice:
                              name === 'b2bp' || name === 'b2bdPercentage'
                                  ? calculateDiscountedPrice(
                                        name === 'b2bp' ? numericValue : variant.b2bp,
                                        name === 'b2bdPercentage' ? numericValue : variant.b2bdPercentage
                                    )
                                  : variant.b2bdPrice,
                      }
                    : variant
            )
        )
    }

    useEffect(() => {
        const [firstVariantAttributes, secondVariantAttributes] = variantInfo.selectedVariants?.map(
            (variant) => variant.attributes
        )
        if (variantInfo.selectedVariants.length > 1) {
            const multipliedAttributes = firstVariantAttributes
                .map((attribute1) => {
                    return secondVariantAttributes.map((attribute2) => `${attribute1} | ${attribute2}`)
                })
                .flat()
            setAttributes(multipliedAttributes)
        } else {
            setAttributes(firstVariantAttributes)
        }
    }, [variantInfo.selectedVariants, generalInfo])

    useEffect(() => {
        if (variants.length > 0) {
            const errors = handleValidation(variants)
            setErrorMessages(errors)

            if (variantInfo.separetePrice) {
                setProductData((prev) => ({
                    ...prev,
                    variantInfo: { ...prev.variantInfo, separetePriceVariants: variants, errorMessages: errors },
                }))
            } else {
                setProductData((prev) => ({
                    ...prev,
                    variantInfo: { ...prev.variantInfo, variants, errorMessages: errors },
                }))
            }
        }
    }, [variants])

    useEffect(() => {
        if (!variantInfo.separetePrice) {
            const newVariants = attributes?.map((attribute) => {
                const attributesObjectsData = getAtributeRowData(attribute)
                return {
                    ...attributesObjectsData,
                    quantity: 0,
                    cop: details.cop,
                    b2cp: price.b2cp,
                    b2cdPercentage: price.b2cd,
                    b2cdPrice: calculateDiscountedPrice(price.b2cp, price.b2cd),
                    b2bp: price.b2bp,
                    b2bdPercentage: price.b2bd,
                    b2bdPrice: calculateDiscountedPrice(price.b2bp, price.b2bd),
                }
            })

            const updatedVariants = newVariants?.map((variant) => {
                const checkedVariant = variantInfo.variants.find((prevVariant) => prevVariant.id === variant.id)
                return checkedVariant ? checkedVariant : variant
            })

            setVariants(updatedVariants)
        } else if (variantInfo.separetePrice) {
            const newVariants = attributes?.map((attribute) => {
                const attributesObjectsData = getAtributeRowData(attribute)
                return {
                    ...attributesObjectsData,
                    quantity: 0,
                    cop: 0,
                    b2cp: 0,
                    b2cdPercentage: 0,
                    b2cdPrice: 0,
                    b2bp: 0,
                    b2bdPercentage: 0,
                    b2bdPrice: 0,
                }
            })
            const updatedVariants = newVariants?.map((variant) => {
                const checkedVariant = variantInfo.separetePriceVariants.find(
                    (prevVariant) => prevVariant.id === variant.id
                )
                return checkedVariant ? checkedVariant : variant
            })
            setVariants(updatedVariants)
        }
    }, [attributes, firstVariantName, secondVariantName, variantInfo.separetePrice, variantInfo.quantityAndPrice])

    return (
        <Fragment>
            {variants?.length > 0 && (
                <Fragment>
                    <div className={classes.productTable}>
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        Variant
                                        <span>
                                            {firstVariantName} {secondVariantName && ' | ' + secondVariantName}
                                        </span>
                                    </th>
                                    <th>Cost of Product</th>

                                    {generalInfo.isB2c && price?.b2cp && (
                                        <Fragment>
                                            <th>Retail Price</th>
                                            <th>Discount (%) Retail Price</th>
                                            <th>Retail Price After Discount</th>
                                        </Fragment>
                                    )}
                                    {generalInfo.isB2b && price?.b2bp && (
                                        <Fragment>
                                            <th>Wholesale Price</th>
                                            <th>Discount (%) Wholesale Price</th>
                                            <th>Wholesale Price After Discount</th>
                                        </Fragment>
                                    )}
                                    <th>Product Stock</th>
                                </tr>
                            </thead>
                            <tbody>
                                <button className={classes.displayVariantButton}>Display Variant</button>
                                {variants?.map((variant, index) => {
                                    const isDisabled = !variantInfo.separetePrice
                                    const validationErrors = errorMessages?.find((error) => error.errorIndex == index)

                                    const {
                                        type,
                                        value,
                                        type2,
                                        value2,
                                        cop,
                                        b2cp,
                                        b2cdPercentage,
                                        b2cdPrice,
                                        b2bp,
                                        b2bdPercentage,
                                        b2bdPrice,
                                        quantity,
                                    } = variant
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {value} {type2 && ` | ${value2}`}
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    className={`${
                                                        error?.variantInfo && !variant.cop
                                                            ? classes.wrongInput
                                                            : classes.input
                                                    }`}
                                                    value={cop}
                                                    name="cop"
                                                    onChange={(e) => handleProductPrice(e, index)}
                                                    disabled={isDisabled}
                                                />
                                            </td>
                                            {generalInfo.isB2c && price?.b2cp && (
                                                <>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            className={`${
                                                                error?.variantInfo && validationErrors?.b2cp
                                                                    ? classes.wrongInput
                                                                    : classes.input
                                                            }`}
                                                            value={b2cp}
                                                            name="b2cp"
                                                            onChange={(e) => handleProductPrice(e, index)}
                                                            disabled={isDisabled}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            className={classes.input}
                                                            value={b2cdPercentage}
                                                            name="b2cdPercentage"
                                                            onChange={(e) => handleProductPrice(e, index)}
                                                            disabled={isDisabled}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            className={`${
                                                                error?.variantInfo && validationErrors?.b2cp
                                                                    ? classes.wrongInput
                                                                    : classes.input
                                                            }`}
                                                            value={b2cdPrice}
                                                            name="b2cdPrice"
                                                            onChange={(e) => handleProductPrice(e, index)}
                                                            disabled
                                                        />
                                                    </td>
                                                </>
                                            )}
                                            {generalInfo.isB2b && price?.b2bp && (
                                                <>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            className={`${
                                                                error?.variantInfo && validationErrors?.b2bp
                                                                    ? classes.wrongInput
                                                                    : classes.input
                                                            }`}
                                                            value={b2bp}
                                                            name="b2bp"
                                                            onChange={(e) => handleProductPrice(e, index)}
                                                            disabled={isDisabled}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            className={classes.input}
                                                            value={b2bdPercentage}
                                                            name="b2bdPercentage"
                                                            onChange={(e) => handleProductPrice(e, index)}
                                                            disabled={isDisabled}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            className={`${
                                                                error?.variantInfo && validationErrors?.b2bp
                                                                    ? classes.wrongInput
                                                                    : classes.input
                                                            }`}
                                                            value={b2bdPrice}
                                                            name="b2bdPrice"
                                                            onChange={(e) => handleProductPrice(e, index)}
                                                            disabled
                                                        />
                                                    </td>
                                                </>
                                            )}
                                            <td>
                                                <input
                                                    type="number"
                                                    className={`${
                                                        error?.variantInfo && validationErrors?.quantity
                                                            ? classes.wrongInput
                                                            : classes.input
                                                    }`}
                                                    value={quantity}
                                                    name="quantity"
                                                    onChange={(e) => handleProductPrice(e, index)}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                    {variants.reduce((total, variant) => total + variant.quantity, 0) > 0 && (
                        <div className={classes.footer}>
                            <p className="total">
                                Total Product Stock :
                                <span> {variants.reduce((total, variant) => total + variant.quantity, 0)}</span>
                            </p>
                        </div>
                    )}
                </Fragment>
            )}
        </Fragment>
    )
}

export default ProductVariantTable

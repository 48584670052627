import React from 'react'
import { svgs } from '../../../utils/findSvgs/findSvgs'
import classes from './resource/Buttons.module.css'

const PrimaryBtn = ({ label, iconPosition }) => {
    return (
        <button className={classes.primaryBtn}>
            {iconPosition === 'left' && svgs.btnPlusIcon}
            {label}
            {iconPosition === 'right' && svgs.btnRightArrow}
        </button>
    )
}

export default PrimaryBtn

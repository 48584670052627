import React, { useContext, useState } from 'react'
import { IoClose } from 'react-icons/io5'
import { toast } from 'react-toastify'
import { Auth } from '../../../../../contexts/allContext'
import { apiV1, apiV2 } from '../../../../../utils/api'
import PopupV1 from '../../../../Resources/PopupV1/PopupV1'
import classes from './Popup.module.css'

export default function Popup({ setForm, form, productId, singleProduct, setProductActive, productActive }) {
    const { stateAuth } = useContext(Auth)
    const token = stateAuth?.token

    const toastSuccess = () => {
        toast.dismiss()
        toast.success('Product Status updated!', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
        })
    }

    const toastSuccessDisabled = () => {
        toast.dismiss()
        toast.success('Product Delete Successfully', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
        })
    }

    const handleStatusChange = async (status) => {
        try {
            const response = await fetch(`${apiV2}/products/product/${productId}`, {
                method: 'PATCH',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ is_active: status }),
            })

            if (response.ok) {
                console.log('Success: Product status updated')
                setProductActive(!productActive)
                toastSuccess()
            } else {
                console.log('Error: Something went wrong!')
            }
        } catch (error) {
            console.error('Request failed:', error)
        }
    }

    const handleStatusDisabled = async (status) => {
        try {
            const response = await fetch(`${apiV1}/admin/product/disable/${productId}`, {
                method: 'PATCH',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })

            if (response.ok) {
                console.log('Success: Product status updated')
                setProductActive(!productActive)
                toastSuccessDisabled()
            } else {
                console.log('Error: Something went wrong!')
            }
        } catch (error) {
            console.error('Request failed:', error)
        }
    }

    return (
        <div className={classes.container}>
            <PopupV1 setForm={setForm} form={form} width="30%">
                <div className={classes.close}>
                    <div className={classes.closeIcon}>
                        <p onClick={() => setForm(!form)}>
                            <IoClose size={20} />
                        </p>
                    </div>
                    <div className={classes.header}>
                        <p>Update Product Status</p>
                    </div>
                </div>
                {/* <div className={classes.btns}>
                    <button
                        onClick={() => {
                            handleStatusChange(true)
                        }}
                        className={
                            singleProduct?.Product?.is_active == true ? classes.activeBtnTrue : classes.activeBtnFalse
                        }>
                        Active
                    </button>
                    <button
                        onClick={() => {
                            handleStatusChange(false)
                        }}
                        className={
                            singleProduct?.Product?.is_active == false
                                ? classes.inactiveBtnTrue
                                : classes.inactiveBtnFalse
                        }>
                        Inactive
                    </button>
                </div> */}
                <div className={classes.btns}>
                    <button
                        onClick={() => {
                            handleStatusChange(true)
                        }}
                        className={classes.activebtn}>
                        Active
                    </button>
                    <button
                        onClick={() => {
                            handleStatusDisabled()
                        }}
                        className={classes.cancelbtn}>
                        Delete
                    </button>
                </div>
            </PopupV1>
        </div>
    )
}

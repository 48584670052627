import React, { Fragment, useEffect, useState } from 'react'
import { svgs } from '../../../../utils/findSvgs/findSvgs'
import ProductQuantity from '../../shared/ProductQuantity'
import classes from './PorductVariant.module.css'

const SingleVariant = ({ setProductData, productData }) => {
    const [isFormCollapsed, setFormCollapsed] = useState(false)
    const { variantInfo, generalInfo } = productData
    const { price, discount, details } = variantInfo?.quantityAndPrice || {}

    useEffect(() => {
        setFormCollapsed(variantInfo.isMultiVariant)
    }, [variantInfo.isMultiVariant])

    const handleProductPrice = (e) => {
        const { name, value } = e.target
        setProductData((prev) => {
            const updatedQuantityAndPrice = { ...prev.variantInfo?.quantityAndPrice }
            if (name === 'cop') {
                updatedQuantityAndPrice.details = {
                    ...updatedQuantityAndPrice.details,
                    [name]: Number(value),
                }
            } else {
                updatedQuantityAndPrice.price = {
                    ...updatedQuantityAndPrice.price,
                    [name]: Number(value),
                }
            }
            return {
                ...prev,
                variantInfo: {
                    ...prev.variantInfo,
                    quantityAndPrice: updatedQuantityAndPrice,
                    variants: [],
                },
            }
        })
    }

    // this is for update discount amount from price and discount percentage
    useEffect(() => {
        const calculateDiscountPrice = (price, discount) => Math.round(price - (price * discount) / 100)

        const updatedDiscount = {}
        if (price?.b2cp) {
            updatedDiscount.b2cdp = calculateDiscountPrice(price.b2cp, price.b2cd)
        }
        if (price?.b2bp) {
            updatedDiscount.b2bdp = calculateDiscountPrice(price.b2bp, price.b2bd)
        }

        setProductData((prev) => ({
            ...prev,
            variantInfo: {
                ...prev.variantInfo,
                quantityAndPrice: {
                    ...prev.variantInfo?.quantityAndPrice,
                    discount: { ...prev.variantInfo?.quantityAndPrice?.discount, ...updatedDiscount },
                },
            },
        }))
    }, [price, details])

    // this is for update profit amount from price and cost of product
    useEffect(() => {
        const calculateProfitMargin = (price, cop) => Math.round(price - cop)

        const updatedProfit = {}
        if (details.cop && (discount.b2cdp || price.b2cp)) {
            updatedProfit.b2cpm = calculateProfitMargin(discount.b2cdp ? discount.b2cdp : price.b2cp, details.cop)
        }
        if (details.cop && (discount.b2bdp || price.b2bp)) {
            updatedProfit.b2bpm = calculateProfitMargin(discount.b2bdp ? discount.b2bdp : price.b2bp, details.cop)
        }

        setProductData((prev) => ({
            ...prev,
            variantInfo: {
                ...prev.variantInfo,
                quantityAndPrice: {
                    ...prev.variantInfo?.quantityAndPrice,
                    profit: { ...prev.variantInfo?.quantityAndPrice?.profit, ...updatedProfit },
                },
            },
        }))
    }, [price, discount, details])

    return (
        <div className={classes.formBox}>
            <div className={`${classes.title} ${isFormCollapsed ? '' : classes.borderBottom}`}>
                <div className={classes.spreadTitle}>
                    <div>{svgs.price} Pricing Details</div>
                    <div>
                        {price.b2bp || price.b2cp ? (
                            <div className={classes.action} onClick={() => setFormCollapsed(!isFormCollapsed)}>
                                <div className={classes.icon}>{svgs.downArrow}</div>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
            <div
                className={`${
                    (price.b2bp || price.b2cp) && !isFormCollapsed
                        ? `${classes.open} ${classes.largeOpen}`
                        : !isFormCollapsed
                        ? classes.open
                        : classes.hide
                }`}>
                <div className={classes.formLayout}>
                    <div className={classes.formGroup}>
                        <label>
                            Product Stock <sup>*</sup>
                        </label>
                        <ProductQuantity setProductData={setProductData} productData={productData} label="quantity" />
                    </div>
                    <div className={classes.formGroup}>
                        <label>
                            Cost of Product <sup>*</sup>
                        </label>
                        <div className={`${classes.quantity} ${classes.amount}`}>
                            <input
                                type="number"
                                placeholder="00.00"
                                onChange={handleProductPrice}
                                name="cop"
                                value={details?.cop || ''}
                            />
                            <div>{svgs.taka}</div>
                        </div>
                    </div>
                </div>
                {/* Pricing Section */}
                <div className={classes.formLayout}>
                    {generalInfo.isB2c && (
                        <PriceSection
                            label="Retail Price"
                            name="b2cp"
                            price={price?.b2cp}
                            handleProductPrice={handleProductPrice}
                            discountName="b2cd"
                            discount={price?.b2cd}
                            finalPrice={price?.b2cd && discount?.b2cdp}
                            svgs={svgs}
                        />
                    )}
                    {generalInfo.isB2b && (
                        <PriceSection
                            label="Wholesale Price"
                            name="b2bp"
                            price={price?.b2bp}
                            handleProductPrice={handleProductPrice}
                            discountName="b2bd"
                            discount={price?.b2bd}
                            finalPrice={price?.b2bd && discount?.b2bdp}
                            svgs={svgs}
                        />
                    )}
                </div>
                {(generalInfo.isB2b && variantInfo?.quantityAndPrice.price.b2bp) ||
                (generalInfo.isB2c && variantInfo?.quantityAndPrice.price.b2cp)
                    ? renderProfitMargin(productData)
                    : undefined}
            </div>
        </div>
    )
}

const PriceSection = ({ label, name, price, handleProductPrice, discountName, discount, finalPrice, svgs }) => (
    <Fragment>
        <div className={classes.formGroup}>
            <label>
                {label} <sup>*</sup>
            </label>
            <div className={`${classes.quantity} ${classes.amount}`}>
                <input
                    type="number"
                    placeholder="00.00"
                    name={name}
                    value={price || ''}
                    onChange={handleProductPrice}
                />
                <div>{svgs.taka}</div>
            </div>
        </div>
        <div className={classes.formGroup}>
            <label>
                Discount for {label} <sup>*</sup>
            </label>
            <div className={`${classes.quantity} ${classes.amount}`}>
                <input
                    type="number"
                    placeholder="00.00"
                    name={discountName}
                    value={discount || ''}
                    onChange={handleProductPrice}
                />
                <div>{svgs.percentage}</div>
            </div>
        </div>
        <div className={classes.formGroup}>
            <label>
                {label} after Discount <sup>*</sup>
            </label>
            <div className={`${classes.quantity} ${classes.amount}`}>
                <input type="number" placeholder="00.00" disabled value={finalPrice || ''} />
                <div>{svgs.taka}</div>
            </div>
        </div>
    </Fragment>
)

const renderProfitMargin = (productData) => {
    const { variantInfo, generalInfo } = productData
    const { price, profit } = variantInfo?.quantityAndPrice || {}
    const isB2BNegativeMargin = generalInfo.isB2b && price.b2bp && profit.b2bpm <= 0
    const isB2CNegativeMargin = generalInfo.isB2c && price.b2cp && profit.b2cpm <= 0
    return (
        <div className={classes.marginCalculation}>
            <div className={`${classes.profit} ${variantInfo.isMultiVariant ? classes.profitLayout2 : ''}`}>
                {generalInfo.isB2c && price.b2cp > 0 && (
                    <div>
                        Retail Margin & Profit: <span>{profit?.b2cpm}৳</span>
                    </div>
                )}
                {generalInfo.isB2b && price.b2bp > 0 && (
                    <div>
                        Wholesale Margin & Profit: <span>{profit?.b2bpm}৳</span>
                    </div>
                )}
            </div>
            <div>
                {isB2BNegativeMargin || isB2CNegativeMargin ? (
                    <p className={classes.warning}>{svgs.warning} Negative margin! Update prices to avoid loss.</p>
                ) : (
                    ''
                )}
            </div>
        </div>
    )
}

export default SingleVariant

import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import ProductVariant from '../../../components/Product/edit/ProductVariant/ProductVariant'
import { getVariants } from '../../../utils/functions'

const ProductVariantPage = () => {
    const { productData, setProductData, singleProductData } = useOutletContext()
    const [originalVariants, setOriginalVariants] = useState([])
    const [filteredVariants, setFilteredVariants] = useState([])

    // Fetch all variants when the component mounts
    useEffect(() => {
        const fetchData = async () => {
            try {
                const fetchedVariants = await getVariants()
                setOriginalVariants(fetchedVariants)
                setFilteredVariants(fetchedVariants)
            } catch (error) {
                console.error('Error fetching variants:', error)
            }
        }

        fetchData()
    }, [])

    // Filter variants based on selected variants in productData
    useEffect(() => {
        const selectedVariantNames =
            productData?.variantInfo?.selectedVariants?.map((variant) => variant.variantName) || []

        setFilteredVariants(originalVariants?.filter((variant) => !selectedVariantNames.includes(variant.name)))
    }, [productData.variantInfo?.selectedVariants, originalVariants])

    return (
        <ProductVariant
            variants={filteredVariants}
            setVariants={setFilteredVariants}
            productData={productData}
            setProductData={setProductData}
            singleProductData={singleProductData}
        />
    )
}

export default ProductVariantPage

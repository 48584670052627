import React, { useContext, useEffect, useState } from 'react'
import CourierList from '../components/CourierList/CourierList'
import { Auth } from '../contexts/allContext'

const CourierListPage = () => {
    const [couriers, setCouriers] = useState('')
    const apiV1 = process.env.REACT_APP_API_URL_V1
    const [refetch, setRefetch] = useState(false)
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token
    const [courierCount, setCourierCount] = useState(0)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiV1}/couriers/all?skip=0&limit=10`, {
                    headers: {
                        Accept: 'appllication/json',
                        'Content-Type': 'application/json',
                    },
                    method: 'GET',
                })
                const data = await response.json()
                if (response.ok) {
                    setCouriers(data[1])
                    setCourierCount(data[0]?.results)
                } else {
                    setCouriers([])
                }
            } catch (error) {
                setCouriers([])
            }
        }
        fetchData()
        if (refetch) {
            fetchData()
            setRefetch(false)
        }
    }, [apiV1, refetch])

    return (
        <CourierList
            couriers={couriers}
            setRefetch={setRefetch}
            refetch={refetch}
            token={token}
            apiV1={apiV1}
            courierCount={courierCount}
        />
    )
}

export default CourierListPage

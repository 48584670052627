import React, { useRef, useState, useContext } from 'react'
import ImgIcon from '../../assets/Courier/imageFieldPlaceHolder.png'
import { Auth } from '../../contexts/allContext'
import Formv1 from '../Resources/FormV1/Formv1'
import classes from './CreateCourier.module.css'

const CreateCourier = () => {
    const [msg, setMsg] = useState('')
    const [submitStatus, setSubmitStatus] = useState(false)
    const [selected, setSelected] = useState(null)
    const inputRef = useRef()
    const { stateAuth } = useContext(Auth)
    const apiV1 = process.env.REACT_APP_API_URL_V1
    const token = stateAuth.token
    const [courierData, setCourierData] = useState({
        name: '',
        description: '',
    })
    const handleSubmit = async (e) => {
        e.preventDefault()
        const queryParams = new URLSearchParams({
            name: courierData.name,
            description: courierData.description,
        }).toString()
        const filedata = new FormData()
        filedata.append('file', selected)
        const fetchdata = async () => {
            try {
                const response = await fetch(`${apiV1}/couriers/?${queryParams}`, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: filedata,
                })
                if (response.ok) {
                    setSubmitStatus(true)
                    setMsg('Courier Added!')
                    setCourierData({ name: '', description: '' })
                    setTimeout(() => {
                        setSubmitStatus(false)
                    }, 2000)
                } else {
                    setMsg('Something went wrong!')
                    setSubmitStatus(true)
                }
            } catch (error) {
                setMsg('Something went wrong!')
                setSubmitStatus(true)
                setTimeout(() => {
                    setSubmitStatus(false)
                }, 2000)
            }
        }
        fetchdata()
    }
    const handleOnChoose = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelected(e.target.files[0])
        }
    }
    return (
        <div className={classes.container}>
            <Formv1 title={'Create Courier'}>
                <form onSubmit={handleSubmit}>
                    <div className={classes.innerContainer}>
                        <p>
                            Courier Name<span className={classes.star}>*</span>
                        </p>

                        <input
                            type="text"
                            value={courierData.name}
                            onChange={(e) =>
                                setCourierData((prev) => ({
                                    ...prev,
                                    name: e.target.value,
                                }))
                            }
                            required
                        />
                        <p>Courier Description</p>
                        <textarea
                            rows={4}
                            onChange={(e) =>
                                setCourierData((prev) => ({
                                    ...prev,
                                    description: e.target.value,
                                }))
                            }></textarea>
                        <div className={classes.fileField} onClick={() => inputRef.current.click()}>
                            <img src={ImgIcon} alt="logo" />
                            <input type="file" onChange={handleOnChoose} ref={inputRef} required accept="image/*" />
                            <div>
                                <p>
                                    Click to upload Logo<span className={classes.star}>*</span>
                                </p>
                            </div>
                        </div>
                        {selected && (
                            <div className={classes.file}>
                                <img src={ImgIcon} alt="logo" />
                                <p>{selected.name}</p>
                            </div>
                        )}

                        {submitStatus && (
                            <div>
                                <span
                                    className={classes.submitMessage}
                                    style={{ color: `${msg?.substring(0, 2) === 'Co' ? '#5cb484' : '#a10f0f'}` }}>
                                    {msg}
                                </span>
                            </div>
                        )}
                    </div>
                    <div className={classes.submitContainer}>
                        <button type="submit">Submit</button>
                    </div>
                </form>
            </Formv1>
        </div>
    )
}

export default CreateCourier

import React, { useState } from 'react'
import { FaRegEdit } from 'react-icons/fa'
import { FiEdit3 } from 'react-icons/fi'
import { MdOutlineBrowserUpdated } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { ConvertDate } from '../../../../utils/dateFormat'
import { getTwoLetter } from '../../../../utils/getTwoLetter'
import Pagination from '../../../Pagination/Pagination'
import SkeletonLoader from '../../../Resources/SkeletonLoader/SkeletonLoader'
import TableV1 from '../../../Resources/TableV1/TableV1'
import TitleContainer from '../../../Resources/TitleContainer/TitleContainer'
import InfoPopup from './InfoPopup/InfoPopup'
import Popup from './Popup/Popup'
import classes from './ProductList.module.css'
import Searchbar from './Searchbar/Searchbar'

const ProductList = ({
    products,
    loading,
    filters,
    setFilters,
    setProductActive,
    productActive,
    search,
    setSearch,
    totalResults,
    page,
    itemsPerPage,
}) => {
    const [meta, data] = products
    const [open, setOpen] = useState(false)
    const [infoPopup, setInfoPopup] = useState(false)
    const [productId, setProductId] = useState('')
    const [singleProduct, setSingleProduct] = useState('')

    return (
        <div className={classes.tableContainer}>
            <Searchbar filters={filters} setFilters={setFilters} search={search} setSearch={setSearch} />
            <TitleContainer title={'Product List'} isCount={true} count={totalResults} />
            <TableV1>
                <thead>
                    <tr className={`${classes.tableRow}`}>
                        <th>SL</th>
                        <th>SKU Number</th>
                        <th>Product</th>
                        <th>Shop Name</th>
                        <th>Category</th>
                        <th>B2C Price</th>
                        <th>B2B Price</th>
                        <th>Stock</th>
                        <th>Variant</th>
                        <th>Status</th>
                        <th>Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <SkeletonLoader row={10} column={12} />
                    ) : data.length > 0 ? (
                        data?.map((item, index) => {
                            const { Product, ProductInventory, ImgProduct, ProductPrice, Category, Seller } = item

                            return (
                                <tr key={index} className={classes.tableData}>
                                    <td>{page * itemsPerPage + index + 1}</td>
                                    <td>
                                        {getTwoLetter(Seller?.page_name)}
                                        {ProductInventory.sku}
                                    </td>
                                    <td className={classes.productName}>
                                        <div className={classes.productCell}>
                                            <img
                                                src={ImgProduct.image_url}
                                                alt={item.product}
                                                className={classes.productImage}
                                            />
                                            <span className={classes.productTitle}>{Product.title}</span>
                                        </div>
                                    </td>
                                    <td className={classes.productShop}>{Seller.page_name}</td>
                                    <td lassName={classes.productShop}>{Category.name}</td>
                                    <td>
                                        {Product.is_retail == true ? ProductPrice.final_retail_price : 'Not Available'}
                                    </td>
                                    <td>
                                        {Product.is_wholesale == true
                                            ? ProductPrice.final_wholesale_price
                                            : 'Not Available'}
                                    </td>
                                    <td>{ProductInventory.quantity}</td>
                                    <td>{ProductInventory?.is_variant ? 'Available' : 'Not Available'}</td>
                                    <td>
                                        <span
                                            className={`${classes.status} ${
                                                Product?.is_active ? classes.active : classes.pending
                                            }`}>
                                            {Product?.is_active ? 'Active' : 'Pending'}
                                        </span>
                                    </td>
                                    <td>{ConvertDate(Product.created_at)}</td>
                                    <td className={classes.icons}>
                                        <Link
                                            target="_blank"
                                            to={`/products/edit/${Product.id}`}
                                            className={classes.viewButton1}>
                                            <FaRegEdit title="edit" />
                                        </Link>

                                        {!Product.is_active && (
                                            <Link className={classes.viewButton2} style={{ marginLeft: '15px' }}>
                                                <FiEdit3
                                                    title="active"
                                                    onClick={(e) => {
                                                        setOpen(true)
                                                        setProductId(Product.id)
                                                        setSingleProduct(item)
                                                    }}
                                                />
                                            </Link>
                                        )}

                                        {Product.is_active && (
                                            <Link className={classes.viewButton2} style={{ marginLeft: '15px' }}>
                                                <MdOutlineBrowserUpdated
                                                    title="status"
                                                    onClick={(e) => {
                                                        setInfoPopup(true)
                                                        setSingleProduct(item)
                                                    }}
                                                />
                                            </Link>
                                        )}
                                    </td>
                                </tr>
                            )
                        })
                    ) : (
                        <tr>
                            <td colSpan="12" className={classes.tableNotFound}>
                                <h2>No Data Found</h2>
                            </td>
                        </tr>
                    )}
                </tbody>
            </TableV1>

            {open && (
                <Popup
                    form={open}
                    setForm={setOpen}
                    productId={productId}
                    singleProduct={singleProduct}
                    setProductActive={setProductActive}
                    productActive={productActive}
                />
            )}

            {infoPopup && (
                <InfoPopup
                    form={infoPopup}
                    setForm={setInfoPopup}
                    singleProduct={singleProduct}
                    setProductActive={setProductActive}
                    productActive={productActive}
                />
            )}
        </div>
    )
}

export default ProductList

import React, { useContext, useEffect, useState } from 'react'
import VariantList from '../components/VariantList/VariantList'
import { Auth } from '../contexts/allContext'

const VariantListPage = () => {
    const [variants, setVariants] = useState([])
    const [count, setCount] = useState(0)
    const apiV1 = process.env.REACT_APP_API_URL_V1
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token
    const [refetch, setRefetch] = useState(false)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiV1}/variants/all`, {
                    headers: {
                        Accept: 'appllication/json',
                        'Content-Type': 'application/json',
                    },
                    method: 'GET',
                })
                const data = await response.json()
                if (response.ok) {
                    setVariants(data[1])
                    setCount(data[0]?.results)
                } else {
                    setVariants([])
                }
            } catch (error) {
                setVariants([])
            }
        }
        fetchData()
        if (refetch) {
            fetchData()
            setRefetch(false)
        }
    }, [apiV1, refetch])

    return (
        <VariantList
            variants={variants}
            apiV1={apiV1}
            token={token}
            refetch={refetch}
            setRefetch={setRefetch}
            count={count}
        />
    )
}

export default VariantListPage

import { useState } from 'react'
import ReactPaginate from 'react-paginate'
import classes from './Pagination.module.css'

export default function Pagination({ totalResults, itemsPerPage, onPageChange, currentPage }) {
    const pageCount = Math.ceil(totalResults / itemsPerPage)
    const [pageInput, setPageInput] = useState(currentPage + 1)

    const handlePageClick = (event) => {
        onPageChange(event.selected)
        setPageInput(event.selected + 1)
    }

    const handleInputChange = (e) => {
        setPageInput(e.target.value)
    }

    const handlePageSubmit = (e) => {
        e.preventDefault()
        let pageNumber = parseInt(pageInput, 10)
        if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= pageCount) {
            onPageChange(pageNumber - 1)
        } else {
            setPageInput(currentPage + 1)
        }
    }

    return (
        <div className={classes.pagination_container}>
            <form onSubmit={handlePageSubmit} className={classes.page_form}>
                <input
                    type="number"
                    value={pageInput}
                    onChange={handleInputChange}
                    min="1"
                    max={pageCount}
                    className={classes.page_input}
                />
                <button type="submit" className={classes.page_button}>
                    Go
                </button>
            </form>
            <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                pageCount={pageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
                pageClassName={classes.page_item}
                pageLinkClassName={classes.page_link}
                previousClassName={classes.page_item}
                previousLinkClassName={classes.page_link}
                nextClassName={classes.page_item}
                nextLinkClassName={classes.page_link}
                breakClassName={classes.page_item}
                breakLinkClassName={classes.page_link}
                containerClassName={classes.pagination}
                activeClassName={classes.active}
                forcePage={currentPage}
            />
        </div>
    )
}

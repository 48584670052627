import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function SkeletonLoader({ row, column }) {
    return Array.from({ length: row }).map((_, rowIndex) => (
        <tr key={rowIndex}>
            {Array.from({ length: column }).map((_, colIndex) => (
                <td key={colIndex}>
                    <Skeleton height={20} />
                </td>
            ))}
        </tr>
    ))
}

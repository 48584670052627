import React, { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import { ProductAdd } from '../../../components'
import { getCategory, getSeller, getSingleSeller, getSubCategory, getSubSubCategory } from '../../../utils/functions'

export default function ProductAddPage() {
    const { productData, setProductData } = useOutletContext()
    const { generalInfo } = productData
    const [allCategories, setAllCategories] = useState({
        categories: [],
        subCategories: [],
        subSubCategories: [],
    })
    const [seller, setSeller] = useState([])

    // Fetch main categories
    useEffect(() => {
        const fetchData = async () => {
            try {
                const category = await getCategory()
                setAllCategories((prev) => ({
                    ...prev,
                    categories: category?.data || [],
                }))
            } catch (error) {
                console.error('Error fetching categories:', error)
            }
        }
        fetchData()
    }, [])

    // Fetch subcategories
    useEffect(() => {
        const fetchSubCategories = async () => {
            if (generalInfo.selectedCategoryId) {
                try {
                    const subCategory = await getSubCategory(generalInfo.selectedCategoryId)
                    setAllCategories((prev) => ({
                        ...prev,
                        subCategories: subCategory?.data || [],
                    }))
                } catch (error) {
                    console.error('Error fetching subcategories:', error)
                }
            }
        }
        fetchSubCategories()
    }, [generalInfo.selectedCategoryId])

    // Fetch sub-subcategories
    useEffect(() => {
        const fetchSubSubCategories = async () => {
            if (generalInfo.selectedSubCategoryId) {
                try {
                    const subSubCategory = await getSubSubCategory(generalInfo.selectedSubCategoryId)

                    setAllCategories((prev) => ({
                        ...prev,
                        subSubCategories: subSubCategory?.data || [],
                    }))
                } catch (error) {
                    console.error('Error fetching sub-subcategories:', error)
                }
            }
        }
        fetchSubSubCategories()
    }, [generalInfo.selectedSubCategoryId])

    // Fetch seller
    useEffect(() => {
        const fetchSeller = async () => {
            if (generalInfo.sellerid?.length >= 7) {
                try {
                    const sellerData = await getSingleSeller(generalInfo.sellerid)
                    setSeller(sellerData.data || [])
                } catch (error) {
                    console.error('Error fetching sub-subcategories:', error)
                }
            }
        }
        fetchSeller()
    }, [generalInfo.sellerid])

    return (
        <ProductAdd
            allCategories={allCategories}
            setProductData={setProductData}
            productData={productData}
            seller={seller}
        />
    )
}

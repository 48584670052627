import React, { useContext, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Auth } from '../../../contexts/allContext'
import { svgs } from '../../../utils/findSvgs/findSvgs'
import {
    getSingleProduct,
    updateProduct,
    updateProductCategory,
    updateProductImages,
    updateProductInventory,
    updateProductPrice,
    updateProductSubCats,
    updateProductVariants,
    uploadPDescriptionImage,
} from '../../../utils/functions'
import { DefaultBtn, PrimaryBtn } from '../../Resources/Buttons'
import classes from './ProductLayout.module.css'

// Import axios for making API requests

const ProductEditLayout = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { stateAuth } = useContext(Auth)
    const { id: productId } = useParams()
    const pages = ['general', 'variant', 'delivery']
    const [isActivePageIndex, setIsActivePageIndex] = useState(0)
    const [singleProductData, setsingleProductData] = useState([])

    // this object shows teh full data workflow.........
    const [productData, setProductData] = useState({
        generalInfo: {
            title: '',
            selectedCategory: null,
            selectedSubCategory: singleProductData?.Product?.sub_category_id,
            selectedSubSubCategory: singleProductData?.Product?.sub_sub_category_id,
            type: null,
            unit: 'Piece',
            moq: 5,
            tags: '',
            productImages: [],
            description: null,
        },
        variantInfo: {
            quantity: 5,
            quantityAndPrice: {
                price: { b2bp: 0, b2cp: 0 },
                discount: { b2bdp: 0, b2cdp: 0 },
                profit: { b2bpm: 0, b2cpm: 0 },
                details: { cop: 0 },
            },
            isMultiVariant: false,
            selectedVariants: [],
            separetePrice: false,
            variants: [],
            separetePriceVariants: [],
        },
        deliveryInfo: {
            packageInfo: null,
            deliveryPartnerInfo: {
                courierId: 0,
            },
        },
        error: {},
    })

    useEffect(() => {
        if (productId) {
            const fetchData = async () => {
                try {
                    const singleProduct = await getSingleProduct(productId)
                    setsingleProductData(singleProduct)
                } catch (error) {
                    console.error('Error fetching single Product', error)
                }
            }
            fetchData()
        }
    }, [productId])

    // this is for routing for all pages
    const handleNavigation = async (direction) => {
        if (direction === '+') {
            const nextPage = pages[isActivePageIndex + 1]
            navigate(`/products/edit/${productId}/${nextPage}`)
        } else {
            const nextPage = pages[isActivePageIndex - 1]
            navigate(`/products/edit/${productId}/${nextPage}`)
        }
    }

    // this is for tracking active, prevous, next page
    useEffect(() => {
        const activePageIndex = pages.findIndex((page) =>
            location.pathname.includes(`/products/edit/${productId}/${page}`)
        )
        setIsActivePageIndex(activePageIndex)
    }, [location])

    // update preview data

    useEffect(() => {
        const {
            Product,
            ProductAttribute,
            ProductTag,
            ImgProduct,
            ProductDelivery,
            Category,
            SubCategory,
            SubSubCategory,
            ProductDescription,
            ProductInventory,
            ProductPackage,
            ProductPrice,
            ProductVariant,
        } = singleProductData

        // updates general product data.....

        if (Product && ProductInventory && ProductAttribute) {
            setProductData((prev) => ({
                ...prev,
                generalInfo: {
                    ...prev.generalInfo,
                    unit: ProductAttribute.unit,
                    type: ProductAttribute.type,
                    title: Product.title,
                    isB2c: Product.is_retail,
                    isB2b: Product.is_wholesale,
                    category: Category.name,
                    subCategory: SubCategory.name,
                    subSubCategory: SubSubCategory.name,
                    moq: ProductInventory.min_order_quantity,
                },
                variantInfo: {
                    ...prev.variantInfo,
                    isMultiVariant: ProductInventory.is_variant,
                    separetePrice: ProductInventory.is_price_vary,
                    productPriceId: ProductPrice.id,
                    inventoryId: ProductInventory.id,
                    imgId: ImgProduct.id,
                    quantity: ProductInventory.quantity,
                },
            }))
        }

        // update product tags.....

        if (ProductTag) {
            const productTags = Object.values(ProductTag)?.filter((value) => typeof value === 'string')
            if (productTags.length > 0) {
                setProductData((prev) => ({
                    ...prev,
                    generalInfo: { ...prev.generalInfo, tags: productTags },
                }))
            }
        }

        // update product images.....

        if (ImgProduct) {
            const initialImages = Object.entries(ImgProduct)
                .filter(([key, value]) => key.includes('image_url') && value)
                .map(([key, value, index]) => value)
            const productImages = {
                image1: {
                    src: initialImages[0] || '',
                    file: null,
                },
                image2: {
                    src: initialImages[1] || '',
                    file: null,
                },
                image3: {
                    src: initialImages[2] || '',
                    file: null,
                },
                image4: {
                    src: initialImages[3] || '',
                    file: null,
                },
                image5: {
                    src: initialImages[4] || '',
                    file: null,
                },
            }
            setProductData((prev) => ({ ...prev, generalInfo: { ...prev.generalInfo, productImages } }))
        }

        // update product description.....

        if (ProductDescription) {
            setProductData((prev) => ({
                ...prev,
                generalInfo: {
                    ...prev.generalInfo,
                    description: {
                        html: ProductDescription?.description,
                        url: ProductDescription?.image_url,
                        file: '',
                    },
                },
            }))
        }

        // update product prices.....

        if (ProductPrice) {
            const quantityAndPrice = {
                price: {
                    b2bp: ProductPrice.wholesale_price,
                    b2cp: ProductPrice.retail_price,
                    b2bd: ProductPrice.wholesale_discount_percentage,
                    b2cd: ProductPrice.retail_discount_percentage,
                },
                discount: {
                    b2bdp: ProductPrice.final_wholesale_price,
                    b2cdp: ProductPrice.final_retail_price,
                },
                profit: {
                    b2bpm: ProductPrice.final_wholesale_price - ProductPrice.manufacture_cost,
                    b2cpm: ProductPrice.final_retail_price - ProductPrice.manufacture_cost,
                },
                details: { cop: ProductPrice.manufacture_cost },
            }
            setProductData((prev) => ({
                ...prev,
                variantInfo: { ...prev.variantInfo, quantityAndPrice },
            }))
        }

        // update product variants.....

        if (ProductVariant) {
            const variants = ProductVariant.map((variant) => ({
                type: variant.variant_type,
                value: variant.variant_value,
                type2: variant.variant_type2,
                value2: variant.variant_value2,
                quantity: variant?.quantity || 0,
                cop: variant?.manufacture_cost || 0,
                b2cp: variant?.retail_price || 0,
                b2cdPercentage: variant?.retail_discount_percentage || 0,
                b2cdPrice: variant?.final_retail_price || 0,
                b2bp: variant?.wholesale_price || 0,
                b2bdPercentage: variant?.wholesale_discount_percentage || 0,
                b2bdPrice: variant?.final_wholesale_price || 0,
                id: variant.id,
            }))
            setProductData((prev) => ({
                ...prev,
                variantInfo: {
                    ...prev.variantInfo,
                    separetePriceVariants: ProductInventory.is_price_vary ? variants : [],
                    variants: !ProductInventory.is_price_vary ? variants : [],
                },
            }))
        }

        // update product packages.......

        if (ProductPackage) {
            setProductData((prev) => ({
                ...prev,
                deliveryInfo: {
                    ...prev.deliveryInfo,
                    packageInfo: {
                        weight: ProductPackage.weight,
                        height: ProductPackage.height,
                        width: ProductPackage.width,
                        length: ProductPackage.length,
                    },
                },
            }))
        }

        // update porduct delivery info.....

        if (ProductDelivery) {
            setProductData((prev) => ({
                ...prev,
                deliveryInfo: {
                    ...prev.deliveryInfo,
                    deliveryPartnerInfo: {
                        ...prev.deliveryInfo.deliveryPartnerInfo,
                        courierId: ProductDelivery?.courier_id,
                        cod: ProductDelivery.cod_charge_percentage,
                        fd: ProductDelivery.free_delivery_amount,
                        dt: ProductDelivery?.delivery_time,
                    },
                },
            }))
        }
    }, [singleProductData])

    const handleSubmit = async () => {
        try {
            const userToken = stateAuth?.token
            const { generalInfo, variantInfo } = productData

            if (!userToken || !productId) {
                toast.error('Authentication token or product ID missing')
                return
            }

            // Prepare all the update promises
            const updatePromises = []

            updatePromises.push(updateProduct(productData, productId, userToken))
            updatePromises.push(updateProductInventory(productData, variantInfo.inventoryId, userToken))
            if (variantInfo.isMultiVariant) {
                updatePromises.push(updateProductVariants(productData, productId, userToken))
            } else {
                updatePromises.push(updateProductPrice(productData, variantInfo.productPriceId, userToken))
            }
            if (generalInfo?.description?.file) {
                updatePromises.push(uploadPDescriptionImage(productId, generalInfo.description.file))
            }
            if (generalInfo?.productImages && Object.values(generalInfo.productImages).some((image) => image.file)) {
                updatePromises.push(updateProductImages(generalInfo.productImages, variantInfo.imgId))
            }
            if (generalInfo.selectedSubCategory || generalInfo.selectedSubSubCategory) {
                updatePromises.push(updateProductSubCats(generalInfo, singleProductData, productId, userToken))
            }
            if (generalInfo.selectedCategory) {
                updatePromises.push(updateProductCategory(generalInfo, singleProductData, productId, userToken))
            }

            // Execute all updates concurrently
            const results = await Promise.all(updatePromises)

            if (results) {
                toast.success('Product update successful')
            }
            navigate('/products/products-lists') // Navigate to the next page
        } catch (error) {
            console.error('Error updating product:', error)
            toast.error('Failed to update product. Please try again.')
        }
    }

    return (
        <div>
            <div className={classes.layout}>
                <h2>Edit Product</h2>
                <div className={classes.navigation}>
                    {pages.map((page, index) => {
                        const lastIndex = pages.length - 1 === index
                        const active = isActivePageIndex === index
                        const completedPage = isActivePageIndex > index

                        return (
                            <div
                                key={page.name}
                                className={`${completedPage ? classes.completed : ''} ${active ? classes.active : ''}`}>
                                <div className={classes.indicator}>{svgs.click}</div>
                                <div>
                                    <h3>
                                        {page} {index == 1 && '& pricing'}
                                    </h3>
                                </div>
                                {!lastIndex && <div className={classes.line}>{svgs.line}</div>}
                            </div>
                        )
                    })}
                </div>
            </div>
            <Outlet
                context={{
                    productData,
                    setProductData,
                    singleProductData,
                }}
            />
            <div className={classes.footer}>
                <div className={classes.btnGroup}>
                    <div onClick={() => handleSubmit()}>
                        <PrimaryBtn label={'Update'} />
                    </div>

                    {isActivePageIndex < 2 && (
                        <div onClick={() => handleNavigation('+')}>
                            <PrimaryBtn label={'Continue'} iconPosition={'right'} />
                        </div>
                    )}
                    <div onClick={() => handleNavigation('-')}>
                        {isActivePageIndex > 0 && <DefaultBtn label="Go Back" />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductEditLayout

import React, { Fragment, useEffect, useState } from 'react'
import { svgs } from '../../../../utils/findSvgs/findSvgs'
import { priceType, variantType } from '../../../../utils/static/static'
import { PrimaryBtn } from '../../../Resources/Buttons'
import RenderDropDown from '../../../Resources/CustomDropDown/RenderDropdown/RenderDropDown'
import classes from './PorductVariant.module.css'
import ProductVariantTable from './ProductVariantTable/ProductVariantTable'
import SingleVariant from './SingleVariant'

const ProductVariant = ({ variants, productData, setProductData, singleProductData }) => {
    const { ProductInventory, ProductVariant } = singleProductData
    const [isVariantFormOpen, setIsVariantFormOpen] = useState(true)
    const [value, setValue] = useState('')
    const [attribute, setAttribute] = useState('')
    const [isDisabledBtn, setDisabledBtn] = useState(null)
    // states from single variant
    const { variantInfo } = productData

    const saveVariant = () => {
        setProductData((prev) => {
            const updatedVariants = prev.variantInfo.selectedVariants.map((variant) => {
                if (!variant.isCompleted) {
                    return {
                        ...variant,
                        isCompleted: true,
                        isEdited: false,
                    }
                }
                return variant
            })

            return {
                ...prev,
                variantInfo: {
                    ...prev.variantInfo,
                    selectedVariants: updatedVariants,
                    isEdited: false,
                },
            }
        })
    }

    const editVariant = (editVariantId) => {
        setProductData((prev) => {
            const updatedVariants = prev.variantInfo.selectedVariants.map((variant) => {
                if (variant.id === editVariantId) {
                    return {
                        ...variant,
                        isCompleted: false,
                        isEdited: true,
                    }
                }
                return { ...variant, isCompleted: true }
            })

            return {
                ...prev,
                variantInfo: {
                    ...prev.variantInfo,
                    selectedVariants: updatedVariants,
                },
            }
        })
    }

    const deleteVariant = (deleteVariantId) => {
        setProductData((prev) => {
            const updatedVariants = prev.variantInfo.selectedVariants.filter(
                (variant) => variant.id !== deleteVariantId
            )
            return {
                ...prev,
                variantInfo: {
                    ...prev.variantInfo,
                    selectedVariants: updatedVariants,
                },
            }
        })
        setIsVariantFormOpen(true)
    }

    //  attribute handler

    const createAttribute = (e) => {
        if (e.key === 'Enter' || (e.type === 'click' && attribute.length > 1)) {
            setProductData((prev) => {
                const updatedVariants = prev.variantInfo.selectedVariants.map((variant) => {
                    if (!variant.isCompleted) {
                        return {
                            ...variant,
                            attributes: [...variant.attributes, attribute],
                        }
                    }
                    return variant
                })

                return {
                    ...prev,
                    variantInfo: {
                        ...prev.variantInfo,
                        selectedVariants: updatedVariants,
                    },
                }
            })
            // Clear the input field (if necessary)
            setAttribute('')
        }
    }

    const deleteAttribute = (removeAttribute) => {
        setProductData((prev) => {
            const updatedVariants = prev.variantInfo.selectedVariants.map((variant) => {
                if (!variant.isCompleted) {
                    return {
                        ...variant,
                        attributes: variant.attributes.filter((attribute) => attribute !== removeAttribute),
                    }
                }
                return variant
            })

            return {
                ...prev,
                variantInfo: {
                    ...prev.variantInfo,
                    selectedVariants: updatedVariants,
                },
            }
        })
    }

    // it will check varinat list. if varinat list >= 2 , then make variant form false

    useEffect(() => {
        const checkVariant = variantInfo.selectedVariants.some((variant) => !variant.isCompleted)
        if (!checkVariant && variantInfo.selectedVariants.length >= 2) {
            setIsVariantFormOpen(false)
        } else {
            setIsVariantFormOpen(true)
        }
    }, [variantInfo.selectedVariants])

    const isEditedVariant = variantInfo?.selectedVariants.find((variant) => variant.isEdited)

    useEffect(() => {
        if (ProductInventory && variantInfo.selectedVariants.length === 0) {
            const { is_price_vary, is_variant } = ProductInventory
            let variant1 = { variantName: '', attributes: [] }
            let variant2 = { variantName: '', attributes: [] }
            let selectedVariants = []
            if (ProductVariant) {
                ProductVariant?.forEach((variant) => {
                    // Handle variant_type
                    if (variant.variant_type) {
                        if (!variant1.variantName) {
                            variant1['variantName'] = variant.variant_type
                        }
                        if (variant1.variantName === variant.variant_type) {
                            variant1['attributes'] = [...new Set([...variant1['attributes'], variant.variant_value])]
                        }
                    }

                    // Handle variant_type2
                    if (variant.variant_type2) {
                        if (!variant2.variantName) {
                            variant2['variantName'] = variant.variant_type2
                        }
                        if (variant2.variantName === variant.variant_type2) {
                            variant2['attributes'] = [...new Set([...variant2['attributes'], variant.variant_value2])]
                        }
                    }
                })

                if (variant1?.variantName) {
                    selectedVariants.push({
                        id: variant1.variantName,
                        variantName: variant1.variantName,
                        attributes: variant1.attributes,
                        isCompleted: true,
                    })
                }
                if (variant2?.variantName) {
                    selectedVariants.push({
                        id: variant2.variantName,
                        variantName: variant2.variantName,
                        attributes: variant2.attributes,
                        isCompleted: true,
                    })
                }
            }
            if (selectedVariants.length > 0) {
                setProductData((prev) => ({
                    ...prev,
                    variantInfo: {
                        ...prev.variantInfo,
                        isMultiVariant: is_variant,
                        separetePrice: is_price_vary,
                        selectedVariants,
                    },
                }))
            }
        }
    }, [singleProductData])

    return (
        <form onSubmit={(e) => e.preventDefault()}>
            {/* main form */}

            {!variantInfo.separetePrice && (
                <>
                    <SingleVariant
                        productData={productData}
                        setProductData={setProductData}
                        singleProductData={singleProductData}
                    />
                    <div className={classes.formBox}>
                        <div className={`${classes.title}`}>How would you like to sell your product?</div>
                        <div className={classes.radioBtn}>
                            {variantType?.map((sell) => {
                                const isActiveId = !variantInfo.isMultiVariant ? 1 : 2
                                return (
                                    <div
                                        className={`${sell.id == isActiveId ? classes.activeBox : ''}`}
                                        key={sell.id}
                                        onClick={() =>
                                            setProductData((prev) => ({
                                                ...prev,
                                                variantInfo: {
                                                    ...prev.variantInfo,
                                                    isMultiVariant: !variantInfo.isMultiVariant,
                                                },
                                            }))
                                        }>
                                        <div className={`${sell.id == isActiveId ? classes.active : ''}`}>
                                            <div></div>
                                        </div>
                                        <label>{sell.name}</label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </>
            )}

            {variantInfo?.isMultiVariant ? (
                <>
                    <div className={classes.formBox}>
                        <div className={`${classes.title} ${classes.borderBottom}`}>
                            <div className={classes.spreadTitle}>
                                <div> {svgs.variant} Product Variants</div>
                                {isVariantFormOpen && <PrimaryBtn label={'Add Variant'} iconPosition={'left'} />}
                            </div>
                        </div>

                        {isVariantFormOpen && (
                            <div className={classes.formLayout2}>
                                <div className={`${classes.formGroup} ${classes.disabledVariant}`}>
                                    <RenderDropDown
                                        label={'Variant Name'}
                                        data={variants}
                                        placeholder={isEditedVariant ? '' : 'Select Your Variant'}
                                        dropdownLabel={'Variant Name'}
                                        setProductData={setProductData}
                                        value={value}
                                        isDisabled={isEditedVariant}
                                        defaultValue={
                                            variantInfo.selectedVariants.find(
                                                (variant) => !variant.isCompleted || variant.isEdited
                                            )?.variantName
                                        }
                                    />
                                    <span>{isEditedVariant?.variantName}</span>
                                </div>
                                <div className={classes.formGroup}>
                                    <label>
                                        Attributes<sup>*</sup>
                                    </label>
                                    <div className={classes.attribute}>
                                        <div className={classes.itemGroup}>
                                            <input
                                                type="string"
                                                placeholder="Create Attributes"
                                                onKeyDown={createAttribute}
                                                value={attribute}
                                                onChange={(e) => setAttribute(e.target.value)}
                                            />
                                            {attribute.length > 1 && (
                                                <button onClick={createAttribute} className={classes.submitBtn}>
                                                    Enter
                                                </button>
                                            )}
                                        </div>
                                        <div className={classes.wrapper}>
                                            {variantInfo?.selectedVariants
                                                .find((variant) => !variant.isCompleted)
                                                ?.attributes?.map((attribute, index) => (
                                                    <button key={index} className={classes.attributeBtn}>
                                                        {attribute}
                                                        {!variantInfo?.isMultiVariant && (
                                                            <span onClick={() => deleteAttribute(attribute)}>
                                                                {svgs.closeIcon}
                                                            </span>
                                                        )}
                                                    </button>
                                                ))}
                                        </div>
                                    </div>
                                </div>

                                <div className={classes.formGroup}></div>
                                <div className={classes.attributeBtnWrapper}>
                                    <div className={classes.btnGroup}>
                                        <div onClick={saveVariant}>
                                            <PrimaryBtn label={'Save Variant'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {renderVariants(
                            variantInfo?.selectedVariants,
                            editVariant,
                            deleteVariant,
                            isVariantFormOpen,
                            variantInfo
                        )}
                    </div>

                    {variantInfo.selectedVariants.some((variant) => variant.isCompleted) && (
                        <div className={classes.formBox}>
                            <div className={`${classes.title}`}>
                                Would you like to add separate prices for each variant?
                            </div>
                            <div className={classes.radioBtn}>
                                {priceType?.map((sell) => {
                                    const isActiveId = !variantInfo.separetePrice ? 1 : 2
                                    return (
                                        <div
                                            className={`${sell.id == isActiveId ? classes.activeBox : ''}`}
                                            key={sell.id}
                                            onClick={() =>
                                                setProductData((prev) => ({
                                                    ...prev,
                                                    variantInfo: {
                                                        ...prev.variantInfo,
                                                        separetePrice: !variantInfo.separetePrice,
                                                    },
                                                }))
                                            }>
                                            <div className={`${sell.id == isActiveId ? classes.active : ''}`}>
                                                <div></div>
                                            </div>
                                            <label>{sell.name}</label>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )}

                    {variantInfo.selectedVariants.some((variant) => variant.isCompleted) && (
                        <ProductVariantTable
                            productData={productData}
                            setProductData={setProductData}
                            singleProductData={singleProductData}
                        />
                    )}
                </>
            ) : undefined}
        </form>
    )
}

const renderVariants = (selectedVariants, editVariant, deleteVariant, isVariantFormOpen, variantInfo) => {
    return (
        <>
            {selectedVariants.map((variant) => {
                const { id, variantName, attributes, isCompleted } = variant
                return (
                    <Fragment>
                        {isCompleted && (
                            <div className={isVariantFormOpen && classes.renderVariantWrapper}>
                                <div className={classes.renderVariant}>
                                    <div className={classes.card}>
                                        <h5>{variantName}</h5>
                                        {attributes?.map((attribute, index) => (
                                            <button key={index} className={classes.attributeBtn}>
                                                {attribute}
                                            </button>
                                        ))}
                                    </div>
                                    <div className={classes.action}>
                                        <button onClick={() => editVariant(id)}>{svgs.editIcon}Edit</button>
                                        {!variantInfo?.isMultiVariant && (
                                            <button onClick={() => deleteVariant(id)}>{svgs.trashIcon}</button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </Fragment>
                )
            })}
        </>
    )
}

export default ProductVariant

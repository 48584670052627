import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import ProductDelivery from '../../../components/Product/shared/ProductDelivery/ProductDelivery'
import { getCourierPriceList, getCouriers } from '../../../utils/functions'

const ProductDeliveryPage = () => {
    const { productData, setProductData } = useOutletContext()
    const [couriers, setCouriers] = useState([])
    const [selectedCourierId, setSelectedCourierId] = useState(1)
    const [priceList, setPriceList] = useState([])

    useEffect(() => {
        const courierId = productData.deliveryInfo?.deliveryPartnerInfo?.courierId || 1
        setSelectedCourierId(courierId)
        const getCourierAndPriceList = async () => {
            try {
                const couriers = await getCouriers()
                setCouriers(couriers)
                if (couriers.length >= 1) {
                    const priceList = await getCourierPriceList(courierId)
                    setPriceList(priceList)
                }
            } catch (error) {
                console.error('Error fetching couriers:', error)
            }
        }

        getCourierAndPriceList()
    }, [productData.deliveryInfo?.deliveryPartnerInfo?.courierId])

    return (
        <ProductDelivery
            couriers={couriers}
            selectedCourierId={selectedCourierId}
            setSelectedCourierId={setSelectedCourierId}
            priceList={priceList}
            productData={productData}
            setProductData={setProductData}
        />
    )
}

export default ProductDeliveryPage

import React, { useState } from 'react'
import { FaRegEdit } from 'react-icons/fa'
import classes from './Courier.module.css'
import CourierUpdatePopup from './CourierUpdatePopup/CourierUpdatePopup'

const Courier = ({ courier, active, setActive, fetch, refetch, token, apiV1, setRefetch, setSelected }) => {
    const [form, setForm] = useState(false)
    const handleChange = (e) => {
        setActive(e.target.value)
        setSelected((prev) => ({
            ...prev,
            id: e.target.id,
            name: e.target.value,
        }))
    }

    return (
        <div className={classes.container} style={courier.name === active ? { backgroundColor: '#f5cda857' } : {}}>
            <label className={classes.courierLabel}>
                <p>
                    <input
                        id={courier.id}
                        type="radio"
                        value={courier.name}
                        checked={courier.name === active}
                        onChange={handleChange}
                    />
                    {courier.name}
                </p>
                <img src={courier.logo_url} alt="courier icon" />

                <button
                    onClick={() => setForm(!form)}
                    style={
                        courier.name === active
                            ? { visibility: 'visible', opacity: 1 }
                            : { visibility: 'hidden', opacity: 0 }
                    }>
                    <FaRegEdit size={14} />
                </button>
            </label>
            {form && (
                <CourierUpdatePopup
                    form={form}
                    setForm={setForm}
                    courier={courier}
                    fetch={fetch}
                    refetch={refetch}
                    token={token}
                    apiV1={apiV1}
                    setRefetch={setRefetch}
                />
            )}
        </div>
    )
}

export default Courier

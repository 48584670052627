import React, { useState } from 'react'
import TableV1 from '../Resources/TableV1/TableV1'
import TitleContainer from '../Resources/TitleContainer/TitleContainer'
import Action from './Action/Action'
import classes from './OrderList.module.css'
import OrderPopup from './OrderPopup/OrderPopup'

const OrderList = () => {
    const [popup, setPopup] = useState(false)

    return (
        <div className={classes.container}>
            <TitleContainer title={'Order List'} isCount={true} count={2} />
            <div className={classes.list}>
                <TableV1>
                    <thead>
                        <tr>
                            <th>Sl.</th>
                            <th>Seller Name</th>
                            <th>Total Price(tk)</th>
                            <th>Payment Status</th>
                            <th>Order Status</th>
                            <th>Order Date</th>
                            <th className={classes.actionHeader}>Action</th>
                        </tr>
                    </thead>
                    <tbody className={classes.body}>
                        <tr className={classes.row}>
                            <td>01</td>
                            <td>ABC Traders</td>
                            <td>10000</td>
                            <td>Unpaid</td>
                            <td>Pending</td>
                            <td>01.01.2024</td>
                            <td className={classes.action}>
                                <Action setPopup={setPopup} popup={popup} />
                            </td>
                        </tr>
                        <tr className={classes.row}>
                            <td>02</td>
                            <td>DC Company</td>
                            <td>5000</td>
                            <td>Paid</td>
                            <td>Approved</td>
                            <td>01.02.2024</td>
                            <td className={classes.action}>
                                <Action setPopup={setPopup} popup={popup} />
                            </td>
                        </tr>
                    </tbody>
                </TableV1>
            </div>
            {popup && <OrderPopup setForm={setPopup} form={popup} />}
        </div>
    )
}

export default OrderList

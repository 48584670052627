import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ProductList } from '../../../components'
import Pagination from '../../../components/Resources/Pagination/Pagination'
import { UserInfo } from '../../../contexts/allContext'
import { useRequest } from '../../../hooks/useRequest'

const ProductListPage = () => {
    const { stateUser } = useContext(UserInfo)
    const userInfo = stateUser.info
    const { handleFetch } = useRequest()
    const location = useLocation()
    const navigate = useNavigate()

    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const [totalResults, setTotalResults] = useState(0)
    const queryParams = new URLSearchParams(location.search)
    const pageFromUrl = parseInt(queryParams.get('page')) || 1
    const [page, setPage] = useState(pageFromUrl - 1)
    const itemsPerPage = 10

    const [productActive, setProductActive] = useState(false)

    const [filters, setFilters] = useState({
        all: true,
        showAll: true,
        sortBy: 'desc',
        retail: false,
        wholesale: false,
        active: true,
        variant: '',
        feature: '',
    })

    const [search, setSearch] = useState({
        value: '',
        searchBy: 'sku',
        isSearchAble: false,
    })

    const fetchProducts = async (currentPage) => {
        const { sortBy, variant, wholesale, retail, feature, active, showAll } = filters
        const { value: searchValue, searchBy } = search
        const skip = currentPage * itemsPerPage
        setLoading(true)
        const params = {
            skip,
            limit: itemsPerPage,
            is_seller_active: true,
            is_product_active: active,
            sort_order_by: sortBy,
            ...(searchValue && { [searchBy]: searchValue }),
            ...(!showAll && { is_wholesale: wholesale }),
            ...(!showAll && { is_retail: retail }),
            // add new filters from here
            ...(variant !== '' && { is_variant: variant }),
            ...(feature !== '' && { is_feature: feature }),
        }
        try {
            const { data, isSuccess } = await handleFetch('v2/products/', params)
            if (isSuccess) {
                setProducts(data)
                setTotalResults(data[0].results)
            }
        } catch (err) {
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (userInfo?.id) {
            fetchProducts(page)
        }
    }, [userInfo?.id, page, filters, productActive])

    useEffect(() => {
        if (search.isSearchAble) {
            fetchProducts(page)
        }
    }, [search])

    //update url
    useEffect(() => {
        const newPageNumber = page + 1
        const newQueryParams = new URLSearchParams(location.search)
        newQueryParams.set('page', newPageNumber)
        navigate(`?${newQueryParams.toString()}`, { replace: true })
    }, [page, navigate, location.search])

    // make sure search product shows on first page ...
    useEffect(() => {
        if (filters?.search) {
            setPage(0)
        }
    }, [filters])

    return (
        <>
            {error ? (
                <p>Error: {error.message}</p>
            ) : (
                <>
                    <ProductList
                        products={products}
                        loading={loading}
                        filters={filters}
                        setFilters={setFilters}
                        setProductActive={setProductActive}
                        productActive={productActive}
                        search={search}
                        setSearch={setSearch}
                        totalResults={totalResults}
                        page={page}
                        itemsPerPage={itemsPerPage}
                    />
                    <Pagination
                        totalResults={totalResults}
                        itemsPerPage={itemsPerPage}
                        onPageChange={setPage}
                        currentPage={page}
                    />
                </>
            )}
        </>
    )
}

export default ProductListPage

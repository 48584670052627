import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import logo from '../../../assets/biztrade_logo/logo.png'
import { Auth, UserInfo } from '../../../contexts/allContext'
import { svgs } from '../../../utils/findSvgs/findSvgs'
import { menuItems } from '../Layout'
import classes from './Navbar.module.css'

export default function Navbar({ isSidebarPanelOpen, setIsSidebarPanelOpen }) {
    const { dispatchAuth } = useContext(Auth)
    const { dispatchUser } = useContext(UserInfo)
    const [openPopUp, setOpenPopUp] = useState(false)
    const { stateUser } = useContext(UserInfo)
    const userInfo = stateUser.info
    const navigate = useNavigate()
    const location = useLocation()

    const modalRef = useRef()

    useEffect(() => {
        const closeModal = (e) => {
            if (!modalRef?.current.contains(e.target)) {
                setOpenPopUp(false)
            }
        }
        document.addEventListener('click', closeModal)
        return () => {
            document.removeEventListener('click', closeModal)
        }
    }, [])

    const handleLogout = async () => {
        dispatchAuth({ type: 'remove' })
        dispatchUser({ type: 'remove' })
        navigate('/')
    }

    const handleMenuItemClick = (item) => {
        if (item.action === 'logout') {
            handleLogout()
        } else {
            navigate(item.path)
        }
    }
    return (
        <div className={classes.navbar}>
            <Link to={`https://biztradebd.com/`}>
                <div className={classes.navbarLeft}>
                    <img src={logo} alt="" />
                </div>
            </Link>
            <div className={classes.bar} onClick={() => setIsSidebarPanelOpen(!isSidebarPanelOpen)}>
                {isSidebarPanelOpen ? svgs.bar : svgs.rightArrow}
            </div>
            <div className={classes.navbarRight}>
                {/* <div className={classes.switch}>
                    <div
                        onClick={() => (!toggle ? handleBuyer() : '')}
                        className={toggle ? classes.active : classes.inactive}>
                        Buyer
                    </div>
                    <div className={!toggle ? classes.active : classes.inactive}>Seller</div>
                </div> */}
                <div className={classes.notificationPanel}>
                    <div className={classes.iconBox}>
                        <div className={classes.icon}>{svgs.notification}</div>
                    </div>
                    <div className={classes.iconBox}>
                        <div className={classes.icon}>{svgs.messages}</div>
                    </div>
                </div>
                <div className={classes.profile} onClick={() => setOpenPopUp(true)} ref={modalRef}>
                    <div className={classes.layout}>
                        {/* <div className={classes.sellerImage}>
                            <img src={sellerImage} alt="" />
                        </div> */}
                        <h5>{userInfo.name}</h5>
                    </div>
                    <div>{svgs.downArrow}</div>
                    <div className={`${classes.popUp} ${openPopUp ? classes.active : ''}`}>
                        <div className={classes.welcomeMessage}>
                            <h4> Good Morning!</h4>
                            <span>{userInfo.name}</span>
                        </div>

                        <div className={classes.menuItems}>
                            {menuItems.map((item) => {
                                return (
                                    <li
                                        key={item.id} // Add a unique key
                                        className={`${classes.linkItem} ${
                                            location.pathname === item.path ? classes.active : classes.inactive
                                        }`}
                                        onClick={() => handleMenuItemClick(item)} // Handle click event
                                    >
                                        <Link to={item.path}>
                                            {item.icon}
                                            {item.text}
                                        </Link>
                                    </li>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

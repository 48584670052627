import React from 'react'
import { useState, useEffect } from 'react'
import { Dashboard } from '../components'
import { apiV1 } from '../utils/api'

export default function DashboardPage() {
    const [totalSellers, setTotalSellers] = useState(0)
    const [pending, setPending] = useState(0)
    const [categories, setCategories] = useState([])
    const [subcategories, setSubCategories] = useState([])
    const [products, setProducts] = useState([])
    // For Total Sellers
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiV1}/companies/?skip=0&limit=0`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })

                const data = await response.json()

                if (response.ok) {
                    setTotalSellers(data[0].results)
                }
            } catch {
                setTotalSellers(0)
            }
        }
        fetchData()
    }, [])

    // For Pending Sellers
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiV1}/companies/inactive?skip=0&limit=0`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })

                const data = await response.json()

                if (response.ok) {
                    setPending(data[0].results)
                }
            } catch {
                setTotalSellers(0)
            }
        }
        fetchData()
    }, [])
    // For total Categories
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiV1}/categories/`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                const data = await response.json()
                if (response.ok) {
                    setCategories(data)
                }
            } catch (error) {
                setCategories([])
            }
        }
        fetchData()
    }, [])

    // For total Subcategories
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiV1}/categories/sub_category/`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                const data = await response.json()
                if (response.ok) {
                    setSubCategories(data)
                }
            } catch (error) {
                setSubCategories([])
            }
        }
        fetchData()
    }, [])
    // For total Products
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiV1}/products/?skip=0`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                const data = await response.json()
                if (response.ok) {
                    setProducts(data)
                }
            } catch (error) {
                setProducts([])
            }
        }
        fetchData()
    }, [])
    return (
        <div>
            <Dashboard
                activeSellers={totalSellers}
                pending={pending}
                categories={categories?.length}
                subcategories={subcategories?.length}
                products={products[0]?.results}
            />
        </div>
    )
}

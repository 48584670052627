//get category production
// import axios from 'axios'
import axios from 'axios'
import { apiV1, apiV2 } from '../../src/utils/api'
import axiosInstance from './axios/axios'

export async function getSubAndSubsubcategoryByCategoryId(id) {
    try {
        const response = await fetch(`${apiV1}/categories/single/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data
        }
    } catch {}
}

export async function getSubcategoryByCategoryId(id) {
    try {
        const response = await fetch(`${apiV1}/categories/sub_category/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data
        }
    } catch {}
}

export async function getSubsubcategoryBySubcategoryId(id) {
    try {
        const response = await fetch(`${apiV1}/categories/sub_sub_category/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data
        }
    } catch {}
}

export async function getProductsByCategory(id) {
    try {
        const response = await fetch(`${apiV1}/products/category/${id}?skip=0&limit=100`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data[1]
        }
    } catch {}
}

export async function getProductsBySubcategory(id) {
    try {
        const response = await fetch(`${apiV1}/products/sub_category/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data[1]
        }
    } catch {}
}

export async function getProductsBySubsubcategory(id) {
    try {
        const response = await fetch(`${apiV1}/products/sub_sub_category/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data[1]
        }
    } catch {}
}

export async function getCompany(name, skip = 0, limit = 10, startDate, endDate) {
    let api = ''
    if (startDate?.length > 0 && endDate?.length > 0) {
        api = `${apiV1}/companies/?name=${name}&start_date=${startDate}&end_date=${endDate}&skip=${skip}&limit=${limit}`
    } else {
        api = `${apiV1}/companies/?name=${name}&skip=${skip}&limit=${limit}`
    }
    try {
        const response = await fetch(`${api}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return [data[0], data[1]]
        }
    } catch {}
}

export async function getSeller(skip, limit) {
    try {
        const response = await fetch(`${apiV1}/sellers/?skip=${skip}&limit=${limit}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return [data[0], data[1]]
        }
    } catch {}
}

export async function getBuyer(skip, limit) {
    try {
        const response = await fetch(`${apiV1}/buyers/?skip=${skip}&limit=${limit}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return [data[0], data[1]]
        }
    } catch {}
}

export async function getFeaturedSeller() {
    try {
        const response = await fetch(`${apiV1}/sellers/feature?skip=0&limit=10`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data[1]
        }
    } catch {}
}
// -------------------------------------
export async function getProductsBySeller(proactive, pageSkip, sku, shopname, title, retail, wholesale) {
    try {
        const response = await axios.get(
            `${apiV2}/products/?is_seller_active=true&is_product_active=${proactive}&title=${title}&sku=${sku}&shop_name=${shopname}&skip=${pageSkip}&limit=10`
        )
        return response.data
    } catch {
        throw new Error('Failed to fetch products. Please check your internet connection!')
    }
}

export async function createProduct(productData, userId) {
    try {
        const { generalInfo, deliveryInfo, variantInfo } = productData
        const allVariant = variantInfo.separetePrice ? variantInfo.separetePriceVariants : variantInfo.variants
        const quantity = variantInfo.isMultiVariant
            ? variantInfo.separetePrice
                ? variantInfo.separetePriceVariants.reduce((total, variant) => total + variant.quantity, 0)
                : variantInfo.variants.reduce((total, variant) => total + variant.quantity, 0)
            : variantInfo.quantity
        const tags =
            generalInfo?.tags?.length > 0
                ? Object.fromEntries(
                      generalInfo.tags.map((tag, index) => [index === 0 ? 'tag' : `tag${index + 1}`, tag])
                  )
                : ''
        const bestVariant = variantInfo.separetePriceVariants[0]
        const productPrice =
            variantInfo.separetePrice && variantInfo.separetePriceVariants.length > 0
                ? {
                      manufacture_cost: bestVariant.cop,
                      retail_price: generalInfo.isB2c ? bestVariant.b2cp : 0,
                      retail_discount_percentage: generalInfo.isB2c ? bestVariant.b2cdPercentage : 0,
                      retail_discount_amount: generalInfo.isB2c ? bestVariant.b2cp - bestVariant.b2cdPrice : 0,
                      final_retail_price: generalInfo.isB2c
                          ? bestVariant.b2cdPercentage
                              ? bestVariant.b2cdPrice
                              : bestVariant.b2cp
                          : 0,
                      wholesale_price: generalInfo.isB2b ? bestVariant.b2bp : 0,
                      wholesale_discount_percentage: generalInfo.isB2b ? bestVariant.b2bdPercentage : 0,
                      wholesale_discount_amount: generalInfo.isB2b ? bestVariant.b2bp - bestVariant.b2bdPrice : 0,
                      final_wholesale_price: generalInfo.isB2b
                          ? bestVariant.b2bdPercentage
                              ? bestVariant.b2bdPrice
                              : bestVariant.b2bp
                          : 0,
                  }
                : {
                      manufacture_cost: variantInfo.quantityAndPrice.details.cop,
                      retail_price: generalInfo.isB2c ? variantInfo.quantityAndPrice.price.b2cp : 0,
                      retail_discount_percentage: generalInfo.isB2c ? variantInfo.quantityAndPrice.price.b2cd : 0,
                      retail_discount_amount: generalInfo.isB2c
                          ? variantInfo.quantityAndPrice.price.b2cp - variantInfo.quantityAndPrice.discount.b2cdp
                          : 0,
                      final_retail_price: generalInfo.isB2c
                          ? variantInfo.quantityAndPrice.price.b2cd
                              ? variantInfo.quantityAndPrice.discount.b2cdp
                              : variantInfo.quantityAndPrice.price.b2cp
                          : 0,
                      wholesale_price: generalInfo.isB2b ? variantInfo.quantityAndPrice.price.b2bp : 0,
                      wholesale_discount_percentage: generalInfo.isB2b ? variantInfo.quantityAndPrice.price.b2bd : 0,
                      wholesale_discount_amount: generalInfo.isB2b
                          ? variantInfo.quantityAndPrice.price.b2bp - variantInfo.quantityAndPrice.discount.b2bdp
                          : 0,
                      final_wholesale_price: generalInfo.isB2b
                          ? variantInfo.quantityAndPrice.price.b2bd
                              ? variantInfo.quantityAndPrice.discount.b2bdp
                              : variantInfo.quantityAndPrice.price.b2bp
                          : 0,
                  }

        const payload = {
            Product: {
                title: generalInfo.name,
                is_active: true,
                is_biztrade: true,
                is_retail: generalInfo?.isB2c,
                is_wholesale: generalInfo?.isB2b,
                is_adult: false,
                is_feature: false,
                category_id: generalInfo.selectedCategoryId,
                sub_category_id: generalInfo.selectedSubCategoryId,
                sub_sub_category_id: generalInfo.selectedSubSubCategoryId,
            },
            ProductDescription: {
                description: generalInfo.description?.html,
            },
            ProductPrice: productPrice,
            ProductTag: tags,
            ProductAttribute: {
                type: generalInfo.type,
                unit: generalInfo.unit,
            },
            ProductVariant: allVariant?.map((variant, index) => ({
                variant_type: variant.type,
                variant_value: variant.value,
                variant_type2: variant.type2 || '',
                variant_value2: variant.value2 || '',
                is_base_variant: index === 0,
                quantity: variant.quantity,
                manufacture_cost: variant.cop,
                retail_price: generalInfo.isB2c ? variant.b2cp : 0,
                retail_discount_percentage: generalInfo.isB2c ? variant.b2cdPercentage : 0,
                retail_discount_amount: generalInfo.isB2c ? variant.b2cp - variant.b2cdPrice : 0,
                final_retail_price: generalInfo.isB2c ? variant.b2cdPrice : 0,
                wholesale_price: generalInfo.isB2b ? variant.b2bp : 0,
                wholesale_discount_percentage: generalInfo.isB2b ? variant.b2bdPercentage : 0,
                wholesale_discount_amount: generalInfo.isB2b ? variant.b2bp - variant.b2bdPrice : 0,
                final_wholesale_price: generalInfo.isB2b ? variant.b2bdPrice : 0,
            })),
            ProductPackage: {
                weight: deliveryInfo.packageInfo?.weight || 0,
                weight_unit: 'Kilogram',
                width: deliveryInfo.packageInfo?.width || 0,
                height: deliveryInfo.packageInfo?.height || 0,
                length: deliveryInfo.packageInfo?.length || 0,
                measurement_unit: 'Inch',
            },
            ProductDelivery: {
                cod_charge_percentage: Number(deliveryInfo.deliveryPartnerInfo.cod),
                ...(deliveryInfo.deliveryPartnerInfo.fd && {
                    free_delivery_amount: Number(deliveryInfo.deliveryPartnerInfo.fd),
                }),
                is_free_delivery: false,
                delivery_time: deliveryInfo.deliveryPartnerInfo?.dt || '3',
                courier_id: deliveryInfo.deliveryPartnerInfo.courierId,
            },
            ProductInventory: {
                quantity,
                critical_stock_quantity: 10,
                last_quantity_add: quantity,
                min_order_quantity: generalInfo?.isB2b ? generalInfo.moq : 1,
                is_variant: variantInfo.isMultiVariant,
                is_price_vary: variantInfo.separetePrice,
            },
        }

        const response = await axios.post(`${apiV2}/products/`, payload, {
            params: { seller_user_id: userId },
        })
        return response.data
    } catch (error) {
        console.error(error)
    }
}

export async function updateProductImages(productImages, imgId) {
    try {
        const formData = new FormData()
        Object.values(productImages || {}).forEach((image, index) => {
            const fieldName = index === 0 ? 'img' : `img${index + 1}`
            if (image?.file) {
                formData.append(fieldName, image.file)
            }
        })
        const response = await axios.patch(`${apiV2}/products/images/${Number(imgId)}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
        return response.data
    } catch (error) {
        console.error(error)
    }
}

export async function getSingleSeller(id) {
    try {
        const response = await axiosInstance.get(`${apiV1}/sellers/single/${id}`)
        return response
    } catch (error) {
        console.error(error, 100)
    }
}

export async function getCategory() {
    try {
        const response = await axiosInstance.get('/categories/')
        return response
    } catch (error) {
        console.error(error, 100)
    }
}

export async function getSingleProduct(productId) {
    try {
        const response = await axios.get(`${apiV2}/products/${productId}`)
        return response.data
    } catch {
        console.error('Please check your internet connection!')
    }
}

export async function getSubCategory(categoryId) {
    try {
        const response = await axiosInstance.get(`/categories/sub_category/${categoryId}`)
        return response
    } catch (error) {
        console.error(error)
    }
}

export async function getSubSubCategory(subcategoryId) {
    try {
        const response = await axiosInstance.get(`/categories/sub_sub_category/${subcategoryId}`)
        return response
    } catch (error) {
        console.error(error)
    }
}

export async function updateProductVariants(productData, productId, userToken) {
    try {
        const { generalInfo, variantInfo } = productData
        const allVariant = variantInfo.separetePrice ? variantInfo.separetePriceVariants : variantInfo.variants
        const variants = allVariant?.map((variant, index) => ({
            variant_type: variant.type,
            variant_value: variant.value,
            variant_type2: variant.type2 || '',
            variant_value2: variant.value2 || '',
            is_base_variant: index === 0,
            quantity: variant.quantity,
            id: variant.id,
            manufacture_cost: variant.cop,
            retail_price: generalInfo.isB2c ? variant.b2cp : 0,
            retail_discount_percentage: generalInfo.isB2c ? variant.b2cdPercentage : 0,
            retail_discount_amount: generalInfo.isB2c ? variant.b2cp - variant.b2cdPrice : 0,
            final_retail_price: generalInfo.isB2c ? variant.b2cdPrice : 0,
            wholesale_price: generalInfo.isB2b ? variant.b2bp : 0,
            wholesale_discount_percentage: generalInfo.isB2b ? variant.b2bdPercentage : 0,
            wholesale_discount_amount: generalInfo.isB2b ? variant.b2bp - variant.b2bdPrice : 0,
            final_wholesale_price: generalInfo.isB2b ? variant.b2bdPrice : 0,
        }))

        const payload = [...variants]
        const response = await axios.patch(`${apiV2}/products/variants/${productId}`, payload, {
            params: {
                is_price_vary: variantInfo.separetePrice,
            },

            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userToken}`,
            },
        })
        return response.data
    } catch (error) {
        console.error(error)
    }
}

export async function updateProductPrice(productData, productPriceId, userToken) {
    try {
        const { generalInfo, variantInfo } = productData
        const productPrice = {
            manufacture_cost: variantInfo.quantityAndPrice.details.cop,
            retail_price: generalInfo.isB2c ? variantInfo.quantityAndPrice.price.b2cp : 0,
            retail_discount_percentage: generalInfo.isB2c ? variantInfo.quantityAndPrice.price.b2cd : 0,
            retail_discount_amount: generalInfo.isB2c
                ? variantInfo.quantityAndPrice.price.b2cp - variantInfo.quantityAndPrice.discount.b2cdp
                : 0,
            final_retail_price: generalInfo.isB2c
                ? variantInfo.quantityAndPrice.price.b2cd
                    ? variantInfo.quantityAndPrice.discount.b2cdp
                    : variantInfo.quantityAndPrice.price.b2cp
                : 0,
            wholesale_price: generalInfo.isB2b ? variantInfo.quantityAndPrice.price.b2bp : 0,
            wholesale_discount_percentage: generalInfo.isB2b ? variantInfo.quantityAndPrice.price.b2bd : 0,
            wholesale_discount_amount: generalInfo.isB2b
                ? variantInfo.quantityAndPrice.price.b2bp - variantInfo.quantityAndPrice.discount.b2bdp
                : 0,
            final_wholesale_price: generalInfo.isB2b
                ? variantInfo.quantityAndPrice.price.b2bd
                    ? variantInfo.quantityAndPrice.discount.b2bdp
                    : variantInfo.quantityAndPrice.price.b2bp
                : 0,
        }

        const payload = { ...productPrice }
        const response = await axios.patch(`${apiV2}/products/price/${productPriceId}`, payload, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userToken}`,
            },
        })
        return response.data
    } catch (error) {
        console.error(error)
    }
}

export async function uploadPDescriptionImage(descriptionImage, userToken, sellerId) {
    const formData = new FormData()
    formData.append('image', descriptionImage)

    try {
        const response = await axios.post(`${apiV2}/products/description/image`, formData, {
            params: {
                seller_user_id: sellerId,
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${userToken}`,
            },
        })
        return response.data
    } catch (error) {
        console.error('Error uploading description image:', error.message || error)
    }
}

export async function updateProduct(productData, productId, userToken) {
    try {
        const { generalInfo, deliveryInfo, variantInfo } = productData
        const tags =
            generalInfo.tags.length > 0
                ? Object.fromEntries(
                      generalInfo.tags.map((tag, index) => [index === 0 ? 'tag' : `tag${index + 1}`, tag])
                  )
                : ''
        const payload = {
            Product: {
                title: generalInfo.title,
                is_active: true,
                is_biztrade: true,
                is_retail: generalInfo.isB2c,
                is_wholesale: generalInfo.isB2b,
                is_feature: true,
                is_adult: false,
            },
            ProductDescription: {
                description: generalInfo.description?.html,
            },
            ProductTag: tags,
            ProductAttribute: {
                type: generalInfo.type,
                unit: generalInfo.unit,
            },
            ProductPackage: {
                weight: deliveryInfo.packageInfo?.weight || 0,
                weight_unit: 'Kilogram',
                width: deliveryInfo.packageInfo?.width || 0,
                height: deliveryInfo.packageInfo?.height || 0,
                length: deliveryInfo.packageInfo?.length || 0,
                measurement_unit: 'Inch',
            },
            ProductDelivery: {
                cod_charge_percentage: Number(deliveryInfo.deliveryPartnerInfo.cod),
                ...(deliveryInfo.deliveryPartnerInfo.fd && {
                    free_delivery_amount: Number(deliveryInfo.deliveryPartnerInfo.fd),
                }),
                is_free_delivery: false,
                delivery_time: deliveryInfo.deliveryPartnerInfo?.dt || '3',
                courier_id: deliveryInfo.deliveryPartnerInfo.courierId,
            },
        }
        const response = await axios.patch(`${apiV2}/products/all/${productId}`, payload, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userToken}`,
            },
        })

        return response.data
    } catch (error) {
        console.error(error)
    }
}

export async function updateProductInventory(productData, inventoryId, userToken) {
    try {
        const { generalInfo, variantInfo } = productData
        const quantity = variantInfo.isMultiVariant
            ? variantInfo.separetePrice
                ? variantInfo.separetePriceVariants.reduce((total, variant) => total + variant.quantity, 0)
                : variantInfo.variants.reduce((total, variant) => total + variant.quantity, 0)
            : variantInfo.quantity
        const productInventory = {
            quantity,
            critical_stock_quantity: 10,
            last_quantity_add: quantity,
            min_order_quantity: generalInfo?.isB2b ? generalInfo.moq : 1,
            is_variant: variantInfo.isMultiVariant,
            is_price_vary: variantInfo.separetePrice,
        }
        const payload = {
            ...productInventory,
        }

        const response = await axios.patch(`${apiV2}/products/inventory/${inventoryId}`, payload, {
            params: {
                is_variant: variantInfo.isMultiVariant,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userToken}`,
            },
        })
        return response.data
    } catch (error) {
        console.error(error)
    }
}

export async function removePDescriptionImage(userToken, imageId) {
    try {
        const response = await axios.delete(`${apiV2}/products/description/image/${imageId}`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${userToken}`,
            },
        })
        return response.data
    } catch (error) {
        console.error('Error removing description image:', error.message || error)
    }
}

export async function getCouriers(courierName) {
    try {
        const response = await axiosInstance.get(`/couriers/${courierName ? `?name=${courierName}` : ''}`)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

export async function getCourierPriceList(pListId) {
    try {
        const response = await axiosInstance.get(`/couriers/prices/list${pListId ? `?id=${pListId}` : ''}`)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

export async function getVariants(variantName) {
    try {
        const response = await axiosInstance.get(`/variants/${variantName ? `?name=${variantName}` : ''}`)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

export async function updateProductCategory(generalInfo, singleProductData, productId, userToken) {
    const payload = {
        category_id: generalInfo.selectedCategory ?? singleProductData?.Product?.category_id,
    }
    try {
        const response = await axios.patch(`${apiV2}/products/category/${productId}`, payload, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userToken}`,
            },
        })

        return response.data
    } catch {}

    return payload
}

export async function updateProductSubCats(generalInfo, singleProductData, productId, userToken) {
    const payload = {
        sub_category_id: generalInfo?.selectedSubCategory ?? singleProductData?.Product?.sub_category_id,
        sub_sub_category_id: generalInfo?.selectedSubSubCategory ?? null,
    }
    try {
        const response = await axios.patch(`${apiV2}/products/category/sub/${productId}`, payload, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userToken}`,
            },
        })

        return response.data
    } catch {}

    return payload
}

// ------------------------------
export async function getCategoryAllDetails() {
    try {
        const response = await fetch(`${apiV1}/categories/all`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data
        }
    } catch {}
}

export async function getCategoryAll() {
    try {
        const response = await fetch(`${apiV1}/categories/`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()
        const categoryOptions = []

        if (response.ok) {
            await data.map((e) => categoryOptions.push({ id: e.id, name: e.name, label: e.name, value: e.name }))
            return categoryOptions
        }
    } catch {}
}

export async function getCategoryProducts(categoryId) {
    try {
        const response = await fetch(`${apiV1}/products/category/${categoryId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data[1]
        }
    } catch {}
}

export async function getProducts(title, category, skip, limit) {
    try {
        const response = await fetch(
            `${apiV1}/products/?title=${title}&category=${category}&skip=${skip}&limit=${limit}`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    // Authorization: `Bearer ${token}`,
                },
            }
        )

        const data = await response.json()

        if (response.ok) {
            return [data[0], data[1]]
        }
    } catch {}
}

export async function getProductsByUserId(userId) {
    try {
        const response = await fetch(`${apiV1}/products/seller/${userId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                // Authorization: `Bearer ${token}`,
            },
        })

        const data = await response.json()

        if (response.ok) {
            return [data[0], data[1]]
        }
    } catch {}
}

export async function getAllRfq(skip, limit) {
    try {
        const response = await fetch(`${apiV1}/rfq/?skip=${skip}&limit=${limit}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                // Authorization: `Bearer ${token}`,
            },
        })

        const data = await response.json()

        if (response.ok) {
            return [data[0], data[1]]
        }
    } catch {}
}

export async function getRfqById(rfqId) {
    try {
        const response = await fetch(`${apiV1}/rfq/${rfqId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                // Authorization: `Bearer ${token}`,
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data
        }
    } catch {}
}

export async function getRfq(skip, limit) {
    try {
        const response = await fetch(`${apiV1}/rfq/?skip=${skip}&limit=${limit}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                // Authorization: `Bearer ${token}`,
            },
        })

        const data = await response.json()

        if (response.ok) {
            return [data[0], data[1]]
        }
    } catch {}
}

import React, { useEffect, useReducer } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { Layout } from './components'
import ProductEditLayout from './components/Layout/ProductLayout/ProductEditLayout'
import ProductLayout from './components/Layout/ProductLayout/ProductLayout'
import { Auth, Category, Order, Seller, UserInfo } from './contexts/allContext'
import { orderReducer, orderState } from './contexts/reducer/OrderReducer'
import { authReducer, authState } from './contexts/reducer/authReducer'
import { categoryReducer, categoryState } from './contexts/reducer/categoryReducer'
import { sellerReducer, sellerState } from './contexts/reducer/sellerReducer'
import { userReducer, userState } from './contexts/reducer/userReducer'
import {
    SettingPage,
    ProfilePage,
    DashboardPage,
    SellerActiveListPage,
    SellerInactiveListPage,
    LogInPage,
    CreateSellerPage,
    CategoryPage,
    CategoryListPage,
    BuyerListPage,
    RfqListPage,
    ProductListPage,
    ProductAddPage,
    ProductVariantPage,
    ProductDeliveryPage,
    ProductEditPage,
    ProductEditVariantPage,
    ProductEditDeliveryPage,
} from './pages'
import CourierListPage from './pages/CourierList'
import CreateCourierPage from './pages/CreateCourier'
import CreateOrderPage from './pages/CreateOrder'
import CreateVariantPage from './pages/CreateVariant'
import OrderListPage from './pages/OrderList'
import Sme from './pages/Sme'
import VariantListPage from './pages/VariantList'
import ProtectedRoute from './routes/ProtectedRoute'

function App() {
    const [stateAuth, dispatchAuth] = useReducer(authReducer, authState)
    const [stateUser, dispatchUser] = useReducer(userReducer, userState)
    const [stateSeller, dispatchSeller] = useReducer(sellerReducer, sellerState)
    useEffect(() => {
        localStorage.removeItem('seller')
        localStorage.removeItem('category')
        localStorage.removeItem('order')
    })
    const [stateCategory, dispatchCategory] = useReducer(categoryReducer, categoryState)
    const [stateOrder, dispatchOrder] = useReducer(orderReducer, orderState)
    return (
        <Order.Provider value={{ stateOrder, dispatchOrder }}>
            <Category.Provider value={{ stateCategory, dispatchCategory }}>
                <Seller.Provider value={{ stateSeller, dispatchSeller }}>
                    <Auth.Provider value={{ stateAuth, dispatchAuth }}>
                        <UserInfo.Provider value={{ stateUser, dispatchUser }}>
                            <Routes>
                                <Route path="" element={<LogInPage />} />
                                <Route path="/*" element={<Layout />}>
                                    <Route path="/*" element={<ProtectedRoute preventUser={['seller']} />}>
                                        <Route path="dashboard" element={<DashboardPage />} />
                                        <Route path="buyers" element={<BuyerListPage />} />
                                        <Route path="products/products-lists" element={<ProductListPage />} />
                                        <Route path="products" element={<ProductLayout />}>
                                            <Route path="add">
                                                <Route index element={<Navigate to="general" replace />} />
                                                <Route path="general" element={<ProductAddPage />} />
                                                <Route path="variant" element={<ProductVariantPage />} />
                                                <Route path="delivery" element={<ProductDeliveryPage />} />
                                            </Route>
                                        </Route>
                                        <Route path="products" element={<ProductEditLayout />}>
                                            <Route path="edit/:id">
                                                <Route index element={<Navigate to="general" replace />} />
                                                <Route path="general" element={<ProductEditPage />} />
                                                <Route path="variant" element={<ProductEditVariantPage />} />
                                                <Route path="delivery" element={<ProductEditDeliveryPage />} />
                                            </Route>
                                        </Route>
                                        <Route path="seller/active-sellers" element={<SellerActiveListPage />} />
                                        <Route path="seller/inactive-sellers" element={<SellerInactiveListPage />} />
                                        <Route path="seller/create-seller" element={<CreateSellerPage />} />
                                        <Route path="rfq-list" element={<RfqListPage />} />
                                        <Route path="setting" element={<SettingPage />} />
                                        <Route path="profile" element={<ProfilePage />} />
                                        <Route path="category/create-category" element={<CategoryPage />} />
                                        <Route path="category/category-list" element={<CategoryListPage />} />
                                        <Route path="orders/order-list" element={<OrderListPage />} />
                                        <Route path="orders/create-order" element={<CreateOrderPage />} />
                                        <Route path="sme" element={<Sme />} />
                                        <Route path="variant/create-variant" element={<CreateVariantPage />} />
                                        <Route path="variant/variant-list" element={<VariantListPage />} />
                                        <Route path="courier/create-courier" element={<CreateCourierPage />} />
                                        <Route path="courier/courier-list" element={<CourierListPage />} />
                                    </Route>
                                </Route>
                            </Routes>
                            <ToastContainer />
                        </UserInfo.Provider>
                    </Auth.Provider>
                </Seller.Provider>
            </Category.Provider>
        </Order.Provider>
    )
}

export default App

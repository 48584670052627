export const productType = [
    { id: 1, label: 'Manufactured Product', value: 'manufactured product' },
    { id: 2, label: 'Stocked Product', value: 'Stocked product' },
]

export const productUnits = [
    { id: 1, label: 'Piece (Each)', value: 'Piece' },
    // { id: 2, label: 'Box (Pack)', value: 'box' },
    // { id: 3, label: 'Dozen', value: 'dozen' },
    // { id: 4, label: 'Kilogram (kg)', value: 'kilogram' },
    // { id: 5, label: 'Liter (L)', value: 'liter' },
]

export const sellType = [
    { id: 1, name: 'Retail (sell product individually)' },
    { id: 2, name: 'Wholesale (for larger orders)' },
]

export const variantType = [
    { id: 1, name: 'Single Variant' },
    { id: 2, name: 'Multiple Variant' },
]

export const priceType = [
    { id: 1, name: 'One price for all variants' },
    { id: 2, name: 'Set separate prices' },
]
export const demoData = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
]

export const measurementOptions = [
    { label: 'Kilogram', value: 'kg' },
    { label: 'Inch', value: 'Inch' },
    { label: 'Grams', value: 'g' },
    { label: 'Centimeters', value: 'cm' },
    { label: 'Meters', value: 'm' },
    { label: 'Millimeters', value: 'mm' },
    { label: 'Liters', value: 'L' },
    { label: 'Milliliters', value: 'mL' },
    { label: 'Miles', value: 'mi' },
    { label: 'Yards', value: 'yd' },
    { label: 'Feet', value: 'ft' },
]
export const deliveryTimeOptions = [
    { label: 'Day', value: 'Day' },
    { label: 'Week', value: 'Week' },
    { label: 'Month', value: 'Month' },
]

export const filterProductType = [
    { id: 5, value: 'Active', label: 'active' },
    { id: 1, value: 'B2C', label: 'retail' },
    { id: 2, value: 'B2B', label: 'wholesale' },
    { id: 3, value: 'Feature', label: 'feature' },
    { id: 4, value: 'Variant', label: 'variant' },
]

export const searchOptions = [
    { value: 'sku', label: 'SKU' },
    { value: 'title', label: 'Title' },
    { value: 'shop_name', label: 'Shop Name' },
    { value: 'category', label: 'Category' },
]

export const filterSortOptions = [
    { label: 'Newest to Oldest', value: 'desc' },
    { label: 'Oldest to Newest', value: 'asc' },
    { label: 'Price (Low to High)', value: 'lowest' },
    { label: 'Price (High to Low)', value: 'highest' },
]

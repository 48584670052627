import React, { useEffect, useState } from 'react'
import { svgs } from '../../../utils/findSvgs/findSvgs'
import classes from '../../Product/create/ProductAdd/ProductAdd.module.css'

const ProductAddTags = ({ productData, setProductData, singleProductData }) => {
    const [tag, setTag] = useState('')
    const [productTags, setProductTags] = useState([])

    //  productTags handler
    const createProductTags = (e) => {
        if ((e.key && e.key === 'Enter') || e.type === 'click') {
            if (tag.trim() && productTags?.length < 5) {
                setProductData((prev) => ({
                    ...prev,
                    generalInfo: {
                        ...prev.generalInfo,
                        tags: [...(prev.generalInfo.tags ? [...prev.generalInfo.tags] : []), tag.trim()],
                    },
                }))
                setTag('')
            }
        }
    }

    const deleteProductTags = (selectedTag) => {
        const tags = productTags.filter((tag) => tag !== selectedTag)
        setProductData((prev) => ({
            ...prev,
            generalInfo: { ...prev.generalInfo, tags: tags },
        }))
    }

    useEffect(() => {
        if (productData?.generalInfo?.tags) {
            setProductTags(productData?.generalInfo?.tags)
        }
    }, [productData?.generalInfo?.tags])

    return (
        <div className={classes.formGroup}>
            <label>Tags (Add up to 5 tags)</label>
            <div className={classes.itemGroup}>
                <input
                    type="text"
                    placeholder="Generate Tags"
                    value={tag}
                    onChange={(e) => setTag(e.target.value)}
                    onKeyDown={createProductTags}
                    disabled={productTags?.length === 5}
                />
                <div className={classes.wrapper}>
                    {productTags?.map((tag, index) => (
                        <button key={index} className={classes.itemGroupBtn}>
                            {tag}{' '}
                            {!singleProductData && <span onClick={() => deleteProductTags(tag)}>{svgs.closeIcon}</span>}
                        </button>
                    ))}
                </div>
                {productTags?.length === 5 ? undefined : tag.length > 2 ? (
                    <button disabled={!tag.trim()} onClick={createProductTags} className={classes.submitBtn}>
                        Enter
                    </button>
                ) : undefined}
            </div>
        </div>
    )
}

export default ProductAddTags

import React, { useState } from 'react'
import classes from './CourierDetails.module.css'

const CourierDetails = ({ courier, setRefetch, token, apiV1 }) => {
    const [submitStatus, setSubmitStatus] = useState(true)
    const [msg, setMsg] = useState('')
    const [name, setName] = useState(courier?.name)
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const response = await fetch(`${apiV1}/couriers/${courier?.id}`, {
                headers: {
                    Accept: 'appllication/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'PATCH',
                body: JSON.stringify({
                    name: name,
                }),
            })

            if (response.ok) {
                setSubmitStatus(true)
                setMsg('Courier Updated!')
                setRefetch(true)
                setTimeout(() => {
                    setSubmitStatus(false)
                }, 2000)
            } else {
                setMsg('Something went wrong!')
                setSubmitStatus(true)
            }
        } catch (error) {
            setMsg('Something went wrong!')
            setSubmitStatus(true)
            setTimeout(() => {
                setSubmitStatus(false)
            }, 2000)
        }
    }
    return (
        <div className={classes.container}>
            <form onSubmit={handleSubmit}>
                <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px' }}>
                    <div className={classes.innerContainer}>
                        <p>Courier Name</p>
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                        {submitStatus && (
                            <div>
                                <span
                                    className={classes.submitMessage}
                                    style={{ color: `${msg?.substring(0, 2) === 'Co' ? '#5cb484' : '#a10f0f'}` }}>
                                    {msg}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
                <div className={classes.submitContainer}>
                    <button type="submit">Submit</button>
                </div>
            </form>
        </div>
    )
}

export default CourierDetails

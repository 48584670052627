import React, { useEffect, useState } from 'react'
import classes from './PricingTable.module.css'

const PricingTable = ({ priceList, couriers, selectedCourierId }) => {
    const [pricingData, setPricingData] = useState()
    const courierName = couriers[selectedCourierId - 1]?.name

    useEffect(() => {
        const pricingData = priceList?.map((list) => {
            const { location, value, value2, value3, value4 } = list
            return {
                location,
                details: [
                    { weight: '0 – 0.5 Kg', price: value + ' ৳' },
                    { weight: '0.5 – 1.0 Kg', price: value2 + ' ৳' },
                    { weight: '1.0 – 2.0 Kg', price: value3 + ' ৳' },
                    {
                        weight: '> 2.0 Kg',
                        price: `For every kilogram above 2.0 kg, an extra ${value4 + ' ৳'} will be added.`,
                    },
                ],
            }
        })
        setPricingData(pricingData)
    }, [priceList])

    return (
        <div className={classes.tableContainer}>
            <div className={classes.tableWrapper}>
                <h3>
                    <span>{courierName} </span>Delivery Partner Pricing Details -
                </h3>
                <table className={classes.table}>
                    <thead>
                        <tr>
                            <th>Delivery Area</th>
                            <th>Weight</th>
                            <th>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pricingData?.map((area, index) => (
                            <React.Fragment key={index}>
                                {area.details.map((detail, idx) => {
                                    return (
                                        <tr key={idx}>
                                            {idx === 0 && (
                                                <td rowSpan={area.details.length} className={classes.area}>
                                                    {area.location}
                                                </td>
                                            )}
                                            <td>{detail.weight}</td>
                                            <td>{detail.price} </td>
                                        </tr>
                                    )
                                })}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default PricingTable

import React, { useState } from 'react'
import { FaRegEdit } from 'react-icons/fa'
import { MdDelete } from 'react-icons/md'
import { formatDate } from '../../utils/dateFormat'
import TableV1 from '../Resources/TableV1/TableV1'
import TitleContainer from '../Resources/TitleContainer/TitleContainer'
import UpdatePopup from './UpdatePopup/UpdatePopup'
import classes from './VariantList.module.css'

const VariantList = ({ variants, apiV1, token, setRefetch, count }) => {
    const handleDelete = async (id) => {
        try {
            const response = await fetch(`${apiV1}/variants/${id}`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'DELETE',
            })
            if (response.ok) {
                setRefetch(true)
            }
        } catch (error) {}
    }
    const [open, setOpen] = useState(false)

    const [current, setCurrent] = useState(0)
    return (
        <div className={classes.container}>
            <TitleContainer title={'Variant List'} isCount={true} count={count} />
            <div className={classes.list}>
                <TableV1>
                    <thead>
                        <tr>
                            <th>SL.</th>
                            <th>Name</th>
                            <th>Creation Date</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {variants.length > 0 &&
                            variants.map((variant, index) => (
                                <tr key={variant.id}>
                                    <td>{index + 1}</td>
                                    <td>{variant.name}</td>
                                    <td>{formatDate(variant?.created_at)}</td>
                                    <td className={classes.action}>
                                        <button
                                            onClick={() => {
                                                setCurrent(index)
                                                setOpen(true)
                                            }}>
                                            <FaRegEdit size={15} />
                                        </button>
                                        <button onClick={() => handleDelete(variant?.id)}>
                                            <MdDelete size={16} />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </TableV1>
                {/* <div className={classes.emptyWhite}></div> */}
            </div>
            {open && (
                <UpdatePopup
                    form={open}
                    setForm={setOpen}
                    setRefetch={setRefetch}
                    variants={variants[current]}
                    apiV1={apiV1}
                    token={token}
                />
            )}
        </div>
    )
}

export default VariantList

import { SearchIcon } from 'lucide-react'
import React, { useEffect } from 'react'
import { MdManageSearch } from 'react-icons/md'
import { svgs } from '../../../../../utils/findSvgs/findSvgs'
import { filterProductType, filterSortOptions, searchOptions } from '../../../../../utils/static/static'
import FilterPopup from './FilterPopup/FilterPopup'
import classes from './Searchbar.module.css'

export default function Searchbar({ filters, setFilters, search, setSearch }) {
    // for filters...

    const handleFilterChange = (label) => {
        setFilters((prev) => ({
            ...prev,
            all: false,
            [label]: filters[label] ? false : true,
        }))
    }

    // for sorting...
    const onChange = (value) => {
        setFilters(() => ({ ...filters, sortBy: value }))
    }

    // make sure all is active when b2b and b2c and all are false

    useEffect(() => {
        if (filters.retail || filters.wholesale) {
            setFilters((prev) => ({ ...prev, showAll: false }))
        } else {
            setFilters((prev) => ({ ...prev, showAll: true, all: true }))
        }
    }, [filters.retail, filters.wholesale])

    const onChangeSearch = (value) => {
        setSearch((prev) => ({ ...prev, searchBy: value }))
    }
    useEffect(() => {
        if (search.value.length <= 0) {
            setSearch((prev) => ({
                ...prev,
                isSearchAble: false,
            }))
        }
    }, [search.value])

    const resetAll = () => {
        setFilters(() => ({
            ...filters,
            all: true,
            showAll: true,
            retail: false,
            wholesale: false,
            active: true,
            search: '',
            variant: '',
            feature: '',
        }))
        setSearch((prev) => ({
            ...prev,
            value: '',
            isSearchAble: false,
        }))
    }

    return (
        <div>
            <div className={classes.filter}>
                <div className={classes.searchContainer}>
                    <div className={classes.searchInputWrapper}>
                        <div className={classes.searchIconWrapper}>
                            <SearchIcon className={classes.searchIcon} size={20} />
                        </div>
                        <input
                            type="text"
                            placeholder="Search Your Product....."
                            value={search.value}
                            onChange={(e) =>
                                setSearch((prev) => ({ ...prev, value: e.target.value, isSearchAble: true }))
                            }
                            className={classes.searchInput}
                        />
                        <div className={classes.popup}>
                            <FilterPopup
                                options={searchOptions}
                                parentClass=""
                                defaultValue={searchOptions[0]}
                                placeholder=""
                                filters={''}
                                onChange={onChangeSearch}
                            />
                        </div>
                    </div>
                </div>

                {/* Filters Row */}
                <div className={classes.filtersRow}>
                    <div className={classes.formBox}>
                        <div className={classes.checkboxes}>
                            <div className={`${filters.all ? classes.active : ''}`} onClick={() => resetAll()}>
                                <div>{svgs.selectIcon}</div>
                                <label>All</label>
                            </div>
                            {filterProductType.map((type) => {
                                const isActive = Object.keys(filters).some((key) => key === type.label && filters[key])
                                return (
                                    <div
                                        key={type.id}
                                        className={`${isActive ? classes.active : ''}`}
                                        onClick={() => handleFilterChange(type.label)}>
                                        <div>{svgs.selectIcon}</div>
                                        <label>{type.value}</label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className={classes.sortContainer}>
                        <FilterPopup
                            options={filterSortOptions}
                            parentClass={'sortContainer'}
                            defaultValue={''}
                            placeholder={'Sort By'}
                            filters={filters}
                            onChange={onChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

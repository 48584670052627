import React from 'react'
import CustomDropDown from '../CustomDropDown'
import classes from './RenderDropdown.module.css'

const RenderDropDown = ({
    label,
    data,
    placeholder,
    dropdownLabel,
    setProductData,
    customNoOptionsMessage,
    defaultValue,
    error,
    isDisabled,
}) => {
    const sortOutData =
        dropdownLabel === 'sellerid' && data?.Seller
            ? [
                  {
                      label: data?.Seller?.page_name,
                      value: data?.Seller?.seller_id,
                      userid: data?.Seller?.user_id,
                  },
              ]
            : data[0]?.label
            ? data
            : data?.map((category) => ({
                  label: category.name,
                  value: category.id,
              }))

    return (
        <>
            {label && (
                <label>
                    {label} <sup>*</sup>
                </label>
            )}
            <CustomDropDown
                label={label}
                options={sortOutData}
                placeholder={placeholder}
                dropdownLabel={dropdownLabel}
                setProductData={setProductData}
                customNoOptionsMessage={customNoOptionsMessage}
                error={error}
                defaultValue={defaultValue}
                isDisabled={isDisabled}
            />
        </>
    )
}

export default RenderDropDown

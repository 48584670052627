import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import classes from './CourierTable.module.css'

const CourierTable = ({ selected, token, apiV1 }) => {
    const [pricing, setPricing] = useState([])
    const [editable, setEditable] = useState(false)
    const [refetch, setRefetch] = useState(false)
    const toastError = () => {
        toast.dismiss()
        toast.error('Something went wrong!', {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
        })
    }
    const toastSuccess = () => {
        toast.dismiss()
        toast.success('Pricing updated!', {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const response = await fetch(`${apiV1}/couriers/prices/list/${selected.id}`, {
                headers: {
                    Accept: 'appllication/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'PATCH',
                body: JSON.stringify({
                    same_city: {
                        value: pricing[0].value,
                        value2: pricing[0].value2,
                        value3: pricing[0].value3,
                        value4: pricing[0].value4,
                    },
                    dhaka_suburb: {
                        value: pricing[1].value,
                        value2: pricing[1].value2,
                        value3: pricing[1].value3,
                        value4: pricing[1].value4,
                    },
                    intercity: {
                        value: pricing[2].value,
                        value2: pricing[2].value2,
                        value3: pricing[2].value3,
                        value4: pricing[2].value4,
                    },
                }),
            })
            if (response.ok) {
                setRefetch(true)
                toastSuccess()
                setEditable(false)
            } else {
                toastError()
            }
        } catch (error) {
            toastError()
        }
    }
    useEffect(() => {
        const fetchdata = async () => {
            try {
                const response = await fetch(`${apiV1}/couriers/prices/list?id=${selected.id}`, {
                    headers: {
                        Accept: 'appllication/json',
                        'Content-Type': 'application/json',
                    },
                    method: 'GET',
                })
                const data = await response.json()
                if (response.ok) {
                    setPricing(data)
                }
            } catch (error) {
                setPricing([])
            }
        }
        fetchdata()
        if (refetch) {
            fetchdata()
            setRefetch(false)
        }
    }, [apiV1, selected, refetch])
    useEffect(() => {
        setEditable(false)
    }, [selected])
    return (
        <div className={classes.tableContainer}>
            <form onSubmit={handleSubmit}>
                <div className={classes.headerContainer}>
                    <h2>
                        Pricing Table for <span>{selected.name}</span>
                    </h2>
                    <div className={classes.buttonContainer}>
                        {editable && <button type="submit">Submit</button>}
                        <button type="button" onClick={() => setEditable(!editable)}>
                            Edit Table
                        </button>
                    </div>
                </div>
                {pricing.length > 0 && (
                    <table className={classes.table}>
                        <thead className={classes.thead}>
                            <tr>
                                <th>Delivery Area</th>
                                <th>Weight</th>
                                <th>Price</th>
                            </tr>
                        </thead>
                        <tbody className={classes.tbody}>
                            <tr>
                                <td rowSpan={4}>Same City</td>
                                <td>0 - 0.5 Kg</td>
                                <td>
                                    {' ৳ '}
                                    <input
                                        type="number"
                                        min={0}
                                        value={pricing[0].value}
                                        required
                                        disabled={!editable}
                                        onChange={(e) =>
                                            setPricing((prev) => [
                                                { ...prev[0], value: e.target.value },
                                                ...prev.slice(1),
                                            ])
                                        }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>0.5 - 1.0 Kg</td>
                                <td>
                                    {' ৳ '}
                                    <input
                                        min={0}
                                        type="number"
                                        value={pricing[0]?.value2}
                                        onChange={(e) =>
                                            setPricing((prev) => [
                                                { ...prev[0], value2: e.target.value },
                                                ...prev.slice(1),
                                            ])
                                        }
                                        disabled={!editable}
                                        required
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>1.0 - 2.0 Kg</td>
                                <td>
                                    {' ৳ '}
                                    <input
                                        type="number"
                                        value={pricing[0]?.value3}
                                        onChange={(e) =>
                                            setPricing((prev) => [
                                                { ...prev[0], value3: e.target.value },
                                                ...prev.slice(1),
                                            ])
                                        }
                                        disabled={!editable}
                                        required
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>{'>'} 2.0 Kg</td>
                                <td>
                                    For every kilogram above 2.0 kg, an extra
                                    {editable ? (
                                        <>
                                            {' ৳ '}
                                            <input
                                                min={0}
                                                type="number"
                                                value={pricing[0]?.value4}
                                                onChange={(e) =>
                                                    setPricing((prev) => [
                                                        { ...prev[0], value4: e.target.value },
                                                        ...prev.slice(1),
                                                    ])
                                                }
                                                required
                                                disabled={!editable}
                                            />{' '}
                                        </>
                                    ) : (
                                        <span> ৳ {pricing[0]?.value4} </span>
                                    )}
                                    will be added.
                                </td>
                            </tr>
                            <tr>
                                <td rowSpan={4}>Dhaka Suburb</td>
                                <td>0 - 0.5 Kg</td>
                                <td>
                                    {' ৳ '}
                                    <input
                                        type="number"
                                        value={pricing[1]?.value}
                                        min={0}
                                        onChange={(e) =>
                                            setPricing((prev) => [
                                                ...prev.slice(0, 1),
                                                { ...prev[1], value: e.target.value },
                                                ...prev.slice(2),
                                            ])
                                        }
                                        disabled={!editable}
                                        required
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>0.5 - 1.0 Kg</td>
                                <td>
                                    {' ৳ '}
                                    <input
                                        min={0}
                                        type="number"
                                        value={pricing[1]?.value2}
                                        onChange={(e) =>
                                            setPricing((prev) => [
                                                ...prev.slice(0, 1),
                                                { ...prev[1], value2: e.target.value },
                                                ...prev.slice(2),
                                            ])
                                        }
                                        disabled={!editable}
                                        required
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>1.0 - 2.0 Kg</td>
                                <td>
                                    {' ৳ '}
                                    <input
                                        min={0}
                                        type="number"
                                        value={pricing[1]?.value3}
                                        onChange={(e) =>
                                            setPricing((prev) => [
                                                ...prev.slice(0, 1),
                                                { ...prev[1], value3: e.target.value },
                                                ...prev.slice(2),
                                            ])
                                        }
                                        disabled={!editable}
                                        required
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>{'>'} 2.0 Kg</td>
                                <td>
                                    For every kilogram above 2.0 kg, an extra
                                    {editable ? (
                                        <>
                                            {' ৳ '}
                                            <input
                                                min={0}
                                                type="number"
                                                value={pricing[1]?.value4}
                                                onChange={(e) =>
                                                    setPricing((prev) => [
                                                        ...prev.slice(0, 1),
                                                        { ...prev[1], value4: e.target.value },
                                                        ...prev.slice(2),
                                                    ])
                                                }
                                                required
                                                disabled={!editable}
                                            />{' '}
                                        </>
                                    ) : (
                                        <span> ৳ {pricing[1]?.value4} </span>
                                    )}
                                    will be added.
                                </td>
                            </tr>
                            <tr>
                                <td rowSpan={4} className={classes.lastRow}>
                                    Intercity
                                </td>
                                <td>0 - 0.5 Kg</td>
                                <td>
                                    {' ৳ '}
                                    <input
                                        min={0}
                                        type="text"
                                        value={pricing[2]?.value}
                                        onChange={(e) =>
                                            setPricing((prev) => [
                                                ...prev.slice(0, 2),
                                                { ...prev[2], value: e.target.value },
                                            ])
                                        }
                                        disabled={!editable}
                                        required
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>0.5 - 1.0 Kg</td>
                                <td>
                                    {' ৳ '}
                                    <input
                                        min={0}
                                        type="text"
                                        value={pricing[2]?.value2}
                                        onChange={(e) =>
                                            setPricing((prev) => [
                                                ...prev.slice(0, 2),
                                                { ...prev[2], value2: e.target.value },
                                            ])
                                        }
                                        disabled={!editable}
                                        required
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>1.0 - 2.0 Kg</td>
                                <td>
                                    {' ৳ '}
                                    <input
                                        min={0}
                                        type="text"
                                        value={pricing[2]?.value3}
                                        onChange={(e) =>
                                            setPricing((prev) => [
                                                ...prev.slice(0, 2),
                                                { ...prev[2], value3: e.target.value },
                                            ])
                                        }
                                        disabled={!editable}
                                        required
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>{'>'} 2.0 Kg</td>
                                <td>
                                    For every kilogram above 2.0 kg, an extra
                                    {editable ? (
                                        <>
                                            {' ৳ '}
                                            <input
                                                min={0}
                                                type="number"
                                                value={pricing[2]?.value4}
                                                onChange={(e) =>
                                                    setPricing((prev) => [
                                                        ...prev.slice(0, 2),
                                                        { ...prev[2], value4: e.target.value },
                                                    ])
                                                }
                                                required
                                                disabled={!editable}
                                            />{' '}
                                        </>
                                    ) : (
                                        <span> ৳ {pricing[2]?.value4} </span>
                                    )}
                                    will be added.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                )}
            </form>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </div>
    )
}

export default CourierTable

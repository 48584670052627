export const formatDate = (date) => {
    let array = date.split('-')
    let updatedDate = array[2]?.slice(0, 2)
    array[2] = updatedDate
    let result = `${array[2]}-${array[1]}-${array[0]}`
    return result
}

export const ConvertDate = (dateString) => {
    const date = new Date(dateString)
    const day = date.getDate()
    const month = date.toLocaleString('default', { month: 'short' })
    const year = date.getFullYear().toString()
    return `${month} ${day}, ${year}`
}

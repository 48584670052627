import Button from '@atlaskit/button'
import React, { useState } from 'react'
import Select from 'react-select'
import classes from './FilterPopup.module.css'

export default function FilterPopup({ options, parentClass, defaultValue, placeholder, filters, onChange }) {
    const [isOpen, setIsOpen] = useState(false)
    const [selectedOption, setSelectedOption] = useState(defaultValue)

    const toggleOpen = () => {
        setIsOpen((prevState) => !prevState)
    }

    const onSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption)
        onChange(selectedOption.value)
        setIsOpen(false)
    }

    // Dynamically add parentClass to the outer div if it is provided
    const popUpClass = parentClass ? `${classes.popUpDropDown} ${parentClass}` : classes.popUpDropDown
    return (
        <div className={popUpClass}>
            <Dropdown
                isOpen={isOpen}
                onClose={toggleOpen}
                target={
                    <Button iconAfter={<ChevronDown />} onClick={toggleOpen} isSelected={isOpen}>
                        {selectedOption ? selectedOption.label : placeholder}
                    </Button>
                }>
                <Select
                    autoFocus
                    options={options}
                    onChange={onSelectChange}
                    value={selectedOption}
                    menuIsOpen
                    isSearchable={false}
                    placeholder="Filter by..."
                    components={{ IndicatorSeparator: null }}
                />
            </Dropdown>
        </div>
    )
}

// Dropdown, Blanket, and Menu remain the same
const Dropdown = ({ children, isOpen, target, onClose }) => (
    <div style={{ position: 'relative' }}>
        {target}
        {isOpen ? <Menu>{children}</Menu> : null}
        {isOpen ? <Blanket onClick={onClose} /> : null}
    </div>
)

const Menu = (props) => (
    <div
        style={{
            backgroundColor: 'white',
            borderRadius: 4,
            boxShadow: '0 0 4px rgba(0,0,0,0.1)',
            marginTop: 8,
            position: 'absolute',
            zIndex: 2,
        }}
        {...props}
    />
)

const Svg = (props) => <svg width="12" height="8" viewBox="0 0 12 8" focusable="false" role="presentation" {...props} />

const ChevronDown = () => (
    <Svg style={{ marginRight: -6 }}>
        <path
            d="M6.60515 7.72962C6.27045 8.09013 5.72688 8.09013 5.39217 7.72962L0.251032 2.19224C-0.0836773 1.83173 -0.0836773 1.24627 0.251032 0.885764C0.585741 0.525258 1.12931 0.525258 1.46402 0.885764L6 5.77135L10.536 0.888649C10.8707 0.528142 11.4143 0.528142 11.749 0.888649C12.0837 1.24916 12.0837 1.83462 11.749 2.19512L6.60783 7.7325L6.60515 7.72962Z"
            fill="#757575"
        />
    </Svg>
)

const Blanket = (props) => (
    <div
        style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1,
        }}
        {...props}
    />
)

import React from 'react'
import { IoClose } from 'react-icons/io5'
import PopupV1 from '../../../Resources/PopupV1/PopupV1'
import CourierDetails from './CourierDetails/CourierDetails'
import CourierLogo from './CourierLogo/CourierLogo'
import classes from './CourierUpdatePopup.module.css'

const CourierUpdatePopup = ({ form, setForm, refetch, courier, setCourier, token, apiV1, setRefetch }) => {
    return (
        <div className={classes.container}>
            <PopupV1 setForm={setForm} form={form} width="50%">
                <div className={classes.close}>
                    <div className={classes.header}>
                        <p>Update Courier</p>
                    </div>
                    <button onClick={() => setForm(!form)}>
                        <IoClose size={20} />
                    </button>
                </div>
                <div className={classes.content}>
                    <hr />
                    <div className={classes.main}>
                        <div>
                            <input type="checkbox" id="box0" />
                            <label htmlFor="box0" className={classes.formItem}>
                                Courier Details
                            </label>
                            <CourierDetails courier={courier} token={token} apiV1={apiV1} setRefetch={setRefetch} />
                        </div>
                        <div>
                            <input type="checkbox" id="box1" />
                            <label htmlFor="box1" className={classes.formItem}>
                                Courier Logo
                            </label>
                            <CourierLogo token={token} apiV1={apiV1} setRefetch={setRefetch} courier={courier} />
                        </div>
                    </div>
                </div>
            </PopupV1>
        </div>
    )
}

export default CourierUpdatePopup

import React, { useEffect, useRef, useState } from 'react'
import { svgs } from '../../../../utils/findSvgs/findSvgs'
import { productType, productUnits, sellType } from '../../../../utils/static/static'
import RenderDropDown from '../../../Resources/CustomDropDown/RenderDropdown/RenderDropDown'
import TextEditor from '../../../TextEditor/TextEditor'
import ImagePreview from '../../shared/ImagePreview/ImagePreview'
import ProductAddTags from '../../shared/ProductAddTags'
import ProductQuantity from '../../shared/ProductQuantity'
import classes from './ProductAdd.module.css'

const ProductAdd = ({ allCategories, setProductData, productData, seller }) => {
    const { categories, subCategories, subSubCategories } = allCategories
    const { generalInfo, error } = productData

    const [sellTypeIds, setSellTypeIds] = useState(() => {
        const initialSellTypes = []
        if (generalInfo?.isB2c) initialSellTypes.push(1)
        if (generalInfo?.isB2b) initialSellTypes.push(2)
        return initialSellTypes.length > 0 ? initialSellTypes : [1]
    })

    const handleSellType = (id) => {
        setProductData((prev) => {
            return {
                ...prev,
                variantInfo: {
                    ...prev.variantInfo,
                    selectedVariants: [],
                    variants: [],
                    separetePriceVariants: [],
                    isMultiVariant: false,
                },
            }
        }, [])
        if (sellTypeIds.includes(id)) {
            if (sellTypeIds.length > 1) {
                return setSellTypeIds((prev) => prev.filter((data) => data !== id))
            }
            return
        }
        setSellTypeIds((prev) => [...prev, id])
    }

    useEffect(() => {
        const isB2c = sellTypeIds.includes(1)
        const isB2b = sellTypeIds.includes(2)
        setProductData((prev) => {
            return {
                ...prev,
                generalInfo: { ...prev.generalInfo, isB2c, isB2b },
            }
        }, [])
    }, [sellTypeIds])

    return (
        <form onSubmit={(e) => e.preventDefault()}>
            {/* ----seller id---- */}
            <div className={classes.formBox}>
                <div className={classes.formGroup}>
                    <RenderDropDown
                        label={'Seller Search'}
                        data={seller}
                        placeholder={'Select Seller'}
                        dropdownLabel={'sellerid'}
                        setProductData={setProductData}
                        defaultValue={generalInfo?.sellerid}
                        error={`${error?.generalInfo && !generalInfo.sellerid ? 'error' : ''}`}
                    />
                    {error?.generalInfo && !generalInfo.sellerid && (
                        <p className={classes.warning}> {svgs.warning}Seller is required</p>
                    )}
                </div>
            </div>

            {/* checkbox */}
            <div className={classes.formBox}>
                <div className={classes.title}>How would you like to make your sales? *</div>
                <div className={classes.checkboxes}>
                    {sellType.map((sell) => {
                        const isActive = sellTypeIds.includes(sell.id)
                        return (
                            <div key={sell.id} onClick={() => handleSellType(sell.id)}>
                                <div className={`${isActive ? classes.active : ''}`}>{svgs.selectIcon}</div>
                                <label>{sell.name}</label>
                            </div>
                        )
                    })}
                </div>
            </div>

            {/*  main form */}
            <div className={classes.formBox}>
                <div className={`${classes.title} ${classes.borderBottom}`}>
                    <div>{svgs.products} Product Details</div>
                </div>
                {/* <div className={classes.formLayout}> */}
                <div className={classes.formGroup}>
                    <label>
                        Product Name <sup>*</sup>
                    </label>
                    <input
                        placeholder="Write Down Product Name"
                        className={`${error?.generalInfo && !generalInfo.name ? classes.error : ''}`}
                        value={generalInfo?.name}
                        onChange={(e) =>
                            setProductData((prev) => ({
                                ...prev,
                                generalInfo: {
                                    ...prev.generalInfo,
                                    name: e.target.value,
                                },
                            }))
                        }
                    />
                    {error?.generalInfo && !generalInfo.name && (
                        <p className={classes.warning}>{svgs.warning}Product Name is required</p>
                    )}
                </div>
                {/* </div> */}

                <div className={classes.formLayout}>
                    <div className={classes.formGroup}>
                        <RenderDropDown
                            label={'Product Category'}
                            data={categories}
                            placeholder={'Select Product Category'}
                            dropdownLabel={'selectedCategoryId'}
                            setProductData={setProductData}
                            defaultValue={generalInfo?.selectedCategoryId}
                            error={`${error?.generalInfo && !generalInfo.selectedCategoryId ? 'error' : ''}`}
                        />
                        {error?.generalInfo && !generalInfo.selectedCategoryId && (
                            <p className={classes.warning}> {svgs.warning}Product Category is required</p>
                        )}
                    </div>
                    <div className={classes.formGroup}>
                        <RenderDropDown
                            label={'Product Sub Category'}
                            data={subCategories}
                            placeholder={'Select Product Sub Category'}
                            dropdownLabel={'selectedSubCategoryId'}
                            setProductData={setProductData}
                            customNoOptionsMessage={'Select Product Category First'}
                            defaultValue={generalInfo?.selectedSubCategoryId}
                            error={`${error?.generalInfo && !generalInfo.selectedSubCategoryId ? 'error' : ''}`}
                        />
                        {error?.generalInfo && !generalInfo.selectedSubCategoryId && (
                            <p className={classes.warning}> {svgs.warning}Product Sub Category is required</p>
                        )}
                    </div>
                    <div className={classes.formGroup}>
                        <RenderDropDown
                            label={'Product Sub SubCategory'}
                            data={subSubCategories}
                            placeholder={'Select Product Sub Category'}
                            dropdownLabel={'selectedSubSubCategoryId'}
                            setProductData={setProductData}
                            customNoOptionsMessage={'Select Product Sub Category First'}
                            defaultValue={generalInfo?.selectedSubSubCategoryId}
                            error={`${error?.generalInfo && !generalInfo.selectedSubSubCategoryId ? 'error' : ''}`}
                        />
                        {error?.generalInfo && !generalInfo.selectedSubSubCategoryId && (
                            <p className={classes.warning}> {svgs.warning}Product Sub subCategory is required</p>
                        )}
                    </div>
                    <div className={classes.formGroup}>
                        <RenderDropDown
                            label={'Product Type'}
                            data={productType}
                            placeholder={'Select Product Type'}
                            dropdownLabel={'type'}
                            setProductData={setProductData}
                            defaultValue={generalInfo?.type}
                            error={`${error?.generalInfo && !generalInfo.type ? 'error' : ''}`}
                        />
                        {error?.generalInfo && !generalInfo.type && (
                            <p className={classes.warning}> {svgs.warning}Product Type is required</p>
                        )}
                    </div>
                    <div className={classes.formGroup}>
                        <RenderDropDown
                            label={'Unit'}
                            data={productUnits}
                            placeholder={'Select Unit'}
                            dropdownLabel={'unit'}
                            setProductData={setProductData}
                            defaultValue={generalInfo?.unit}
                            error={`${error?.generalInfo && !generalInfo.unit ? 'error' : ''}`}
                        />
                        {error?.generalInfo && !generalInfo.unit && (
                            <p className={classes.warning}> {svgs.warning}Product Unit is required</p>
                        )}
                    </div>
                    {generalInfo?.isB2b && (
                        <div className={classes.formGroup}>
                            <label>
                                Minimum Order Quantity (MOQ) <sup>*</sup>
                            </label>
                            <ProductQuantity setProductData={setProductData} productData={productData} label="moq" />
                        </div>
                    )}
                </div>

                <ProductAddTags setProductData={setProductData} productData={productData} singleProductData={''} />
            </div>

            {/* product image uplaod */}
            <div className={classes.formBox}>
                <div className={classes.title}>
                    <div>{svgs.imageIcon} Upload Product Images</div>
                    <p>
                        Upload upto 5 images, maximum size 5MB. It is suggested to add a “Size Chart” image (if needed)
                        for a better shopping experience.
                    </p>
                </div>
                <ImagePreview setProductData={setProductData} productData={productData} />
            </div>

            {/* text editor */}
            <div className={classes.formBox}>
                <div className={classes.title}>
                    <div>{svgs.descriptionIcon} Description</div>
                </div>
                <TextEditor setProductData={setProductData} productData={productData} />
                {error?.generalInfo && !generalInfo?.description?.html && (
                    <p className={`${classes.warning} ${classes.lastChild}`}>
                        {svgs.warning}Product description is required
                    </p>
                )}
                <DescriptionDemo />
            </div>
        </form>
    )
}

// it's static
const DescriptionDemo = () => {
    return (
        <div className={classes.descriptionDemo}>
            <h3>Description Demo</h3>
            <div>
                <p className={classes.demoText}>
                    This brown leather shoe, in a rich chocolate brown shade, combines timeless style with modern
                    comfort. Crafted from high-quality leather, it is designed to offer durability, support, and
                    versatility for both formal and casual occasions.
                </p>
                <ul className={classes.featuresList}>
                    <li>Color: Rich chocolate brown for a classic, versatile look</li>
                    <li>Premium Leather: Made with top-grade leather for a smooth, sophisticated finish</li>
                    <li>Cushioned Insole: Provides superior comfort for all-day wear, reducing foot fatigue</li>
                    <li>Durable Rubber Sole: Offers excellent traction and long-lasting durability</li>
                </ul>
            </div>
        </div>
    )
}

export default ProductAdd

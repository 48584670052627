import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { svgs } from '../../utils/findSvgs/findSvgs'
import classes from '../Layout/Layout.module.css'
import Navbar from './Navbar/Navbar'
import Sidebar from './Sidebar/Sidebar'

export const sidebarItems = [
    {
        id: 1,
        path: '/dashboard',
        icon: svgs.dashboard,
        text: 'Dashboard',
    },
    {
        id: 2,
        path: '/buyers',
        icon: svgs.buyers,
        text: 'Buyers',
    },
    {
        id: 3,
        path: '/seller',
        icon: svgs.sellers,
        text: 'Seller',
        submenu: [
            {
                id: 3.1,
                path: '/seller/active-sellers',
                icon: svgs.list,
                text: 'Active Sellers',
            },
            {
                id: 3.2,
                path: '/seller/Inactive-sellers',
                icon: svgs.create,
                text: 'Inactive Sellers',
            },
            {
                id: 3.3,
                path: '/seller/create-seller',
                icon: svgs.create,
                text: 'Create Seller',
            },
        ],
    },
    {
        id: 4,
        path: '/category',
        icon: svgs.category,
        text: 'Category',
        submenu: [
            {
                id: 4.1,
                path: '/category/category-list',
                icon: svgs.list,
                text: 'Category Lists',
            },
            {
                id: 4.2,
                path: '/category/create-category',
                icon: svgs.list,
                text: 'Create Category',
            },
        ],
    },
    {
        id: 5,
        path: '/products',
        icon: svgs.products,
        text: 'Products',
        submenu: [
            {
                id: 5.1,
                path: 'products/products-lists',
                icon: svgs.list,
                text: 'Products Lists',
            },
            {
                id: 5.2,
                path: '/products/add',
                icon: svgs.create,
                text: 'Add Product',
            },
        ],
    },
    {
        id: 6,
        path: '/orders',
        icon: svgs.orders,
        text: 'Orders',
        submenu: [
            {
                id: 6.1,
                path: '/orders/order-list',
                icon: svgs.list,
                text: 'Order Lists',
            },
            {
                id: 6.2,
                path: '/orders/create-order',
                icon: svgs.create,
                text: 'Create Order',
            },
        ],
    },

    {
        id: 7,
        path: '/variant',
        icon: svgs.variant,
        text: 'Variant',
        submenu: [
            {
                id: 7.1,
                path: '/variant/variant-list',
                icon: svgs.list,
                text: 'Variant Lists',
            },
            {
                id: 7.2,
                path: '/variant/create-variant',
                icon: svgs.create,
                text: 'Create Variant',
            },
        ],
    },
    {
        id: 8,
        path: '/courier',
        icon: svgs.contacts,
        text: 'Courier',
        submenu: [
            {
                id: 8.1,
                path: '/courier/Courier-list',
                icon: svgs.list,
                text: 'Courier Lists',
            },
            {
                id: 8.2,
                path: '/courier/create-courier',
                icon: svgs.create,
                text: 'Create Courier',
            },
        ],
    },
    {
        id: 9,
        path: '/sme',
        icon: svgs.documents,
        text: 'SME',
    },
    {
        id: 10,
        path: '/rfq-list',
        icon: svgs.helpCenter,
        text: 'RFQ Lists',
    },
]

export const menuItems = [
    {
        id: 1,
        path: '/settings',
        icon: svgs.settings,
        text: 'Settings',
    },
    {
        id: 2,
        path: '#',
        icon: svgs.logout,
        text: 'Logout',
        action: 'logout',
    },
]

export default function Layout() {
    const [isSidebarPanelOpen, setIsSidebarPanelOpen] = useState(true)
    return (
        <div className={classes.layout}>
            <div>
                <Sidebar
                    sidebarItems={sidebarItems}
                    isSidebarPanelOpen={isSidebarPanelOpen}
                    setIsSidebarPanelOpen={setIsSidebarPanelOpen}
                />
            </div>

            <div className={classes.container}>
                <Navbar isSidebarPanelOpen={isSidebarPanelOpen} setIsSidebarPanelOpen={setIsSidebarPanelOpen} />
                <div className={classes.content}>
                    <Outlet />
                </div>
            </div>
        </div>
    )
}
